import React, {useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import {Formik} from "formik";
import {FormatNumber} from "../../constants/FormatNumber";
import {useDispatch, useSelector} from "react-redux";
import {createProduct} from "../../redux/product/actions";

export const AddProduct = ({
                               visible,
                               close,
                               photos,
                               handleAddReference,
                               isAddedReference,
                               references,
                               handle,
                               deleteImage,
                               setPhotos,
}) => {

    const dispatch = useDispatch();

    const {token} = useSelector(state => state.auth);
    const {subcategories} = useSelector(state => state.subcategory);
    const {requestingCreate, errorCreate} = useSelector(state => state.product);
    const {providers} = useSelector(state => state.provider);
    const {categories} = useSelector(state => state.category);

    const [newSubcategories, setNewSubcategories] = useState([]);

    const changeCategory = (category) => {
        if(category !== 0){
            let subcategoriesShort = subcategories.filter(subcategory => subcategory.category.id === parseInt(category.target.value));
            setNewSubcategories(subcategoriesShort.sort(function (a,b) {
                return a.name.localeCompare(b.name)
            }));
        }else {
            setNewSubcategories([]);
        }
    }

    const addImage = (photo) => {
        let reader = new FileReader();
        reader.readAsDataURL(photo.target.files[0]);
        reader.onload = () => {
            let values =  {
                path: reader.result,
                url: URL.createObjectURL(photo.target.files[0]),
                name: photo.target.files[0].name,
            }
            setPhotos([...photos, values])
        };
    };

    const handleCreateProduct = (data) => {
        dispatch(createProduct(token, data, photos, references))
    };

    return (
        <Modal show={visible} onHide={() =>{ return true}} style={{ display: 'block' }}>
            <div className="modal-content">
                <Modal.Header className="modal-header" closeButton onClick={close}>
                    <h5 className="modal-title  fw-bold" id="addProduct"> Agregar producto</h5>
                </Modal.Header>
                <Formik
                    initialValues={{ name: '', sku: '', stock: '',
                        sale_price: '', purchase_price: '', description: '',
                        sub_category_id: '', provider_id: ''}}
                    onSubmit={(values ) => {
                        handleCreateProduct(values)
                    }}
                >
                    {({
                          handleChange,
                          handleSubmit,
                          setFieldValue,
                      }) => (
                        <form onSubmit={handleSubmit}>
                            <Modal.Body className="modal-body">
                                <div className="deadline-form">
                                    <div className="row g-3 mb-3">
                                        <div className="col-sm-12">
                                            <label htmlFor="it em100" className="form-label">Nombre</label>
                                            <input type="text"
                                                   className="form-control"
                                                   placeholder="Nombre"
                                                   name={'name'}
                                                   onChange={handleChange}
                                            />
                                            {errorCreate.name && <div className="text-danger fs-12">
                                                {errorCreate.name}
                                            </div>}
                                        </div>
                                        <div className="col-sm-12">
                                            <label htmlFor="it    em100" className="form-label">Sku</label>
                                            <input type="text"
                                                   className="form-control"
                                                   placeholder="Sku"
                                                   name={'sku'}
                                                   onChange={handleChange}
                                            />
                                            {errorCreate.sku && <div className="text-danger fs-12">
                                                {errorCreate.sku}
                                            </div>}
                                        </div>
                                        <div className="col-sm-12">
                                            <label htmlFor="it    em100" className="form-label">Stock</label>
                                            <input type="numeric"
                                                   className="form-control"
                                                   placeholder="Stock"
                                                   name={'stock'}
                                                   onChange={handleChange}
                                            />
                                            {errorCreate.stock && <div className="text-danger fs-12">
                                                {errorCreate.stock}
                                            </div>}
                                        </div>
                                        <div className="col-sm-12">
                                            <label htmlFor="it    em100" className="form-label">Precio de venta</label>
                                            <FormatNumber
                                                className="form-control"
                                                onValueChange={(value) => setFieldValue('sale_price', value.floatValue)}
                                                placeholder="Precio de venta"
                                            />
                                            {errorCreate.sale_price && <div className="text-danger fs-12">
                                                {errorCreate.sale_price}
                                            </div>}
                                        </div>
                                        <div className="col-sm-12">
                                            <label htmlFor="it    em100" className="form-label">Precio de compra</label>
                                            <FormatNumber
                                                className="form-control"
                                                onValueChange={(value) => setFieldValue('purchase_price', value.floatValue)}
                                                placeholder="Precio de venta"
                                            />
                                            {errorCreate.purchase_price && <div className="text-danger fs-12">
                                                {errorCreate.purchase_price}
                                            </div>}
                                        </div>
                                        <div className="col-sm-12">
                                            <label className="form-label">Descripción</label>
                                            <textarea className="form-control"
                                                      placeholder="Descripción"
                                                      rows="3"
                                                      name={'description'}
                                                      onChange={handleChange}
                                            />
                                        </div>
                                        <div className="col-sm-12">
                                            <label className="form-label">Proveedor</label>
                                            <select name={'provider_id'} className="form-control" onChange={handleChange}>
                                                <option
                                                    value={0}>
                                                    Elegir proveedor
                                                </option>
                                                {providers.map((provider, index) => (
                                                    <option
                                                        key={index}
                                                        value={provider.id}>
                                                        {provider.name}
                                                    </option>
                                                ))}
                                            </select>
                                            {errorCreate.provider_id && <div className="text-danger fs-12">
                                                {errorCreate.provider_id}
                                            </div>}
                                        </div>
                                        <div className="col-sm-12">
                                            <label className="form-label">Categorias</label>
                                            <select className="form-control"  onChange={changeCategory}>
                                                <option
                                                    value={0}>
                                                    Elegir Categoría
                                                </option>
                                                {categories.map((category, index) => (
                                                    <option
                                                        key={index}
                                                        value={category.id}>
                                                        {`${category.name}-${category.section.name}`}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        {newSubcategories.length > 0 && (
                                            <div className="col-sm-12">
                                                <label className="form-label">Subcategoría</label>
                                                <select name={'sub_category_id'} className="form-control" onChange={handleChange}>
                                                    <option
                                                        value={0}>
                                                        Elegir subcategoria
                                                    </option>
                                                    {newSubcategories.map((subcategory, index) => (
                                                        <option
                                                            key={index}
                                                            value={subcategory.id}>
                                                            {subcategory.name}
                                                        </option>
                                                    ))}
                                                </select>
                                                {errorCreate.sub_category_id && <div className="text-danger fs-12">
                                                    {errorCreate.sub_category_id}
                                                </div>}
                                            </div>
                                        )}
                                        <div className="col-sm-12">
                                            <label htmlFor="taxtno200" className="form-label">Subir imagenes</label>
                                            <input
                                                type="file"
                                                className="form-control"
                                                onChange={addImage}
                                            />
                                        </div>
                                        <div className={'row'}>
                                            {photos.length > 0 && photos.map((photo, index) => (
                                                <div className={'col-4 col-2'} key={index}>
                                                    <img  alt={''} src={photo.url}  className={'pre-image-product'}/>
                                                    <i onClick={() => deleteImage(photo)} className="fas fa-regular fa-trash icon-trash"></i>
                                                </div>
                                            ))}
                                        </div>
                                        {isAddedReference && (
                                            <>
                                                {references.length > 0 && references.map((reference, index) => (
                                                    <div key={index}>
                                                        <div className="mb-3 mt-1">
                                                            <label className="mb-1 form-label">{`Nombre de referencia ${index +1 }`}</label>
                                                            <input type="text" className="form-control"
                                                                   placeholder="Referencia"
                                                                   name={'reference'}
                                                                   onChange={(value) => handle(index, `reference`, value)}
                                                            />
                                                        </div>
                                                        <div className="mb-3 mt-1">
                                                            <label className="mb-1 form-label">{`Cantidad de referencia ${index + 1}`}</label>
                                                            <input type="text" className="form-control"
                                                                   placeholder="Cantidad"
                                                                   name={'quantity'}
                                                                   onChange={(value) => handle(index, `quantity`, value)}
                                                            />
                                                        </div>
                                                    </div>
                                                ))}
                                            </>
                                        )}
                                        <div className="col-sm-12  d-flex justify-content-center">
                                            <button type="button" onClick={handleAddReference}
                                                    className="btn btn-primary">Agregar referencia</button>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                            <div className="modal-footer">
                                {!requestingCreate && (
                                    <button type="button"
                                            className="btn btn-secondary col-sm-3"
                                            onClick={close}
                                            data-bs-dismiss="modal">Cancelar</button>
                                )}
                                {requestingCreate && (
                                    <button className="btn btn-primary lift btn-spinner container-spinner col-sm-3">
                                        <i className="fa fa-spinner fa-spin"></i>
                                    </button>
                                )}
                                {!requestingCreate && (
                                    <button type="submit" className="btn btn-primary col-sm-3">Crear</button>
                                )}
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </Modal>
    )
};
