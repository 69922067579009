import React from "react";
import {Link} from "react-router-dom";
import Logo from "../../assets/images/logo-white.png";
import {menuItem} from "../../utils/menu";
import {useDispatch} from "react-redux";
import {openModalLogout, setSidebarMini} from "../../redux/auth/actions";

export const Sidebar = ({
                            routeCurrency,
                            isSidebarMini}) => {

    const dispatch = useDispatch();

    const handleLogout = () => {
        dispatch(openModalLogout(true))
    };

    const openChildren = (id) => {
        let otherTabs = document.getElementsByClassName("has-children")
        if (otherTabs) {
            for (var i = 0; i < otherTabs.length; i++) {
                if (otherTabs[i].id !== id) {
                    otherTabs[i].className = otherTabs[i].className.replace(" show", "");
                    if (otherTabs[i].parentElement.children.length > 1) {
                        otherTabs[i].parentElement.children[0].setAttribute("aria-expanded", "false")
                    }
                }
            }
        }

        let menuTab = document.getElementById(id)
        if (menuTab) {
            if (menuTab.classList.contains("show")) {
                menuTab.classList.remove("show")
                if (menuTab.parentElement.children.length > 1) {
                    menuTab.parentElement.children[0].setAttribute("aria-expanded", "false")
                }
            } else {
                menuTab.classList.add("show")
                if (menuTab.parentElement.children.length > 1) {
                    menuTab.parentElement.children[0].setAttribute("aria-expanded", "true")
                }
            }
        }
    }

    const openChildren1 = (id) => {
        let otherTabs = document.getElementsByClassName("has-children")
        if (otherTabs) {
            for (var i = 0; i < otherTabs.length; i++) {
                otherTabs[i].className = otherTabs[i].className.replace(" show", "");
            }
        }
        let menuTab = document.getElementById(id)
        if (menuTab) {
            menuTab.classList.add("show")
            if (menuTab.parentElement.children.length > 1) {
                menuTab.parentElement.children[0].setAttribute("aria-expanded", "true")
            }
        }
    }
    return (<div id="mainsidemenu" className={`sidebar px-4 py-4 py-md-4 me-0 ${isSidebarMini ? "sidebar-mini" : ""}`} style={{ overflow: 'scroll' }}>
            <div className="d-flex flex-column h-100">
                <Link to="/orders" className="mb-0 brand-icon" >
                    <img style={{width: 50, height: 40, marginRight: isSidebarMini ? 0 : -10}}  src={Logo} alt="logo" />
                    <span className="logo-text"  >Thivisha</span>
                </Link>
                <ul className="menu-list flex-grow-1 mt-3">
                    {menuItem.map((menu, index) => {
                        if (menu.children.length === 0) {
                            return <li key={index} className="collapsed">
                                <Link to={menu.identifier === 'Close' ? '' :`/${menu.routerLink}`}
                                      onClick={menu.identifier === 'Close' ? () => handleLogout() : ''}
                                      className={`m-link ${("/" + menu.routerLink === routeCurrency) ? "active" : ""}`} >
                                    <i className={menu.iconClass}></i>
                                    <span>{menu.name}</span>
                                    <span className="ms-auto text-end fs-5">
                                    </span>
                                </Link>
                            </li>
                        }else {
                            return <li key={index} className=" collapsed">
                                <Link
                                    to={'#!'}
                                    className={`m-link ${(menu.children.filter((d) => "/" + d.routerLink === routeCurrency).length > 0) ? "active" : ""}`}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        openChildren("menu-Pages" + index)
                                    }}>
                                    <i className={menu.iconClass}></i>
                                    <span>{menu.name}</span>
                                    <span className="arrow icofont-dotted-down ms-auto text-end fs-5" />
                                </Link>
                                <ul className="sub-menu collapse has-children" id={'menu-Pages' + index}>
                                    {menu.children.map((data, ind) => {
                                        if (routeCurrency === "/" + data.routerLink) {
                                            setTimeout(() => {
                                                openChildren1("menu-Pages" + index)
                                            }, 1);
                                        }
                                        return <li key={ind}>
                                            <Link className={ "/" + data.routerLink  === routeCurrency ? "ms-link active" : "ms-link"}
                                                  to={`/${data.routerLink}`}>
                                                {isSidebarMini && <i className={data.iconClass} style={{marginLeft: -15, marginBottom: 10,}}></i>}
                                                {!isSidebarMini &&  <span>{data.name}</span>}

                                            </Link>
                                        </li>
                                    })}
                                </ul>
                            </li>
                        }

                    })}
                </ul>
                <button type="button" className="btn btn-link sidebar-mini-btn text-light" onClick={() => { dispatch(setSidebarMini(!isSidebarMini)) }}>
                    <span className="ms-2"><i className="icofont-bubble-right"></i></span>
                </button>
            </div>
        </div>
    )
}

