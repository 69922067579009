import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Modal from 'react-bootstrap/Modal';

export const ListImages = ({
                               visible,
                               close,
                               images,
                               name,
}) => {

    return (
        <Modal show={visible} onHide={close} style={{ display: 'block' }}>
            <Carousel
                showThumbs={false}
            >
                {images.length > 0 && images.map((image, index) => (
                    <div key={index}>
                        <img alt={`${name}` + index} src={image.url} />
                    </div>
                ))}
            </Carousel>
        </Modal>
    )
}
