import React from 'react';
import {Routes, Route} from "react-router-dom";
import {Order} from "../view/Order/Order";
import {useSelector} from "react-redux";
import {ProtectedRoute} from "../components/Route/ProtectedRoute";
import {Login} from "../view/Login/Login";
import {ForgotPassword} from "../view/ForgotPassword/ForgotPassword";
import {Product} from "../view/Product/Product";
import {NotFound} from "../view/NotFound/NotFound";
import {Account} from "../view/Account/Account";
import {Buyer} from "../view/Buyer/Buyer";
import {Category} from "../view/Category/Category";
import {Subcategory} from "../view/Subcateogory/Subcategory";
import {Provider} from "../view/Provider/Provider";
import {Payment} from "../view/Payment/Payment";
import {Packing} from "../view/Packing/Packing";
import {Section} from "../view/Section/Section";
import {Dashboard} from "../view/Dashboard/Dashboard";
import {DamagedProduct} from "../view/DamagedProduct/DamagedProduct";
import {DamagedPacking} from "../view/DamagedPacking/DamagedPacking";

const App = () => {

    const {user} = useSelector(state => state.auth);

    return(
        <div id="ebazar-layout" className='theme-blue'>
            <Routes>
                <Route path={"/"} element={<Login />} />
                <Route path={"reset_password"} element={<ForgotPassword />} />
                <Route path={"*"} element={<NotFound />} />
                <Route element={<ProtectedRoute user={user}/>}>
                    <Route path={"dashboard"} element={ <Dashboard />} />
                    <Route path={"orders"} element={ <Order />} />
                    <Route path={"products"} element={ <Product />} />
                    <Route path={"accounts"} element={ <Account />} />
                    <Route path={"buyers"} element={ <Buyer />} />
                    <Route path={"categories"} element={ <Category />} />
                    <Route path={"subcategories"} element={ <Subcategory />} />
                    <Route path={"providers"} element={ <Provider />} />
                    <Route path={"payments"} element={ <Payment />} />
                    <Route path={"packing"} element={ <Packing />} />
                    <Route path={"sections"} element={ <Section />} />
                    <Route path={"damagedProduct"} element={<DamagedProduct />} />
                    <Route path={"damagedPacking"} element={<DamagedPacking />} />
                </Route>
            </Routes>
        </div>
    )
};

export default App;
