import React from "react";
import DataTable from "react-data-table-component";
import {FormatNumber} from "../../constants/FormatNumber";
import {PaginateComponent} from "../Paginate/Paginate";

export const TableAccount = ({
                                 accounts,
                                 metaAccounts,
                                 pageCurrency,
                                 handleChangePage,
                                 modalHistory,
                                 setFocusHistory,
                                 setFocusState,
                                 setModalAddPayment,
                                 setFocusAccountId
}) => {

    const columns = [
        {
            name: 'Id',
            selector: row => row.id,
            center: true,
            width: '50px'
        },
        {
            name: 'Total de la Compra',
            selector: row => <FormatNumber value={row.total}
                                    className={'numeric-input-disabled'}
                                    disabled={true}/>,
            center: true,
            width: '150px'
        },
        {
            name: 'Por pagar',
            selector: row => <FormatNumber value={row.should}
                                           className={'numeric-input-disabled'}
                                           disabled={true}/>,
            center: true,
            width: '150px'
        },
        {
            name: 'Pagado',
            selector: row => <FormatNumber value={row.payment}
                                           className={'numeric-input-disabled'}
                                           disabled={true}/>,
            center: true,
            width: '150px'
        },
        {
            name: 'Comprador',
            selector: row => row.buyer.name,
            center: true,
            width: '250px'
        },
        {
            name: 'Historial',
            selector: row => <>{row.history.length > 0 ? <button type="button"  onClick={() => {
                    modalHistory(true);
                    setFocusHistory(row.history);
                    setFocusState(row.state);
            }} className="btn button-table-account">
                <i className="fa-solid fa-folder-open fa-lg icon-table"/>
            </button> : <div>Sin historial</div>}</>,
            center: true,
            width: '150px'
        },
        {
            name: 'Estado',
            selector: row => <div className={'button-state'} style={{background: row.state.color}}>
                <div style={{color: 'white'}}>{row.state.name}</div>
            </div>,
            center: true,
            width: '250px'
        },
        {
            name: 'Acción',
            selector: row => <>{row.state.id === 10 ? <button type="button"  onClick={() => {
                setModalAddPayment(true);
                setFocusAccountId(row.id);
            }} className="btn button-table-account">
                <i className="fa-solid fa-file-circle-plus fa-lg icon-table"/>
            </button> : null}</>,
            center: true,
            width: '120px'
        },
    ];

    return (
        <div className="card mb-3">
            <DataTable
                title="Datatable Product"
                columns={columns}
                data={accounts}
                defaultSortField="title"
                pagination={false}
                selectableRows={false}
                className="table myDataTable table-hover align-middle mb-0 d-row nowrap dataTable no-footer dtr-inline"
                highlightOnHover={true}
                noDataComponent={'No hay cuentas en estos momentos'}
            />
            {metaAccounts.total > 9 && (
                <PaginateComponent
                    pageCurrency={pageCurrency}
                    handleChangePage={handleChangePage}
                    meta={metaAccounts}
                />
            )}
        </div>
    );
}
