import React from 'react';
import Modal from 'react-bootstrap/Modal';
import {Formik} from "formik";
import {updateProductReferences} from "../../redux/product/actions";
import {useDispatch, useSelector} from "react-redux";

export const ListReferences = ({
                                   visible,
                                   close,
                                   references,
                                   edit,
                                   setEdit
}) => {

    const dispatch = useDispatch();

    const {token} = useSelector(state => state.auth);
    const {updateReferenceRequesting,
        updateReferenceError} = useSelector(state => state.product);


    const handleUpdateReference = (values) => {
        let body = {
            '_method': 'PUT',
            reference: values.reference,
            quantity: parseInt(values.quantity),
            id: values.id,
        }
        dispatch(updateProductReferences(token, body));
    };

    return (
        <Modal
            show={visible} onHide={close} style={{ display: 'block' }}>
            <Modal.Header className="modal-header" closeButton>
                <h5 className="modal-title  fw-bold" id="addProduct"> Referencias</h5>
            </Modal.Header>
            <Modal.Body className="modal-body">
                <div className="deadline-form">
                    {references.map((reference, index) => (
                        <Formik
                            key={index}
                            initialValues={{
                                reference: '',
                                quantity: 0,
                            }}
                            onSubmit={(values ) => {
                                let body = {
                                    reference: values.reference !== '' ? values.reference : reference.reference,
                                    quantity: values.quantity !== 0 ? values.quantity : reference.quantity,
                                    id: reference.id,
                                }
                                handleUpdateReference(body)
                            }}
                        >
                            {({
                                  handleChange,
                                  handleSubmit,
                              }) => (
                                <form >
                                    <div className={`row g-3 ${index !== 0 ? 'mt-2' : ''}`} >
                                        <div className="col-sm-4">
                                            <label htmlFor="item100" className="form-label">Referencia</label>
                                            <input type="text"
                                                   className="form-control"
                                                   name={'reference'}
                                                   defaultValue={reference.reference}
                                                   disabled={!(edit === reference.id)}
                                                   onChange={handleChange}
                                            />
                                        </div>
                                        <div className="col-sm-2">
                                            <label htmlFor="item100" className="form-label">Cantidad</label>
                                            <input type="text"
                                                   className="form-control"
                                                   name={'quantity'}
                                                   defaultValue={reference.quantity}
                                                   disabled={!(edit === reference.id)}
                                                   onChange={handleChange}
                                            />
                                        </div>
                                        <div className="col-sm-6">
                                            <label htmlFor="item100" className="form-label">Acción</label>
                                            <div className="containar">
                                                <div className="row ">
                                                    <button type="button"
                                                            onClick={() => {
                                                                if(edit !== reference.id)
                                                                    setEdit(reference.id)
                                                                else
                                                                    handleSubmit()
                                                            }}
                                                            className="btn button-table-edit-references button-input">
                                                        {updateReferenceRequesting && (edit === reference.id) && (
                                                            <i className={'fa fa-spinner fa-spin fa-lg icon-table'} />
                                                        )}
                                                        {!updateReferenceRequesting && (edit === reference.id) && (
                                                            <i className={'fa-solid fa-floppy-disk fa-lg icon-table'} />
                                                        )}
                                                        {!updateReferenceRequesting && (edit !== reference.id) && (
                                                            <i className={'fa-solid fa-pen-to-square fa-lg icon-table'}/>
                                                        )}
                                                    </button>
                                                    {edit === reference.id &&
                                                        <button type="button"
                                                                onClick={() => {
                                                                        setEdit(0)
                                                                }}
                                                                className="btn button-table-reverse button-input">
                                                        <i className="fa-solid fa-xmark fa-lg icon-table"></i>
                                                    </button>}
                                                </div>
                                            </div>
                                        </div>
                                        {edit === reference.id && updateReferenceError.message &&
                                            <div className="text-danger fs-12 mt-2">
                                                {updateReferenceError.message}
                                            </div>}
                                    </div>
                                </form>
                            )}
                        </Formik>
                    ))}
                </div>
            </Modal.Body>
        </Modal>
    )
};
