import React from 'react';
import Modal from 'react-bootstrap/Modal';
import {Formik} from "formik";
import {FormatNumber} from "../../constants/FormatNumber";
import {useDispatch, useSelector} from "react-redux";
import {createPacking} from "../../redux/packing/actions";

export const AddPacking = ({
                               visible,
                               close,
                               photos,
                               deleteImage,
                               setPhotos,
}) => {

    const dispatch = useDispatch();

    const {token} = useSelector(state => state.auth);
    const {createPackingRequesting, createPackingError} = useSelector(state => state.packing);

    const addImage = (photo) => {
        let reader = new FileReader();
        reader.readAsDataURL(photo.target.files[0]);
        reader.onload = () => {
            let values =  {
                path: reader.result,
                url: URL.createObjectURL(photo.target.files[0]),
                name: photo.target.files[0].name,
            }
            setPhotos([...photos, values])
        };
    };

    const handleCreatePacking = (data) => {
        dispatch(createPacking(token, data, photos))
    };

    return (
        <Modal show={visible} onHide={() =>{ return true}} style={{ display: 'block' }}>
            <div className="modal-content">
                <Modal.Header className="modal-header" closeButton onClick={close}>
                    <h5 className="modal-title  fw-bold" id="addProduct"> Agregar empaque</h5>
                </Modal.Header>
                <Formik
                    initialValues={{ name: '', sku: '', stock: '',
                        sale_price: '', purchase_price: ''}}
                    onSubmit={(values ) => {
                        handleCreatePacking(values)
                    }}
                >
                    {({
                          handleChange,
                          handleSubmit,
                          setFieldValue,
                      }) => (
                        <form onSubmit={handleSubmit}>
                            <Modal.Body className="modal-body">
                                <div className="deadline-form">
                                    <div className="row g-3 mb-3">
                                        <div className="col-sm-12">
                                            <label htmlFor="it em100" className="form-label">Nombre</label>
                                            <input type="text"
                                                   className="form-control"
                                                   placeholder="Nombre"
                                                   name={'name'}
                                                   onChange={handleChange}
                                            />
                                            {createPackingError?.name && <div className="text-danger fs-12">
                                                {createPackingError.name}
                                            </div>}
                                        </div>
                                        <div className="col-sm-12">
                                            <label htmlFor="it    em100" className="form-label">Sku</label>
                                            <input type="text"
                                                   className="form-control"
                                                   placeholder="Sku"
                                                   name={'sku'}
                                                   onChange={handleChange}
                                            />
                                            {createPackingError?.sku && <div className="text-danger fs-12">
                                                {createPackingError.sku}
                                            </div>}
                                        </div>
                                        <div className="col-sm-12">
                                            <label htmlFor="it    em100" className="form-label">Stock</label>
                                            <input type="numeric"
                                                   className="form-control"
                                                   placeholder="Stock"
                                                   name={'stock'}
                                                   onChange={handleChange}
                                            />
                                            {createPackingError?.stock && <div className="text-danger fs-12">
                                                {createPackingError.stock}
                                            </div>}
                                        </div>
                                        <div className="col-sm-12">
                                            <label htmlFor="it    em100" className="form-label">Precio de venta</label>
                                            <FormatNumber
                                                className="form-control"
                                                onValueChange={(value) => setFieldValue('sale_price', value.floatValue)}
                                                placeholder="Precio de venta"
                                            />
                                            {createPackingError?.sale_price && <div className="text-danger fs-12">
                                                {createPackingError.sale_price}
                                            </div>}
                                        </div>
                                        <div className="col-sm-12">
                                            <label htmlFor="it    em100" className="form-label">Precio de compra</label>
                                            <FormatNumber
                                                className="form-control"
                                                onValueChange={(value) => setFieldValue('purchase_price', value.floatValue)}
                                                placeholder="Precio de venta"
                                            />
                                            {createPackingError?.purchase_price && <div className="text-danger fs-12">
                                                {createPackingError.purchase_price}
                                            </div>}
                                        </div>

                                        <div className="col-sm-12">
                                            <label htmlFor="taxtno200" className="form-label">Subir imagenes</label>
                                            <input
                                                type="file"
                                                className="form-control"
                                                onChange={addImage}
                                            />
                                        </div>
                                        <div className={'row'}>
                                            {photos.length > 0 && photos.map((photo, index) => (
                                                <div className={'col-4 col-2'} key={index}>
                                                    <img  alt={''} src={photo.url}  className={'pre-image-product'}/>
                                                    <i onClick={() => deleteImage(photo)} className="fas fa-regular fa-trash icon-trash"></i>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                            <div className="modal-footer">
                                {!createPackingRequesting && (
                                    <button type="button"
                                            className="btn btn-secondary col-sm-3"
                                            onClick={close}
                                            data-bs-dismiss="modal">Cancelar</button>
                                )}
                                {createPackingRequesting && (
                                    <button className="btn btn-primary lift btn-spinner container-spinner col-sm-3">
                                        <i className="fa fa-spinner fa-spin"></i>
                                    </button>
                                )}
                                {!createPackingRequesting && (
                                    <button type="submit" className="btn btn-primary col-sm-3">Crear</button>
                                )}
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </Modal>
    )
};
