import React from 'react'
import {Modal} from "react-bootstrap";

export const ModalReturnProducts = ({
                               visible,
                               close,
                               products,
                               packing
}) => {

    return (
        <Modal show={visible} onHide={close} style={{ display: 'block' }}>
            <div className="modal-content">
                <Modal.Header className="modal-header" closeButton>
                    <h5 className="modal-title  fw-bold" id="expeditLabel1111">Información de los productos devueltos</h5>
                </Modal.Header>
                <Modal.Body className="modal-body">
                    {products.map((product, index) => (
                        <div key={index}>
                            <label htmlFor="item100" className="form-label">Producto {index + 1}</label>
                            <div className="deadline-form">
                                <div className="row g-3 mb-3">
                                    <div className="col-sm-12">
                                        <label htmlFor="item100" className="form-label">Nombre del producto:</label>
                                        <input type="text" className="form-control" id="item100" value={product.product_name} disabled={true} />
                                    </div>
                                    {product.reference_name !== '' && (
                                        <div className="col-sm-12">
                                            <label htmlFor="abc11edit" className="form-label">Referencia:</label>
                                            <input type="text" className="form-control" id="item100" value={product.reference_name} disabled={true} />
                                        </div>
                                    )}
                                    <div className="row mt-2 mb-3 align-items-center">
                                        <img className="avatar mini-image-product" src={product.product_image} alt="profile" />
                                        <div className="col-sm-3">
                                            <label htmlFor="abc11edit" className="form-label">Cantidad:</label>
                                            <input type="text" className="form-control" id="item100" value={product.quantity} disabled={true} />
                                        </div>
                                        <div className="col-sm-6">
                                            <label htmlFor="abc11edit" className="form-label">Fecha de devolución:</label>
                                            <input type="text" className="form-control" id="item100" value={product.date.substring(0,10)} disabled={true} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    {packing.map((pack, index) => (
                        <div key={index}>
                            <label htmlFor="item100" className="form-label">Empaque {index + 1}</label>
                            <div className="deadline-form">
                                <div className="row g-3 mb-3">
                                    <div className="col-sm-12">
                                        <label htmlFor="item100" className="form-label">Nombre del Empaque:</label>
                                        <input type="text" className="form-control" id="item100" value={pack.packing_name} disabled={true} />
                                    </div>
                                    <div className="row mt-2 mb-3 align-items-center">
                                        <img className="avatar mini-image-product" src={pack.packing_image} alt="profile" />
                                        <div className="col-sm-3">
                                            <label htmlFor="abc11edit" className="form-label">Cantidad:</label>
                                            <input type="text" className="form-control" id="item100" value={pack.quantity} disabled={true} />
                                        </div>
                                        <div className="col-sm-6">
                                            <label htmlFor="abc11edit" className="form-label">Fecha de devolución:</label>
                                            <input type="text" className="form-control" id="item100" value={pack.date.substring(0,10)} disabled={true} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </Modal.Body>
            </div>
        </Modal>
    )
}
