import React, {useEffect} from "react";
import {Sidebar} from "../../components/Sidebar/Sidebar";
import {Header} from "../../components/Header/Header";
import {useDispatch, useSelector} from "react-redux";
import { Tab, Row, Col } from 'react-bootstrap';
import {cacheDashboard, getDashboard} from "../../redux/dashboard/actions";
import {FormatNumber} from "../../constants/FormatNumber";
import moment from 'moment';

export const Dashboard = () => {

    const dispatch = useDispatch();
    const date = new Date();
    const today = moment(date).format('L');
    const dayLocal = JSON.parse(localStorage.getItem('@thivisha_date'));
    const dashboard = JSON.parse(localStorage.getItem('@thivisha_dashboard'));
    const {user, successMe, token, sidebarMini} = useSelector(state => state.auth);
    const {getDashboardSuccess, dashboardHeader, dashboardCard} = useSelector(state => state.dashboard);

    useEffect(() => {
        if(!getDashboardSuccess && successMe){
            if(today !== dayLocal){
                dispatch(getDashboard(token));
                localStorage.setItem('@thivisha_date', JSON.stringify(today));
            }else{
                if(dashboard !== null)
                    dispatch(cacheDashboard(dashboard));
            }
        }
    }, []);

    return (
        <div className={'d-flex'}>
            <Sidebar isSidebarMini={sidebarMini}
                     routeCurrency={'/dashboard'} />
            <div className='main px-lg-12 px-md-4' >
                <Header
                    search={false}
                    user={user}/>
                <div className={`${sidebarMini ? 'table-widthSideMini': 'table-widthSide'} body d-flex py-3`}>
                    <div className={'table-widthMini'}>
                        <div className="row g-3 mb-3 row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-2 row-cols-xl-4">
                            {dashboardHeader.map((dash, index) => (
                                <div className="col" key={index}>
                                    <div className={`${dash.background} alert mb-0`}>
                                        <div className="d-flex align-items-center">
                                            <div className={`avatar rounded no-thumbnail ${dash.color} text-light`}><i className={`fa ${dash.icon} fa-lg`}></i></div>
                                            <div className="flex-fill ms-3 text-truncate">
                                                <div className="h6 mb-0">{dash.title}</div>
                                                <span className="small">{dash.price ? <FormatNumber value={dash.value}
                                                                                      className={'numeric-not-input-disabled'}
                                                                                      disabled={true}/> : dash.value}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="mt-1">
                            <Tab.Container id="left-tabs-example" defaultActiveKey="first" className="col-lg-12 col-md-12">
                                <Row>
                                    <Col sm={12}>
                                        <Tab.Content className="tab-content mt-1">
                                            <Tab.Pane eventKey="first" className="tab-pane fade show" id="summery-today">
                                                <div className="row g-1 g-sm-3 mb-3 row-deck">
                                                    {dashboardCard.map((dash, index) => (
                                                        <div key={index} className="col-xl-4 col-lg-4 col-md-4 col-sm-6">
                                                            <div className="card">
                                                                <div className="card-body py-xl-4 py-3 d-flex flex-wrap align-items-center justify-content-between">
                                                                    <div className="left-info">
                                                                        <span className="text-muted">{dash.title}</span>
                                                                        <div><span className="fs-6 fw-bold me-2">{dash.value}</span></div>
                                                                        {dash?.secondValue && (
                                                                            <div><span className="fs-10 fw-bold me-2">
                                                                                {dash.price ? <FormatNumber value={dash.secondValue}
                                                                                                            className={'fs-10 fw-bold numeric-not-input-disabled'}
                                                                                                            disabled={true}/> : dash.secondValue}
                                                                            </span></div>
                                                                        )}
                                                                    </div>
                                                                    <div className="right-icon">
                                                                        <i className={`${dash.icon} fs-3 ${dash.colorIcon}`}></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Col>
                                </Row>
                            </Tab.Container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
