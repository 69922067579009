import {
    CLEAN_LOGIN,
    GET_ME_ATTEMPT,
    GET_ME_FAILURE,
    GET_ME_SUCCESS,
    LOGIN_ATTEMPT,
    LOGIN_FAILURE,
    LOGIN_SUCCESS, LOGOUT_ATTEMPT, LOGOUT_SUCCESS, OPEN_MODAL, SIDEBAR_MINI
} from "./constants";

export const login = values => ({
    type: LOGIN_ATTEMPT,
    values,
});

export const loginSuccess = () => ({
    type: LOGIN_SUCCESS,
});

export const loginFailure = error => ({
    type: LOGIN_FAILURE,
    error,
});

export const cleanLogin = () => ({
    type: CLEAN_LOGIN,
});

export const getMe = (token) => ({
    type: GET_ME_ATTEMPT,
    token,
});

export const getMeSuccess = user => ({
    type: GET_ME_SUCCESS,
    user,
});

export const getMeFailure = error => ({
    type: GET_ME_FAILURE,
    error,
});

export const logout = (token, values) => ({
    type: LOGOUT_ATTEMPT,
    token,
    values,
});

export const logoutSuccess = () => ({
    type: LOGOUT_SUCCESS,
});

export const logoutFailure = error => ({
    type: LOGIN_FAILURE,
    error
});

export const openModalLogout = change => ({
    type: OPEN_MODAL,
    change,
});

export const setSidebarMini = change => ({
    type: SIDEBAR_MINI,
    change,
});

