import {
    CLEAN_SUBCATEGORY,
    CREATE_SUBCATEGORY_ATTEMPT, CREATE_SUBCATEGORY_FAILURE, CREATE_SUBCATEGORY_SUCCESS,
    GET_SUBCATEGORY_ATTEMPT,
    GET_SUBCATEGORY_FAILURE,
    GET_SUBCATEGORY_SUCCESS, UPDATE_SUBCATEGORY_ATTEMPT, UPDATE_SUBCATEGORY_FAILURE, UPDATE_SUBCATEGORY_SUCCESS
} from "./constants";

export const getSubcategory = token => ({
    type: GET_SUBCATEGORY_ATTEMPT,
    token,
});

export const getSubcategorySuccess = subcategories => ({
    type: GET_SUBCATEGORY_SUCCESS,
    subcategories,
});

export const getSubcategoryFailure = error => ({
    type: GET_SUBCATEGORY_FAILURE,
    error,
});

export const createSubcategory = (token, values) => ({
    type: CREATE_SUBCATEGORY_ATTEMPT,
    token,
    values,
});

export const createSubcategorySuccess = subcategory => ({
    type: CREATE_SUBCATEGORY_SUCCESS,
    subcategory,
});

export const createSubcategoryFailure = error => ({
    type: CREATE_SUBCATEGORY_FAILURE,
    error,
});

export const updateSubcategory = (token, values) => ({
    type: UPDATE_SUBCATEGORY_ATTEMPT,
    token,
    values,
});

export const updateSubcategorySuccess = subcategory => ({
    type: UPDATE_SUBCATEGORY_SUCCESS,
    subcategory,
});

export const updateSubcategoryFailure = error => ({
    type: UPDATE_SUBCATEGORY_FAILURE,
    error,
});

export const cleanSubcategory = () => ({
    type: CLEAN_SUBCATEGORY,
});
