import React from "react";
import DataTable from "react-data-table-component";
import {PaginateComponent} from "../Paginate/Paginate";
import {FormatNumber} from "../../constants/FormatNumber";

export const TablePacking = ({
                                 packing,
                                 metaPacking,
                                 pageCurrency,
                                 handleChangePage,
                                 setFocusPacking,
                                 openModal
}) => {

    const columns = [
        {
            name: 'Id',
            selector: row => row.id,
            center: true,
            width: '50px'
        },
        {
            name: 'Nombre',
            selector: row => row.name,
            center: true,
            width: '350px'
        },
        {
            name: 'Sku',
            selector: row => row.sku,
            center: true,
            width: '100px'
        },
        {
            name: 'Stock',
            selector: row => row.stock,
            center: true,
            width: '100px'
        },
        {
            name: 'Precio de venta',
            selector: row => <FormatNumber value={row.sale_price}
                                           className={'numeric-input-disabled'}
                                           disabled={true}/>,
            center: true,
            width: '150px'
        },
        {
            name: 'Precio de compra',
            selector: row => <FormatNumber value={row.purchase_price}
                                           className={'numeric-input-disabled'}
                                           disabled={true}/>,
            center: true,
            width: '150px'
        },
        {
            name: 'Imagen',
            cell:row=><><img onClick={() => {
                setFocusPacking(row);
                openModal('viewPhotos')
            }}
                             className="avatar rounded lg border"
                             src={row.images.length > 0 ? row.images[0].url :
                                 'https://dlinkmea.com/images/no-product.png'}
                             alt="" /></>,
            center: true,
        },
        {
            name: 'Estado',
            selector: row => <div className={'button-state'} style={{background: row.state.color}}>
                <div style={{color: 'white'}}>{row.state.name}</div>
            </div>,
            width: '200px',
            center: true
        },
        {
            name: 'Acciones',
            selector: row => <div >
                <button type="button" className="btn button-table-account margin-buttons"
                        onClick={() => {
                            openModal('addStock');
                            setFocusPacking(row);
                        }}>
                    <i className="fa-solid fa-plus fa-lg icon-table" />
                </button>
                <button type="button" className="btn button-table-category margin-buttons"
                        onClick={() => {
                            openModal('editPacking');
                            setFocusPacking(row);
                        }}>
                    <i className="fa-solid fa-pen-to-square fa-lg icon-table" />
                </button>
                <button type="button" className="btn button-table-account margin-buttons"
                        onClick={() => {
                            openModal('editImage');
                            setFocusPacking(row);
                        }}>
                    <i className="fa-solid fa-image fa-lg icon-table" />
                </button>
                {row.stock > 0 && (
                    <button type="button" className="btn button-table-damaged"
                            onClick={() => {
                                openModal('addDamaged');
                                setFocusPacking(row);
                            }}>
                        <i className="fa-solid fa-screwdriver-wrench icon-table" />
                    </button>
                )}
            </div>,
            width: '400px',
            center: true
        },
    ];

    return (
        <div className="card mb-3">
            <DataTable
                title="Datatable Packing"
                columns={columns}
                data={packing}
                defaultSortField="title"
                pagination={false}
                selectableRows={false}
                className="table myDataTable table-hover align-middle mb-0 d-row nowrap dataTable no-footer dtr-inline"
                highlightOnHover={true}
                noDataComponent={'No hay paquetes en estos momentos'}
            />
            {metaPacking.total > 9 && (
                <PaginateComponent
                    pageCurrency={pageCurrency}
                    handleChangePage={handleChangePage}
                    meta={metaPacking}
                />
            )}
        </div>
    );
}
