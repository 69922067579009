import {CLEAN_BUYER, GET_BUYERS_ATTEMPT, GET_BUYERS_FAILURE, GET_BUYERS_SUCCESS} from "./constants";

export const getBuyers = (token, page) => ({
    type: GET_BUYERS_ATTEMPT,
    token,
    page,
});

export const getBuyersSuccess = buyers => ({
    type: GET_BUYERS_SUCCESS,
    buyers,
});

export const getBuyersFailure = error => ({
    type: GET_BUYERS_FAILURE,
    error,
});

export const cleanBuyer = () => ({
    type: CLEAN_BUYER,
});
