import {
    ADD_STOCK_PACKING_ATTEMPT,
    ADD_STOCK_PACKING_FAILURE,
    ADD_STOCK_PACKING_SUCCESS, ALL_GET_PACKING_ATTEMPT, ALL_GET_PACKING_FAILURE, ALL_GET_PACKING_SUCCESS,
    CLEAN_PACKING,
    CREATE_PACKING_ATTEMPT,
    CREATE_PACKING_FAILURE,
    CREATE_PACKING_SUCCESS, GET_PACK,
    GET_PACKING_ATTEMPT,
    GET_PACKING_FAILURE,
    GET_PACKING_SUCCESS,
    UPDATE_IMAGE_PACKING_ATTEMPT, UPDATE_IMAGE_PACKING_FAILURE, UPDATE_IMAGE_PACKING_SUCCESS,
    UPDATE_PACKING_ATTEMPT,
    UPDATE_PACKING_FAILURE,
    UPDATE_PACKING_SUCCESS
} from "./constants";

const initialState = {
    getPackingRequesting: false,
    getPackingSuccess: false,
    getPackingError: '',
    packing: [],
    linkPacking: {},
    metaPacking: {},
    pagePacking: 0,
    createPackingRequesting: false,
    createPackingSuccess: false,
    createPackingError: '',
    updatePackingRequesting: false,
    updatePackingSuccess: false,
    updatePackingError: '',
    addStockPackingRequesting: false,
    addStockPackingSuccess: false,
    addStockPackingFailure: '',
    updateImagePackingRequesting: false,
    updateImagePackingSuccess: false,
    updateImagePackingError: '',
    allGetPackingRequesting: false,
    allGPackingSuccess: false,
    allGetPackingError: '',
    allPacking: [],
}

const reducer = (state = initialState, action) => {
    switch (action.type){
        case GET_PACKING_ATTEMPT:
            return {
                ...state,
                getPackingRequesting: true,
                pagePacking: action.page,
            };
        case GET_PACKING_SUCCESS:
            return {
                ...state,
                getPackingRequesting: false,
                getPackingSuccess: true,
                packing: action.packing.data,
                linkPacking: action.packing.links,
                metaPacking: action.packing.meta,
            };
        case GET_PACKING_FAILURE:
            return {
                ...state,
                getPackingRequesting: false,
                getPackingError: action.error,
            };
        case CREATE_PACKING_ATTEMPT:
            return {
                ...state,
                createPackingRequesting: true,
                createPackingSuccess: false,
                updatePackingSuccess: false,
                addStockPackingSuccess: false,
                updateImagePackingSuccess: false,
            };
        case CREATE_PACKING_SUCCESS:
            return {
                ...state,
                createPackingRequesting: false,
                createPackingSuccess: true,
                packing: [action.packing, ...state.packing],
            };
        case CREATE_PACKING_FAILURE:
            return {
                ...state,
                createPackingRequesting: false,
                createPackingError: action.error,
            };
        case UPDATE_PACKING_ATTEMPT:
            return {
                ...state,
                updatePackingRequesting: true,
                updatePackingSuccess: false,
                createPackingSuccess: false,
                addStockPackingSuccess: false,
                updateImagePackingSuccess: false,
            };
        case UPDATE_PACKING_SUCCESS:
            return {
                ...state,
                updatePackingRequesting: false,
                updatePackingSuccess: true,
                packing: state.packing.map(packing => (
                    packing.id === action.packing.id ? action.packing : packing
                ))
            };
        case UPDATE_PACKING_FAILURE:
            return {
                ...state,
                updatePackingRequesting: false,
                updatePackingError: action.error,
            };
        case ADD_STOCK_PACKING_ATTEMPT:
            return {
                ...state,
                addStockPackingRequesting: true,
                addStockPackingSuccess: false,
                updatePackingSuccess: false,
                createPackingSuccess: false,
                updateImagePackingSuccess: false,
            };
        case ADD_STOCK_PACKING_SUCCESS:
            return {
                ...state,
                addStockPackingRequesting: false,
                addStockPackingSuccess: true,
                packing: state.packing.map(packing => (
                    packing.id === action.packing.id ? action.packing : packing
                ))
            };
        case ADD_STOCK_PACKING_FAILURE:
            return {
                ...state,
                addStockPackingRequesting: false,
                addStockPackingFailure: action.error,
            };
        case UPDATE_IMAGE_PACKING_ATTEMPT:
            return {
                ...state,
                updateImagePackingRequesting: true,
                updateImagePackingSuccess: false,
                addStockPackingSuccess: false,
                updatePackingSuccess: false,
                createPackingSuccess: false,
            };
        case UPDATE_IMAGE_PACKING_SUCCESS:
            return {
                ...state,
                updateImagePackingRequesting: false,
                updateImagePackingSuccess: true,
                packing: state.packing.map(packing => (
                    packing.id === action.packing.id ? action.packing : packing
                ))
            };
        case UPDATE_IMAGE_PACKING_FAILURE:
            return {
                ...state,
                updateImagePackingRequesting: false,
                updateImagePackingError: action.error,
            };
        case ALL_GET_PACKING_ATTEMPT:
            return {
                ...state,
                allGetPackingRequesting: true,
            };
        case ALL_GET_PACKING_SUCCESS:
            return {
                ...state,
                allGetPackingRequesting: false,
                allGPackingSuccess: true,
                allPacking: action.allPacking,
            };
        case ALL_GET_PACKING_FAILURE:
            return {
                ...state,
                allGetPackingRequesting: false,
                allGetPackingError: action.error,
            };
        case GET_PACK:
            return {
                ...state,
                packing: state.packing.map(packing => (
                    packing.id === action.pack.id ? action.pack : packing
                ))
            };
        case CLEAN_PACKING:
            return {
                ...state,
                getPackingRequesting: false,
                getPackingSuccess: false,
                getPackingError: '',
                packing: [],
                linkPacking: {},
                metaPacking: {},
                pagePacking: 0,
                createPackingRequesting: false,
                createPackingSuccess: false,
                createPackingError: '',
                updatePackingRequesting: false,
                updatePackingSuccess: false,
                updatePackingError: '',
                addStockPackingRequesting: false,
                addStockPackingSuccess: false,
                addStockPackingFailure: '',
                updateImagePackingRequesting: false,
                updateImagePackingSuccess: false,
                updateImagePackingError: '',
                allGetPackingRequesting: false,
                allGPackingSuccess: false,
                allGetPackingError: '',
                allPacking: [],
            };
        default:
            return state;
    }
}

export default reducer;
