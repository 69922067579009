import React from "react";
import DataTable from "react-data-table-component";
import Switch from "react-switch";

export const TableSection = ({
                                 sections,
                                 setFocusSection,
                                 setModal,
                                 handleUpdateActive
}) => {

    const columns = [
        {
            name: 'Id',
            selector: row => row.id,
            center: true,
            width: '50px'
        },
        {
            name: 'Nombre',
            selector: row => row.name,
            center: true,
            width: '300px'
        },
        {
            name: 'Activo',
            selector: row => <Switch
                onChange={() => handleUpdateActive({active:!row.active, id: row.id})}
                checked={row.active}
                uncheckedIcon={false}
                checkedIcon={false}
            />,
            center: true,
            width: '150px'
        },
        {
            name: 'Acción',
            selector: row => <button type="button" className="btn button-table-category"
                                     onClick={() => {
                                         setFocusSection(row);
                                         setModal('Edit')
                                     }}>
                <i className="fa-solid fa-pen-to-square fa-lg icon-table" />
            </button>,
            center: true,
            width: '150px'
        },
    ];

    return (
        <div className="card mb-3">
            <DataTable
                title="Datatable Product"
                columns={columns}
                data={sections}
                defaultSortField="title"
                pagination={sections.length > 10}
                selectableRows={false}
                className="table myDataTable table-hover align-middle mb-0 d-row nowrap dataTable no-footer dtr-inline"
                highlightOnHover={true}
                noDataComponent={'No hay secciones en estos momentos'}
                paginationComponentOptions={{
                    rowsPerPageText: 'Filas por página',
                    rangeSeparatorText: 'de'
                }}
            />
        </div>
    );
}
