import {CLEAN_BUYER, GET_BUYERS_ATTEMPT, GET_BUYERS_FAILURE, GET_BUYERS_SUCCESS} from "./constants";

const initialState = {
    getBuyersRequesting: false,
    getBuyersSuccess: false,
    getBuyerError: '',
    buyers: [],
    linkBuyers: {},
    metaBuyers: {},
    pageBuyers: 0,
}

const reducer = (state = initialState, action) => {
    switch (action.type){
        case GET_BUYERS_ATTEMPT:
            return {
                ...state,
                getBuyersRequesting: true,
                pageBuyers: action.page,
            };
        case GET_BUYERS_SUCCESS:
            return {
                ...state,
                getBuyersRequesting: false,
                getBuyersSuccess: true,
                buyers: action.buyers.data,
                linkBuyers: action.buyers.links,
                metaBuyers: action.buyers.meta,
            };
        case GET_BUYERS_FAILURE:
            return {
                ...state,
                getBuyersRequesting: false,
                getBuyerError: action.error,
            };
        case CLEAN_BUYER:
            return {
                ...state,
                getBuyersRequesting: false,
                getBuyersSuccess: false,
                getBuyerError: '',
                buyers: [],
                linkBuyers: {},
                metaBuyers: {},
                pageBuyers: 0,
            };
        default:
            return state;
    }
}

export default reducer;
