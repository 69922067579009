import {all, call, put, takeEvery} from 'redux-saga/effects'
import {
    addPackingProductsFailure,
    addPackingProductsSuccess,
    addStockProductFailure,
    addStockProductSuccess,
    changeStateProductFailure,
    changeStateProductSuccess,
    createProductFailure,
    createProductReferencesFailure,
    createProductReferencesSuccess,
    createProductSuccess,
    getProductsFailure,
    getProductsSuccess, searchProductsFailure, searchProductsSuccess, updateImageFailure, updateImageSuccess,
    updateProductFailure,
    updateProductReferencesFailure,
    updateProductReferencesSuccess,
    updateProductSuccess
} from "./actions";
import {
    ADD_PACKING_PRODUCTS_ATTEMPT,
    ADD_STOCK_PRODUCT_ATTEMPT,
    CHANGE_STATE_PRODUCT_ATTEMPT,
    CREATE_PRODUCT_ATTEMPT,
    CREATE_PRODUCT_REFERENCES_ATTEMPT,
    GET_PRODUCTS_ATTEMPT,
    SEARCH_PRODUCTS_ATTEMPT,
    UPDATE_IMAGE_ATTEMPT,
    UPDATE_PRODUCT_ATTEMPT,
    UPDATE_PRODUCT_REFERENCES_ATTEMPT
} from "./constants";

const URL = `${process.env.REACT_APP_API_KEY}`;

const createProductAPI = (token, values, photo, references) => {

    let images = photo.map((photo, index) => {
        return {url: photo.path, name: photo.name, position: index + 1 }
    });

    let productReferences = references.map((reference) => {
        return {reference: reference.reference, referenceQuantity: reference.quantity}
    })

    let body =  references.length > 0 ? {
        name: values?.name ? values.name : '',
        sku: values?.sku ? values.sku : '',
        stock: values?.stock ? values.stock : '',
        sale_price: values?.sale_price ? values.sale_price : '',
        purchase_price: values?.purchase_price ? values.purchase_price : '',
        description: values?.description ? values.description : '',
        sub_category_id: values?.sub_category_id ? values.sub_category_id : '',
        provider_id: values?.provider_id ? values.provider_id : '',
        images: images,
        references: productReferences,
    } : {
        name: values?.name ? values.name : '',
        sku: values?.sku ? values.sku : '',
        stock: values?.stock ? values.stock : '',
        sale_price: values?.sale_price ? values.sale_price : '',
        purchase_price: values?.purchase_price ? values.purchase_price : '',
        description: values?.description ? values.description : '',
        sub_category_id: values?.sub_category_id ? values.sub_category_id : '',
        provider_id: values?.provider_id ? values.provider_id : '',
        images: images,
    }

    return fetch(`${URL}products/product`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
    })
        .then(response => response.json())
        .then(json => {
            if(json?.errors){
                throw json.errors;
            }
            if (json.code === 400){
                throw json.message;
            }
            if (json.code === 200){
                return json.product;
            }
        }).catch((error) => {
            throw error;
        })
};

function* createProductFlow(action) {
    try {
        const {token, values, photo, references} = action;
        const product = yield call(createProductAPI, token, values, photo, references);
        yield put(createProductSuccess(product));
    } catch (error) {
        yield put(createProductFailure(error));
    }
}

const getProductsAPI = (token, page) => {
    return fetch(`${URL}products/products?page=${page}&top=10`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    })
        .then(response => response.json())
        .then(json => {
            if (json.data.length === 0){
                throw 'No hay productos';
            }
            if (json.data.length > 0){
                return json;
            }
        }).catch((error) => {
            throw error;
        })
};

function* getProductsFlow(action) {
    try {
        const {token, page} = action;
        const products = yield call(getProductsAPI, token, page);
        yield put(getProductsSuccess(products));
    } catch (error) {
        yield put(getProductsFailure(error));
    }
}

const changeStateProductAPI = (token, productId) => {
    return fetch(`${URL}states/product_states/${productId}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    })
        .then(response => response.json())
        .then(json => {
            if (json.code === 200){
                return json.product;
            }
        }).catch((error) => {
            throw error;
        })
};

function* changeStateProductFlow(action) {
    try {
        const {token, productId} = action;
        const product = yield call(changeStateProductAPI, token, productId);
        yield put(changeStateProductSuccess(product));
    } catch (error) {
        yield put(changeStateProductFailure(error));
    }
}

const createProductReferencesAPI = (token, values, productId) => {

    return fetch(`${URL}products/product_reference/${productId}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(values),
    })
        .then(response => response.json())
        .then(json => {
            if(json?.errors){
                throw json.errors;
            }
            if (json.code === 400){
                throw json.message;
            }
            if (json.code === 200){
                return json.product;
            }
        }).catch((error) => {
            throw error;
        })
};

function* createProductReferencesFlow(action) {
    try {
        const {token, values, productId} = action;
        const product = yield call(createProductReferencesAPI, token, values, productId);
        yield put(createProductReferencesSuccess(product));
    } catch (error) {
        yield put(createProductReferencesFailure(error));
    }
}

const updateProductReferencesAPI = (token, values) => {

    return fetch(`${URL}products/product_reference/${values.id}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(values),
    })
        .then(response => response.json())
        .then(json => {
            if(json?.errors){
                throw json.errors;
            }
            if (json.code === 400){
                throw json;
            }
            if (json.code === 200){
                return json.product;
            }
        }).catch((error) => {
            throw error;
        })
};

function* updateProductReferencesFlow(action) {
    try {
        const {token, values} = action;
        const product = yield call(updateProductReferencesAPI, token, values);
        yield put(updateProductReferencesSuccess(product));
    } catch (error) {
        yield put(updateProductReferencesFailure(error));
    }
}

const addStockProductAPI = (token, values) => {

    return fetch(`${URL}products/add_stock`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(values),
    })
        .then(response => response.json())
        .then(json => {
            if(json?.errors){
                throw json.errors;
            }
            if (json.code === 400){
                throw json;
            }
            if (json.code === 200){
                return json.product;
            }
        }).catch((error) => {
            throw error;
        })
};

function* addStockProductFlow(action) {
    try {
        const {token, values} = action;
        const product = yield call(addStockProductAPI, token, values);
        yield put(addStockProductSuccess(product));
    } catch (error) {
        yield put(addStockProductFailure(error));
    }
}

const updateProductAPI = (token, values, focusProduct) => {

    let body = {
        '_method': 'PUT',
        name: values.name !== '' ? values.name : focusProduct.name,
        sku: values.sku !== '' ? values.sku : focusProduct.sku,
        stock: values.stock !== '' ? parseInt(values.stock) : focusProduct.stock,
        sale_price: values.sale_price !== '' ? values.sale_price : focusProduct.sale_price,
        purchase_price: values.purchase_price !== '' ? values.purchase_price : focusProduct.purchase_price,
        description: values.description !== '' ? values.description : focusProduct.description,
        sub_category_id: values.sub_category_id !== '' ? values.sub_category_id : focusProduct.sub_category.id,
        provider_id: values.provider_id !== '' ? values.provider_id : focusProduct.provider.id,
    }

    return fetch(`${URL}products/product/${focusProduct.id}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
    })
        .then(response => response.json())
        .then(json => {
            if(json?.errors){
                throw json.errors;
            }
            if (json.code === 400){
                throw json.message;
            }
            if (json.code === 200){
                return json.product;
            }
        }).catch((error) => {
            throw error;
        })
};

function* updateProductFlow(action) {
    try {
        const {token, values, focusProduct} = action;
        const product = yield call(updateProductAPI, token, values, focusProduct);
        yield put(updateProductSuccess(product));
    } catch (error) {
        yield put(updateProductFailure(error));
    }
}

const updateImageAPI = (token, values) => {

    return fetch(`${URL}images/product`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(values),
    })
        .then(response => response.json())
        .then(json => {
            if(json?.errors){
                throw json.errors;
            }
            if (json.code === 400){
                throw json.message;
            }
            if (json.code === 200){
                return json.product;
            }
        }).catch((error) => {
            throw error;
        })
};

function* updateImageFlow(action) {
    try {
        const {token, values} = action;
        const product = yield call(updateImageAPI, token, values);
        yield put(updateImageSuccess(product));
    } catch (error) {
        yield put(updateImageFailure(error));
    }
}

const addPackingProductsAPI = (token, values, productId) => {

    return fetch(`${URL}products/add_packing/${productId}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(values),
    })
        .then(response => response.json())
        .then(json => {
            if(json?.errors){
                throw json.errors;
            }
            if (json.code === 400){
                throw json.message;
            }
            if (json.code === 200){
                return json.product;
            }
        }).catch((error) => {
            throw error;
        })
};

function* addPackingProductsFlow(action) {
    try {
        const {token, values, productId} = action;
        const product = yield call(addPackingProductsAPI, token, values, productId);
        yield put(addPackingProductsSuccess(product));
    } catch (error) {
        yield put(addPackingProductsFailure(error));
    }
}

const searchProductsAPI = (token, search, page) => {
    return fetch(`${URL}products/search_product?page=${page}&top=10`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(search),
    })
        .then(response => response.json())
        .then(json => {
            if (json.data.length === 0){
                throw 'No hay productos';
            }
            if (json.data.length > 0){
                return json;
            }
        }).catch((error) => {
            throw error;
        })
};

function* searchProductsFlow(action) {
    try {
        const {token, search, page} = action;
        const products = yield call(searchProductsAPI, token, search, page);
        yield put(searchProductsSuccess(products));
    } catch (error) {
        yield put(searchProductsFailure(error));
    }
}

function* productWatcher(){
    yield all([
        takeEvery(CREATE_PRODUCT_ATTEMPT, createProductFlow),
        takeEvery(GET_PRODUCTS_ATTEMPT, getProductsFlow),
        takeEvery(CHANGE_STATE_PRODUCT_ATTEMPT, changeStateProductFlow),
        takeEvery(CREATE_PRODUCT_REFERENCES_ATTEMPT, createProductReferencesFlow),
        takeEvery(UPDATE_PRODUCT_REFERENCES_ATTEMPT, updateProductReferencesFlow),
        takeEvery(ADD_STOCK_PRODUCT_ATTEMPT, addStockProductFlow),
        takeEvery(UPDATE_PRODUCT_ATTEMPT, updateProductFlow),
        takeEvery(UPDATE_IMAGE_ATTEMPT, updateImageFlow),
        takeEvery(ADD_PACKING_PRODUCTS_ATTEMPT, addPackingProductsFlow),
        takeEvery(SEARCH_PRODUCTS_ATTEMPT, searchProductsFlow)
    ])
}

export default productWatcher;
