export const LOGIN_ATTEMPT = 'LOGIN_ATTEMPT';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const CLEAN_LOGIN = 'CLEAN_LOGIN';

export const GET_ME_ATTEMPT = 'GET_ME_ATTEMPT';
export const GET_ME_SUCCESS = 'GET_ME_SUCCESS';
export const GET_ME_FAILURE = 'GET_ME_FAILURE';

export const LOGOUT_ATTEMPT = 'LOGOUT_ATTEMPT';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const OPEN_MODAL = 'OPEN_MODAL';

export const SIDEBAR_MINI = 'SIDEBAR_MINI';
