import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {SendToken} from "../../components/ForgotPassword/SendToken";
import {VerifyToken} from "../../components/ForgotPassword/VerifyToken";
import {resetPassword, sendToken, verifyToken} from "../../redux/forgotPassword/actions";
import {ResetPassword} from "../../components/ForgotPassword/ResetPassword";
import {useNavigate} from "react-router-dom";
import {cleanLogin} from "../../redux/auth/actions";
import {AuthLeft} from "../../components/Auth/AuthLeft";
import {userAuthenticated} from "../../constants/UserStorage";

export const ForgotPassword = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { viewActive, requestingSendToken, messageSendEmail,
        errorSendEmail, requestingVerifyToken, errorVerifyToken,
        messageVerifyToken, requestingResetPassword, errorResetPassword,
        successResetPassword,
    } = useSelector(state => state.forgotPassword);
    const {successMe, errorMe, requestingMe} = useSelector(state => state.auth);

    const [email, setEmail] = useState('');
    const [token, setToken] = useState('');

    const handleSendToken = (data) => {
        let values = {
            'email': data.email,
        };
        setEmail(data.email);
        dispatch(sendToken(values));
    };

    const handleVerifyToken = (data) => {
        let values = {
            'email': email,
            'token': data.token,
        };
        setToken(data.token);
        dispatch(verifyToken(values));
    };

    const handleResetPassword = (data) => {
        let values = {
            'email': email,
            'token': token,
            'password': data.password,
        };
        dispatch(resetPassword(values));
    };

    useEffect(() => {
        if(successResetPassword){
            navigate('/');
            dispatch(cleanLogin());
        }
    }, [successResetPassword]);

    useEffect(() => {
        userAuthenticated(dispatch, navigate, errorMe, successMe, '/orders', '/reset_password');
    }, [errorMe, successMe]);

    return (
        <div className='main p-2 py-3 p-xl-5 '>
            <div className='body d-flex p-0 p-xl-5'>
                <div className='container-xxl'>
                    <div className='row g-0'>
                        <AuthLeft />
                        <div className="w-100 p-3 p-md-2 card border-0 shadow-sm" style={{ maxWidth: '32rem' }}>
                            {viewActive === 'send_token' && (
                                <SendToken
                                    handle={handleSendToken}
                                    error={errorSendEmail}
                                    requesting={requestingSendToken}
                                    requestingMe={requestingMe}
                                />
                            )}
                            {viewActive === 'validate_token' && (
                                <VerifyToken
                                    handle={handleVerifyToken}
                                    error={errorVerifyToken}
                                    requesting={requestingVerifyToken}
                                    message={messageSendEmail}
                                />
                            )}
                            {viewActive === 'reset_password' && (
                                <ResetPassword
                                    handle={handleResetPassword}
                                    error={errorResetPassword}
                                    requesting={requestingResetPassword}
                                    message={messageVerifyToken}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
