import React, {useEffect, useState} from 'react';
import {Sidebar} from "../../components/Sidebar/Sidebar";
import {Header} from "../../components/Header/Header";
import {useDispatch, useSelector} from "react-redux";
import {getPacking} from "../../redux/packing/actions";
import {TablePacking} from "../../components/Packing/Table";
import {AddPacking} from "../../components/Packing/AddPacking";
import {ListImages} from "../../components/Image/ListImages";
import {EditPacking} from "../../components/Packing/EditPacking";
import {AddStockPacking} from "../../components/Packing/AddStock";
import {EditImagePacking} from "../../components/Packing/EditImage";
import {AddDamagedPacking} from "../../components/Packing/AddDamagedPacking";

export const Packing = () => {

    const dispatch = useDispatch();

    const {user, successMe, sidebarMini, token} = useSelector(state => state.auth);
    const {getPackingSuccess, packing, metaPacking, pagePacking,
        createPackingSuccess, updatePackingSuccess,
        addStockPackingSuccess, updateImagePackingSuccess} = useSelector(state => state.packing);
    const {createDamagedPackingSuccess} = useSelector(state => state.damagedPacking);

    const [modal, setModal] = useState('');
    const [photos, setPhotos] = useState([]);
    const [focusPacking, setFocusPacking] = useState({});

    const handleChangePage = (page) => {
       if(page !== pagePacking && page !== 0){
            dispatch(getPacking(token, page));
        }
    }

    const closeModal = () => {
        setModal('')
    };

    const cleanPhotos = () => {
        setPhotos([]);
    };

    const deleteImage = (photo) => {
        const newArrayPhotos = photos.filter((item) => item.path !== photo.path);
        setPhotos(newArrayPhotos);
    };

    useEffect(() => {
        if(!getPackingSuccess && successMe)
            dispatch(getPacking(token, 1));
    }, []);

    useEffect(() => {
        if(createPackingSuccess){
            closeModal();
            cleanPhotos();
        }
        if(updatePackingSuccess || addStockPackingSuccess || updateImagePackingSuccess || createDamagedPackingSuccess){
            closeModal();
        }
    }, [createPackingSuccess, updatePackingSuccess, addStockPackingSuccess,
        updateImagePackingSuccess, createDamagedPackingSuccess])

    return (
        <div className={'d-flex'}>
            <Sidebar isSidebarMini={sidebarMini}
                     routeCurrency={'/packing'} />
            <div className='main px-lg-12 px-md-4' >
                <Header user={user}/>
                <button
                    type="button"
                    className="btn btn-primary align-self-end"
                    onClick={() => setModal('addPacking')}
                >
                    Agregar empaque
                </button>
                <div className={`${sidebarMini ? 'table-widthSideMini': 'table-widthSide'} body d-flex py-3`}>
                    <TablePacking
                        packing={packing}
                        metaPacking={metaPacking}
                        pageCurrency={pagePacking}
                        handleChangePage={handleChangePage}
                        setFocusPacking={setFocusPacking}
                        openModal={setModal}
                    />
                </div>
                <AddPacking
                    visible={modal === 'addPacking'}
                    close={() => {
                        closeModal();
                        cleanPhotos();
                    }}
                    photos={photos}
                    deleteImage={deleteImage}
                    setPhotos={setPhotos}
                />
                {modal === 'viewPhotos' && (
                    <ListImages
                        visible={modal === 'viewPhotos'}
                        close={closeModal}
                        images={focusPacking.images}
                        name={focusPacking.name}
                    />
                )}
                {modal === 'editPacking' &&
                    <EditPacking
                        visible={modal === 'editPacking'}
                        close={closeModal}
                        focus={focusPacking}
                    />
                }
                {modal === 'addStock' && (
                    <AddStockPacking
                        visible={modal === 'addStock'}
                        close={closeModal}
                        focus={focusPacking}
                    />
                )}
                {modal === 'editImage' && (
                    <EditImagePacking
                        close={closeModal}
                        visible={modal === 'editImage'}
                        focus={focusPacking}
                        photos={photos}
                        setPhotos={setPhotos}
                    />
                )}
                {modal === 'addDamaged' && (
                    <AddDamagedPacking
                        close={() => {
                            closeModal();
                        }}
                        visible={modal === 'addDamaged'}
                        focus={focusPacking}
                    />
                )}
            </div>
        </div>
    )
}
