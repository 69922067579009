import {
    CLEAN_DAMAGED_PRODUCT,
    CREATE_DAMAGED_PRODUCT_ATTEMPT,
    CREATE_DAMAGED_PRODUCT_FAILURE,
    CREATE_DAMAGED_PRODUCT_SUCCESS,
    GET_DAMAGED_PRODUCT_ATTEMPT,
    GET_DAMAGED_PRODUCT_FAILURE,
    GET_DAMAGED_PRODUCT_SUCCESS,
    UPDATE_DAMAGED_PRODUCT_ATTEMPT,
    UPDATE_DAMAGED_PRODUCT_FAILURE,
    UPDATE_DAMAGED_PRODUCT_SUCCESS
} from "./constants";

const initialState = {
    createDamagedProductRequesting: false,
    createDamagedProductSuccess: false,
    createDamagedProductError: '',
    getDamagedProductRequesting: false,
    getDamagedProductSuccess: false,
    getDamagedProductError: '',
    damagedProducts: [],
    linkDamagedProducts: {},
    metaDamagedProducts: {},
    pageDamagedProducts: 0,
    updateDamagedProductRequesting: false,
    updateDamagedProductSuccess: false,
    updateDamagedProductError: ''
}

const reducer = (state = initialState, action) => {
    switch (action.type){
        case CREATE_DAMAGED_PRODUCT_ATTEMPT:
            return {
                ...state,
                createDamagedProductRequesting: true,
                createDamagedProductSuccess: false,
            };
        case CREATE_DAMAGED_PRODUCT_SUCCESS:
            return {
                ...state,
                createDamagedProductRequesting: false,
                createDamagedProductSuccess: true,
                damagedProducts: state.damagedProducts.length > 0 ?
                    state.damagedProducts.map(damagedProduct => (
                        damagedProduct.id === action.damagedProduct.damagedProduct.id ? action.damagedProduct.damagedProduct : damagedProduct
                    ))
                    : [action.damagedProduct.damagedProduct, ...state.damagedProducts],
            };
        case CREATE_DAMAGED_PRODUCT_FAILURE:
            return {
                ...state,
                createDamagedProductRequesting: false,
                createDamagedProductError: action.error,
            };
        case GET_DAMAGED_PRODUCT_ATTEMPT:
            return {
                ...state,
                getDamagedProductRequesting: true,
                pageDamagedProducts: action.page
            };
        case GET_DAMAGED_PRODUCT_SUCCESS:
            return {
                ...state,
                getDamagedProductRequesting: false,
                getDamagedProductSuccess: true,
                damagedProducts: action.damagedProducts.data,
                linkDamagedProducts: action.damagedProducts.links,
                metaDamagedProducts: action.damagedProducts.meta,
            };
        case GET_DAMAGED_PRODUCT_FAILURE:
            return {
                ...state,
                getDamagedProductRequesting: false,
                getDamagedProductError: action.error,
            };
        case UPDATE_DAMAGED_PRODUCT_ATTEMPT:
            return {
                ...state,
                updateDamagedProductRequesting: true,
                updateDamagedProductSuccess: false,
            };
        case UPDATE_DAMAGED_PRODUCT_SUCCESS:
            return {
                ...state,
                updateDamagedProductRequesting: false,
                updateDamagedProductSuccess: true,
                damagedProducts:  state.damagedProducts.map(damagedProduct => (
                    damagedProduct.id === action.damagedProduct.id ? action.damagedProduct : damagedProduct
                ))
            };
        case UPDATE_DAMAGED_PRODUCT_FAILURE:
            return {
                ...state,
                updateDamagedProductRequesting: false,
                updateDamagedProductError: action.error,
            };
        case CLEAN_DAMAGED_PRODUCT:
            return {
                ...state,
                createDamagedProductRequesting: false,
                createDamagedProductSuccess: false,
                createDamagedProductError: '',
                getDamagedProductRequesting: false,
                getDamagedProductSuccess: false,
                getDamagedProductError: '',
                damagedProducts: [],
                linkDamagedProducts: {},
                metaDamagedProducts: {},
                pageDamagedProducts: 0,
                updateDamagedProductRequesting: false,
                updateDamagedProductSuccess: false,
                updateDamagedProductError: ''
            };
        default:
            return state;
    }
}

export default reducer;
