import React, {useEffect, useState} from 'react';
import {Sidebar} from "../../components/Sidebar/Sidebar";
import {useDispatch, useSelector} from "react-redux";
import {Header} from "../../components/Header/Header";
import {getStatesDamagedProduct} from "../../redux/state/actions";
import {getDamagedPacking} from "../../redux/damagedPacking/actions";
import {TableDamagedPacking} from "../../components/DamagedPacking/Table";
import {ModalHistoriesPacking} from "../../components/DamagedPacking/ModalHistoriesPacking";
import {AddMoreDamagedPacking} from "../../components/DamagedPacking/AddMoreDamagedPacking";

export const DamagedPacking = () => {

    const dispatch = useDispatch();

    const {user, token, successMe, sidebarMini} = useSelector(state => state.auth);
    const {getDamagedPackingSuccess, damagedPacking, metaDamagedPacking,
        pageDamagedPacking, updateDamagedPackingSuccess} = useSelector(state => state.damagedPacking);
    const {getStateDamagedProductSuccess} = useSelector(state => state.state);

    const [modal, setModal] = useState('');
    const [focus, setFocus] = useState({});

    const closeModal = () => {
        setModal('')
    };

    const handleChangePage = (page) => {
        if(page !== pageDamagedPacking && page !== 0)
            dispatch(getDamagedPacking(token, page));
    };

    useEffect(() => {
        if(!getDamagedPackingSuccess && successMe)
            dispatch(getDamagedPacking(token, 1));
        if(!getStateDamagedProductSuccess && successMe)
            dispatch(getStatesDamagedProduct(token))
    }, [successMe])

    useEffect(() => {
        if(updateDamagedPackingSuccess){
            closeModal();
        }
    }, [updateDamagedPackingSuccess]);

    return (
        <div className={'d-flex'}>
            <Sidebar
                isSidebarMini={sidebarMini}
                routeCurrency={'/damagedPacking'} />
            <div className='main px-lg-12 px-md-4' >
                <Header user={user}/>
                <div className={`${sidebarMini ? 'table-widthSideMini': 'table-widthSide'} body d-flex py-3`}>
                    <TableDamagedPacking
                        damagedPacking={damagedPacking}
                        pageCurrency={pageDamagedPacking}
                        metaDamagedPacking={metaDamagedPacking}
                        setModal={setModal}
                        setFocus={setFocus}
                        handleChangePage={handleChangePage}
                    />
                </div>
            </div>
            {modal === 'viewHistories' && (
                <ModalHistoriesPacking
                    visible={modal === 'viewHistories'}
                    close={closeModal}
                    histories={focus.histories}
                />
            )}
            {modal === 'addMoreDamaged' && (
                <AddMoreDamagedPacking
                    close={() => {
                        closeModal();
                    }}
                    visible={modal === 'addMoreDamaged'}
                    focus={focus}
                />
            )}
        </div>
    )
};
