import React, {useEffect, useState} from 'react';
import {Sidebar} from "../../components/Sidebar/Sidebar";
import {useDispatch, useSelector} from "react-redux";
import {Header} from "../../components/Header/Header";
import {TableProducts} from "../../components/Product/Table";
import {AddProduct} from "../../components/Product/AddProduct";
import {getSubcategory} from "../../redux/subcategory/actions";
import {useReferenceProduct} from "../../hooks/Product/ProductReference";
import {
    changeStateProduct,
    getProducts,
    cleanErrorProductReference, searchProducts, cleanSearch,
} from "../../redux/product/actions";
import {ListImages} from "../../components/Image/ListImages";
import {ListReferences} from "../../components/Product/ListReferences";
import {AddReferences} from "../../components/Product/AddReferences";
import {AddStock} from "../../components/Product/AddStock";
import {useProductStockReference} from "../../hooks/Product/ProductStockReference";
import {EditProduct} from "../../components/Product/EditProduct";
import {EditImage} from "../../components/Product/EditImage";
import {AddPackingToProduct} from "../../components/Product/AddPacking";
import {allGetPacking} from "../../redux/packing/actions";
import {getProviders} from "../../redux/provider/actions";
import {getCategories} from "../../redux/category/actions";
import {AddDamagedProduct} from "../../components/Product/AddDamagedProduct";

export const Product = () => {

    const dispatch = useDispatch();

    const {user, token, successMe, sidebarMini} = useSelector(state => state.auth);
    const {successSubcategory} = useSelector(state => state.subcategory);
    const {successProducts,
        successCreate, products, metaProducts, pageProducts, createReferenceSuccess,
        updateReferenceSuccess, addStockProductSuccess, updateProductSuccess,
        updateImageProductSuccess, addPackingProductSuccess, pageSearchProducts,
        searchProductsSuccess, productsSearch, metaSearchProducts, searchProductsRequesting,
        requestingProducts
    } = useSelector(state => state.product);
    const {allGPackingSuccess, allPacking} = useSelector(state => state.packing);
    const {getProvidersSuccess} = useSelector(state => state.provider);
    const {getCategoriesSuccess} = useSelector(state => state.category);
    const {createDamagedProductSuccess} = useSelector(state => state.damagedProduct);

    const [modal, setModal] = useState('');
    const [photos, setPhotos] = useState([]);
    const [focusProduct, setFocusProduct] = useState({});
    const [editReferences, setEditReferences] = useState(0);
    const [searchText, setSearchText] = useState('');

    const {references, handleChangeFormReference, isAddedReference,
        handleAddReference, cleanReference} = useReferenceProduct();

    const {handleAddStock, cleanAddStock, addStock} = useProductStockReference();

    const handleChangePage = (page) => {
        if(searchProductsSuccess){
            let search = {
                search: searchText
            }
            if(page !== pageSearchProducts && page !== 0)
                dispatch(searchProducts(token, search, page));
        }else{
            if(page !== pageProducts && page !== 0)
                dispatch(getProducts(token, page));
        }
    };

    const handleChangeState = (productId) => {
        dispatch(changeStateProduct(token, productId))
    };

    const openModal = (value) => {
        setModal(value)
    }

    const closeModal = () => {
        setModal('')
    };

    const deleteImage = (photo) => {
        const newArrayPhotos = photos.filter((item) => item.path !== photo.path);
        setPhotos(newArrayPhotos);
    };

    const cleanPhotos = () => {
        setPhotos([]);
    };

    const handleSearchProducts = () => {
        let search = {
            search: searchText
        }
        dispatch(searchProducts(token, search,1))
    };

    const handleCleanSearch = () => {
        dispatch(cleanSearch())
    }

    useEffect(() => {
        if(!successSubcategory && successMe)
            dispatch(getSubcategory(token))
        if(!successProducts && successMe)
            dispatch(getProducts(token, 1));
        if(!allGPackingSuccess && successMe)
            dispatch(allGetPacking(token))
        if(!getProvidersSuccess && successMe)
            dispatch(getProviders(token))
        if(!getCategoriesSuccess && successMe)
            dispatch(getCategories(token))
    }, [successMe]);

    useEffect(() => {
        if(successCreate){
            closeModal()
            cleanPhotos();
            cleanReference();
        }
        if(createReferenceSuccess){
            closeModal();
            cleanReference();
        }
        if(updateReferenceSuccess){
            closeModal();
            setEditReferences(0);
        }
        if(addStockProductSuccess){
            closeModal();
            cleanAddStock();
        }
        if(updateProductSuccess || addPackingProductSuccess || createDamagedProductSuccess){
            closeModal();
        }
        if(updateImageProductSuccess){
            closeModal();
            cleanPhotos();
        }
    }, [successCreate, createReferenceSuccess, updateReferenceSuccess,
        addStockProductSuccess, updateProductSuccess, updateImageProductSuccess,
        addPackingProductSuccess, createDamagedProductSuccess]);

    return (
        <div className={'d-flex'}>
            <Sidebar
                isSidebarMini={sidebarMini}
                routeCurrency={'/products'} />
            <div className='main px-lg-12 px-md-4' >
                <Header
                    user={user}
                    placeHolderSearch={'Buscar producto'}
                    handleSearch={handleSearchProducts}
                    handleCleanSearch={handleCleanSearch}
                    setSearchText={setSearchText}
                    searchText={searchText}
                />
                <button
                    type="button"
                    className="btn btn-primary align-self-end"
                    onClick={() => openModal('addProduct')}
                >
                    Agregar producto
                </button>
                    <div className={`${sidebarMini ? 'table-widthSideMini': 'table-widthSide'} body d-flex py-3`}>
                        {(!searchProductsRequesting && !requestingProducts) && (
                        <TableProducts
                            products={searchProductsSuccess ? productsSearch : products}
                            metaProducts={searchProductsSuccess ? metaSearchProducts : metaProducts}
                            pageCurrency={searchProductsSuccess ? pageSearchProducts : pageProducts}
                            handleChangePage={handleChangePage}
                            openModal={openModal}
                            setFocusProduct={setFocusProduct}
                            changeState={handleChangeState}
                            handleAddReference={handleAddReference}
                        />
                        )}
                        {(searchProductsRequesting || requestingProducts) && <p className={'text-center h6'}>Cargando ...</p>}
                    </div>

                <AddProduct
                    visible={modal === 'addProduct'}
                    close={() => {
                        closeModal()
                        cleanReference();
                        cleanPhotos();
                    }}
                    photos={photos}
                    handleAddReference={handleAddReference}
                    isAddedReference={isAddedReference}
                    references={references}
                    handle={handleChangeFormReference}
                    deleteImage={deleteImage}
                    setPhotos={setPhotos}
                />
                {modal === 'viewPhotos' && (
                    <ListImages
                        visible={modal === 'viewPhotos'}
                        close={closeModal}
                        images={focusProduct.images}
                        name={focusProduct.name}
                    />
                )}
                {modal === 'viewReferences' && (
                    <ListReferences
                        visible={modal === 'viewReferences'}
                        close={() => {
                            closeModal();
                            dispatch(cleanErrorProductReference());
                        }}
                        references={focusProduct.references}
                        edit={editReferences}
                        setEdit={setEditReferences}
                    />
                )}
                {modal === 'addReferences' && (
                    <AddReferences
                        visible={modal === 'addReferences'}
                        close={() => {
                            closeModal();
                            cleanReference();
                        }}
                        isAddedReference={isAddedReference}
                        references={references}
                        handleAddReference={handleAddReference}
                        handle={handleChangeFormReference}
                        focus={focusProduct}
                    />
                )}
                {modal === 'addStock' && (
                    <AddStock
                        visible={modal === 'addStock'}
                        close={closeModal}
                        focus={focusProduct}
                        addStock={addStock}
                        handleAddStock={handleAddStock}
                    />
                )}
                {modal === 'editProduct' &&
                    <EditProduct
                        visible={modal === 'editProduct'}
                        close={closeModal}
                        focus={focusProduct}
                    />
                }
                {modal === 'editImage' && (
                    <EditImage
                        close={() => {
                            closeModal()
                            cleanPhotos();
                        }}
                        visible={modal === 'editImage'}
                        focus={focusProduct}
                        photos={photos}
                        setPhotos={setPhotos}
                    />
                )}
                {modal === 'addPacking' && (
                    <AddPackingToProduct
                        close={() => {
                            closeModal();
                        }}
                        visible={modal === 'addPacking'}
                        focus={focusProduct}
                        packing={allPacking}
                    />
                )}
                {modal === 'addDamaged' && (
                    <AddDamagedProduct
                        close={() => {
                            closeModal();
                        }}
                        visible={modal === 'addDamaged'}
                        focus={focusProduct}
                    />
                )}
            </div>
        </div>
    )
};
