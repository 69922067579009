export const CREATE_PRODUCT_ATTEMPT = 'CREATE_PRODUCT_ATTEMPT';
export const CREATE_PRODUCT_SUCCESS = 'CREATE_PRODUCT_SUCCESS';
export const CREATE_PRODUCT_FAILURE = 'CREATE_PRODUCT_FAILURE';

export const GET_PRODUCTS_ATTEMPT = 'GET_PRODUCTS_ATTEMPT';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_FAILURE = 'GET_PRODUCTS_FAILURE';

export const CHANGE_STATE_PRODUCT_ATTEMPT = 'CHANGE_STATE_PRODUCT_ATTEMPT';
export const CHANGE_STATE_PRODUCT_SUCCESS = 'CHANGE_STATE_PRODUCT_SUCCESS';
export const CHANGE_STATE_PRODUCT_FAILURE = 'CHANGE_STATE_PRODUCT_FAILURE';

export const CREATE_PRODUCT_REFERENCES_ATTEMPT = 'CREATE_PRODUCT_REFERENCES_ATTEMPT';
export const CREATE_PRODUCT_REFERENCES_SUCCESS = 'CREATE_PRODUCT_REFERENCES_SUCCESS';
export const CREATE_PRODUCT_REFERENCES_FAILURE = 'CREATE_PRODUCT_REFERENCES_FAILURE';

export const UPDATE_PRODUCT_REFERENCES_ATTEMPT = 'UPDATE_PRODUCT_REFERENCES_ATTEMPT';
export const UPDATE_PRODUCT_REFERENCES_SUCCESS = 'UPDATE_PRODUCT_REFERENCES_SUCCESS';
export const UPDATE_PRODUCT_REFERENCES_FAILURE = 'UPDATE_PRODUCT_REFERENCES_FAILURE';

export const ADD_STOCK_PRODUCT_ATTEMPT = 'ADD_STOCK_PRODUCT_ATTEMPT';
export const ADD_STOCK_PRODUCT_SUCCESS = 'ADD_STOCK_PRODUCT_SUCCESS';
export const ADD_STOCK_PRODUCT_FAILURE = 'ADD_STOCK_PRODUCT_FAILURE';

export const UPDATE_PRODUCT_ATTEMPT = 'UPDATE_PRODUCT_ATTEMPT';
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS';
export const UPDATE_PRODUCT_FAILURE = 'UPDATE_PRODUCT_FAILURE';

export const UPDATE_IMAGE_ATTEMPT = 'UPDATE_IMAGE_ATTEMPT';
export const UPDATE_IMAGE_SUCCESS = 'UPDATE_IMAGE_SUCCESS';
export const UPDATE_IMAGE_FAILURE = 'UPDATE_IMAGE_FAILURE';

export const ADD_PACKING_PRODUCTS_ATTEMPT = 'ADD_PACKING_PRODUCTS_ATTEMPT';
export const ADD_PACKING_PRODUCTS_SUCCESS = 'ADD_PACKING_PRODUCTS_SUCCESS';
export const ADD_PACKING_PRODUCTS_FAILURE = 'ADD_PACKING_PRODUCTS_FAILURE';

export const CLEAN_ERROR_PRODUCT_REFERENCE = 'CLEAN_ERROR_PRODUCT_REFERENCE';

export const SEARCH_PRODUCTS_ATTEMPT = 'SEARCH_PRODUCTS_ATTEMPT';
export const SEARCH_PRODUCTS_SUCCESS = 'SEARCH_PRODUCTS_SUCCESS';
export const SEARCH_PRODUCTS_FAILURE = 'SEARCH_PRODUCTS_FAILURE';

export const CLEAN_SEARCH = 'CLEAN_SEARCH';

export const GET_PRODUCT = 'GET_PRODUCT';

export const CLEAN_PRODUCT = 'CLEAN_PRODUCT';

