import {all, call, put, takeEvery} from 'redux-saga/effects'
import {
    cleanLogin,
    getMe,
    getMeFailure,
    getMeSuccess,
    loginFailure,
    loginSuccess,
    logoutFailure,
    logoutSuccess
} from "./actions";
import {GET_ME_ATTEMPT, LOGIN_ATTEMPT, LOGOUT_ATTEMPT} from "./constants";

const URL = `${process.env.REACT_APP_API_KEY}`;

const loginAPI = (values) => {

    return fetch(`${URL}auth/login_backoffice`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        mode: 'cors',
        body: JSON.stringify(values)
    })
        .then(response => response.json())
        .then(json => {
           if (json?.errors){
                throw json.errors;
            }
           if (json.code === 202){
                throw json;
            }
            if (json.code === 400){
                throw json;
            }
            if (json?.access_token && json.access_token){
                return json.access_token;
            }
        }).catch((error) => {
            throw error;
        })
};

function* loginFlow(action) {
    try {
        const {values} = action;
        const token = yield call(loginAPI, values);
        yield put(getMe(token));
        yield put(loginSuccess());
        yield put(cleanLogin());
        localStorage.setItem('@thivisha', token);
    } catch (error) {
        yield put(loginFailure(error))
    }
}

const getMeAPI = (token) => {

    return fetch(`${URL}auth/auth_me`, {
        method: 'GET',
        headers: {
            'Authorization' : `Bearer ${token}`
        },
        mode: 'cors',
    })
        .then(response => response.json())
        .then(json => {
            if (json.code === 400){
                throw json;
            }
            if (json.code === 200){
                return json.user;
            }
        }).catch((error) => {
            throw error;
        })
};

function* meFlow(action) {
    try {
        const {token} = action;
        const user = yield call(getMeAPI, token);
        yield put(getMeSuccess(user));
    } catch (error) {
        yield put(getMeFailure(error))
    }
}

const logoutAPI = (token, values) => {

    return fetch(`${URL}auth/logout`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization' : `Bearer ${token}`
        },
        body: JSON.stringify(values)
    })
        .then(response => response.json())
        .then(json => {
            if (json.code === 200){
                return json;
            }
        }).catch((error) => {
            throw error;
        })
};

function* logoutFlow(action) {
    try {
        const {token, values} = action;
        yield call(logoutAPI, token, values);
        yield put(logoutSuccess());
    } catch (error) {
        yield put(logoutFailure(error))
    }
}


function* loginWatcher() {
    yield all([
        takeEvery(LOGIN_ATTEMPT, loginFlow),
        takeEvery(GET_ME_ATTEMPT, meFlow),
        takeEvery(LOGOUT_ATTEMPT, logoutFlow),
    ])
}

export default loginWatcher;
