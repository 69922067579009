import {
    CLEAN_DAMAGED_PACKING,
    CREATE_DAMAGED_PACKING_ATTEMPT,
    CREATE_DAMAGED_PACKING_FAILURE,
    CREATE_DAMAGED_PACKING_SUCCESS,
    GET_DAMAGED_PACKING_ATTEMPT,
    GET_DAMAGED_PACKING_FAILURE,
    GET_DAMAGED_PACKING_SUCCESS,
    UPDATE_DAMAGED_PACKING_ATTEMPT,
    UPDATE_DAMAGED_PACKING_FAILURE,
    UPDATE_DAMAGED_PACKING_SUCCESS
} from "./constants";

const initialState = {
    createDamagedPackingRequesting: false,
    createDamagedPackingSuccess: false,
    createDamagedPackingError: '',
    getDamagedPackingRequesting: false,
    getDamagedPackingSuccess: false,
    getDamagedPackingError: '',
    damagedPacking: [],
    linkDamagedPacking: {},
    metaDamagedPacking: {},
    pageDamagedPacking: 0,
    updateDamagedPackingRequesting: false,
    updateDamagedPackingSuccess: false,
    updateDamagedPackingError: ''
}

const reducer = (state = initialState, action) => {
    switch (action.type){
        case CREATE_DAMAGED_PACKING_ATTEMPT:
            return {
                ...state,
                createDamagedPackingRequesting: true,
                createDamagedPackingSuccess: false,
            }
        case CREATE_DAMAGED_PACKING_SUCCESS:
            return {
                ...state,
                createDamagedPackingRequesting: false,
                createDamagedPackingSuccess: true,
                damagedPacking: state.damagedPacking.length > 0 ?
                    state.damagedPacking.map(damagedPacking => (
                        damagedPacking.id === action.damagedPacking.damagedPacking.id ? action.damagedProduct.damagedPacking : damagedPacking
                    ))
                    : [action.damagedPacking.damagedPacking, ...state.damagedPacking],
            }
        case CREATE_DAMAGED_PACKING_FAILURE:
            return {
                ...state,
                createDamagedPackingRequesting: false,
                createDamagedPackingError: action.error,
            };
        case GET_DAMAGED_PACKING_ATTEMPT:
            return {
                ...state,
                getDamagedPackingRequesting: true,
                pageDamagedPacking: action.page
            };
        case GET_DAMAGED_PACKING_SUCCESS:
            return {
                ...state,
                getDamagedPackingRequesting: false,
                getDamagedPackingSuccess: true,
                damagedPacking: action.damagedPacking.data,
                linkDamagedPacking: action.damagedPacking.links,
                metaDamagedPacking: action.damagedPacking.meta,
            };
        case GET_DAMAGED_PACKING_FAILURE:
            return {
                ...state,
                getDamagedPackingRequesting: false,
                getDamagedPackingError: action.error,
            };
        case UPDATE_DAMAGED_PACKING_ATTEMPT:
            return {
                ...state,
                updateDamagedPackingRequesting: true,
                updateDamagedPackingSuccess: false,
            };
        case UPDATE_DAMAGED_PACKING_SUCCESS:
            return {
                ...state,
                updateDamagedPackingRequesting: false,
                updateDamagedPackingSuccess: true,
                damagedPacking:  state.damagedPacking.map(damagedPacking => (
                    damagedPacking.id === action.damagedPacking.id ? action.damagedPacking : damagedPacking
                ))
            };
        case UPDATE_DAMAGED_PACKING_FAILURE:
            return {
                ...state,
                updateDamagedPackingRequesting: false,
                updateDamagedPackingError: action.error,
            };
        case CLEAN_DAMAGED_PACKING:
            return {
                ...state,
                createDamagedPackingRequesting: false,
                createDamagedPackingSuccess: false,
                createDamagedPackingError: '',
                getDamagedPackingRequesting: false,
                getDamagedPackingSuccess: false,
                getDamagedPackingError: '',
                damagedPacking: [],
                linkDamagedPacking: {},
                metaDamagedPacking: {},
                pageDamagedPacking: 0,
            };
        default:
            return state;
    }
}

export default reducer;
