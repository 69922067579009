import React from "react";
import DataTable from "react-data-table-component";
import {PaginateComponent} from "../Paginate/Paginate";

export const TableDamagedPacking = ({
                                        damagedPacking,
                                        pageCurrency,
                                        metaDamagedPacking,
                                        setModal,
                                        setFocus,
                                        handleChangePage,

}) => {

    const columns = [
        {
            name: 'Nombre del empaque',
            selector: row => row.packing_name,
            center: true,
            width: '200px'
        },
        {
            name: 'Imagen del empaque',
            cell:row=><><img className="avatar rounded lg border"
                             src={row.packing_image}
                             alt="" /></>,
            center: true,
            width: '200px'
        },
        {
            name: 'Cantidad',
            selector: row => row.quantity,
            center: true
        },
        {
            name: 'Razón actual',
            selector: row => row.histories[row.histories.length - 1].reason,
            center: true,
            width: '250px'
        },
        {
            name: 'Historial',
            selector: row => <>{row.histories.length > 0 ? <button type="button"  onClick={() => {
               setModal('viewHistories');
               setFocus(row);
            }} className="btn button-table-account">
                <i className="fa-solid fa-folder-open fa-lg icon-table"/>
            </button> : <div>Sin historial</div>}</>,
            center: true,
            width: '150px'
        },
        {
            name: 'Estado',
            selector: row => <div className={'button-state'} style={{background: row.state.color}}>
                <div style={{color: 'white'}}>{row.state.name}</div>
            </div>,
            width: '200px',
            center: true
        },
        {
            name: 'Acción',
            selector: row => <>{row.state.id === 16 ? <button type="button"  onClick={() => {
                 setModal('addMoreDamaged');
                 setFocus(row);
            }} className="btn button-table-account">
                <i className="fa-solid fa-file-circle-plus fa-lg icon-table"/>
            </button> : null}</>,
            center: true,
            width: '120px'
        },
    ];

    return (
        <div className="card mb-3">
            <DataTable
                title="Datatable Packing"
                columns={columns}
                data={damagedPacking}
                defaultSortField="title"
                pagination={false}
                selectableRows={false}
                className="table myDataTable table-hover align-middle mb-0 d-row nowrap dataTable no-footer dtr-inline"
                highlightOnHover={true}
                noDataComponent={'No hay empaques averiados en estos momentos'}
            />
            {metaDamagedPacking.total > 9 && (
                <PaginateComponent
                    pageCurrency={pageCurrency}
                    handleChangePage={handleChangePage}
                    meta={metaDamagedPacking}
                />
            )}
        </div>
    );
}
