export const CREATE_DAMAGED_PRODUCT_ATTEMPT = 'CREATE_DAMAGED_PRODUCT_ATTEMPT';
export const CREATE_DAMAGED_PRODUCT_SUCCESS = 'CREATE_DAMAGED_PRODUCT_SUCCESS';
export const CREATE_DAMAGED_PRODUCT_FAILURE = 'CREATE_DAMAGED_PRODUCT_FAILURE';

export const GET_DAMAGED_PRODUCT_ATTEMPT = 'GET_DAMAGED_PRODUCT_ATTEMPT';
export const GET_DAMAGED_PRODUCT_SUCCESS = 'GET_DAMAGED_PRODUCT_SUCCESS';
export const GET_DAMAGED_PRODUCT_FAILURE = 'GET_DAMAGED_PRODUCT_FAILURE';

export const UPDATE_DAMAGED_PRODUCT_ATTEMPT = 'UPDATE_DAMAGED_PRODUCT_ATTEMPT';
export const UPDATE_DAMAGED_PRODUCT_SUCCESS = 'UPDATE_DAMAGED_PRODUCT_SUCCESS';
export const UPDATE_DAMAGED_PRODUCT_FAILURE = 'UPDATE_DAMAGED_PRODUCT_FAILURE';

export const CLEAN_DAMAGED_PRODUCT = 'CLEAN_DAMAGED_PRODUCT';
