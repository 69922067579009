import React from "react";
import DataTable from "react-data-table-component";

export const TablePayment = ({
                                 payments,
                                 setModalEditPayment,
                                 setFocusPayment,
}) => {

    const columns = [
        {
            name: 'Id',
            selector: row => row.id,
            center: true,
            width: '50px'
        },
        {
            name: 'Nombre',
            selector: row => row.name,
            center: true,
            width: '350px'
        },
        {
            name: 'Acción',
            selector: row => <button type="button" className="btn button-table-category"
                                     onClick={() => {
                                         setModalEditPayment(true);
                                         setFocusPayment(row);
                                     }}>
                <i className="fa-solid fa-pen-to-square fa-lg icon-table" />
            </button>,
            center: true,
            width: '150px'
        },
    ];

    return (
        <div className="card mb-3">
            <DataTable
                title="Datatable Product"
                columns={columns}
                data={payments}
                defaultSortField="title"
                pagination={payments.length > 10}
                selectableRows={false}
                className="table myDataTable table-hover align-middle mb-0 d-row nowrap dataTable no-footer dtr-inline"
                highlightOnHover={true}
                noDataComponent={'No hay métodos de pago en estos momentos'}
                paginationComponentOptions={{
                    rowsPerPageText: 'Filas por página',
                    rangeSeparatorText: 'de'
                }}
            />
        </div>
    );
}
