import {
    CLEAN_PROVIDER,
    CREATE_PROVIDER_ATTEMPT, CREATE_PROVIDER_FAILURE, CREATE_PROVIDER_SUCCESS,
    GET_PROVIDERS_ATTEMPT,
    GET_PROVIDERS_FAILURE,
    GET_PROVIDERS_SUCCESS, UPDATE_PROVIDER_ATTEMPT, UPDATE_PROVIDER_FAILURE, UPDATE_PROVIDER_SUCCESS
} from "./constants";

export const getProviders = token => ({
    type: GET_PROVIDERS_ATTEMPT,
    token,
});

export const getProvidersSuccess = providers => ({
    type: GET_PROVIDERS_SUCCESS,
    providers,
});

export const getProvidersFailure = error => ({
    type: GET_PROVIDERS_FAILURE,
    error,
})

export const createProvider = (token, values) => ({
    type: CREATE_PROVIDER_ATTEMPT,
    token,
    values,
});

export const createProviderSuccess = provider => ({
    type: CREATE_PROVIDER_SUCCESS,
    provider
});

export const createProviderFailure = error => ({
    type: CREATE_PROVIDER_FAILURE,
    error,
});

export const updateProvider = (token, values) => ({
    type: UPDATE_PROVIDER_ATTEMPT,
    token,
    values,
});

export const updateProviderSuccess = provider => ({
    type: UPDATE_PROVIDER_SUCCESS,
    provider,
});

export const updateProviderFailure = error => ({
    type: UPDATE_PROVIDER_FAILURE,
    error,
});

export const cleanProvider = () => ({
    type: CLEAN_PROVIDER,
});
