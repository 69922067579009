import {
    CACHE_DASHBOARD,
    CLEAN_DASHBOARD,
    GET_DASHBOARD_ATTEMPT,
    GET_DASHBOARD_FAILURE,
    GET_DASHBOARD_SUCCESS
} from "./constants";

export const getDashboard = token => ({
    type: GET_DASHBOARD_ATTEMPT,
    token,
});

export const getDashboardSuccess = dashboard => ({
    type: GET_DASHBOARD_SUCCESS,
    dashboard,
});

export const getDashboardFailure = error => ({
    type: GET_DASHBOARD_FAILURE,
    error
});

export const cacheDashboard = (dashboard) => ({
    type: CACHE_DASHBOARD,
    dashboard
});

export const cleanDashboard = () => ({
    type: CLEAN_DASHBOARD,
});
