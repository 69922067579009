import React from "react";
import DataTable from "react-data-table-component";
import {PaginateComponent} from "../Paginate/Paginate";

export const TableBuyer = ({
                                 buyers,
                                 metaBuyers,
                                 pageCurrency,
                                 handleChangePage,
}) => {

    const columns = [
        {
            name: 'Id',
            selector: row => row.id,
            center: true,
            width: '50px'
        },
        {
            name: 'Nombre',
            selector: row => row.name,
            center: true,
            width: '350px'
        },
        {
            name: 'Teléfono',
            selector: row => row.phone,
            center: true,
            width: '150px'
        },
        {
            name: 'Dirección',
            selector: row => row.address,
            center: true,
            width: '250px'
        },
    ];

    return (
        <div className="card mb-3">
            <DataTable
                title="Datatable Product"
                columns={columns}
                data={buyers}
                defaultSortField="title"
                pagination={false}
                selectableRows={false}
                className="table myDataTable table-hover align-middle mb-0 d-row nowrap dataTable no-footer dtr-inline"
                highlightOnHover={true}
                noDataComponent={'No hay compradores en estos momentos'}
            />
            {metaBuyers.total > 9 && (
                <PaginateComponent
                    pageCurrency={pageCurrency}
                    handleChangePage={handleChangePage}
                    meta={metaBuyers}
                />
            )}
        </div>
    );
}
