import {
    CLEAN_PAYMENT,
    CREATE_PAYMENT_ATTEMPT, CREATE_PAYMENT_FAILURE, CREATE_PAYMENT_SUCCESS,
    GET_ALL_PAYMENTS_ATTEMPT,
    GET_ALL_PAYMENTS_FAILURE,
    GET_ALL_PAYMENTS_SUCCESS, UPDATE_PAYMENT_ATTEMPT, UPDATE_PAYMENT_FAILURE, UPDATE_PAYMENT_SUCCESS
} from "./constants";

const initialState = {
    getAllPaymentsRequesting: false,
    getAllPaymentsSuccess: false,
    getAllPaymentsError: '',
    allPayments: [],
    createPaymentRequesting: false,
    createPaymentSuccess: false,
    createPaymentError: '',
    updatePaymentRequesting: false,
    updatePaymentSuccess: false,
    updatePaymentError: '',
}

const reducer = (state = initialState, action) => {
    switch (action.type){
        case GET_ALL_PAYMENTS_ATTEMPT:
            return {
                ...state,
                getAllPaymentsRequesting: true,
            };
        case GET_ALL_PAYMENTS_SUCCESS:
            return {
                ...state,
                getAllPaymentsRequesting: false,
                getAllPaymentsSuccess: true,
                allPayments: action.payments,
            };
        case GET_ALL_PAYMENTS_FAILURE:
            return {
                ...state,
                getAllPaymentsRequesting: false,
                getAllPaymentsError: action.error,
            };
        case CREATE_PAYMENT_ATTEMPT:
            return {
                ...state,
                createPaymentRequesting: true,
                createPaymentSuccess: false,
                updatePaymentSuccess: false,
            };
        case CREATE_PAYMENT_SUCCESS:
            return {
                ...state,
                createPaymentRequesting: false,
                createPaymentSuccess: true,
                allPayments: [...state.allPayments, action.payment],
            };
        case CREATE_PAYMENT_FAILURE:
            return {
                ...state,
                createPaymentRequesting: false,
                createPaymentError: action.error,
            };
        case UPDATE_PAYMENT_ATTEMPT:
            return {
                ...state,
                updatePaymentRequesting: true,
                updatePaymentSuccess: false,
                createPaymentSuccess: false,
            };
        case UPDATE_PAYMENT_SUCCESS:
            return {
                ...state,
                updatePaymentRequesting: false,
                updatePaymentSuccess: true,
                allPayments: state.allPayments.map(payment => (
                    payment.id === action.payment.id ? action.payment : payment
                ))
            };
        case UPDATE_PAYMENT_FAILURE:
            return {
                ...state,
                updatePaymentRequesting: false,
                updatePaymentError: action.error,
            };
        case CLEAN_PAYMENT:
            return {
                ...state,
                getAllPaymentsRequesting: false,
                getAllPaymentsSuccess: false,
                getAllPaymentsError: '',
                allPayments: [],
                createPaymentRequesting: false,
                createPaymentSuccess: false,
                createPaymentError: '',
                updatePaymentRequesting: false,
                updatePaymentSuccess: false,
                updatePaymentError: '',
            };
        default:
            return state;
    }
}

export default reducer;

