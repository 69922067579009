import {
    GET_STATES_DAMAGED_PRODUCT_ATTEMPT,
    GET_STATES_DAMAGED_PRODUCT_FAILURE,
    GET_STATES_DAMAGED_PRODUCT_SUCCESS
} from "./constants";

export const getStatesDamagedProduct = token => ({
    type: GET_STATES_DAMAGED_PRODUCT_ATTEMPT,
    token,
});

export const getStatesDamagedProductSuccess = statesDamagedProduct => ({
    type: GET_STATES_DAMAGED_PRODUCT_SUCCESS,
    statesDamagedProduct,
});

export const getStatesDamagedProductFailure = error => ({
    type: GET_STATES_DAMAGED_PRODUCT_FAILURE,
    error,
});
