import {
    CLEAN_SECTION,
    CREATE_SECTION_ATTEMPT,
    CREATE_SECTION_FAILURE,
    CREATE_SECTION_SUCCESS,
    GET_SECTIONS_ATTEMPT,
    GET_SECTIONS_FAILURE,
    GET_SECTIONS_SUCCESS,
    UPDATE_ACTIVE_SECTION_ATTEMPT, UPDATE_ACTIVE_SECTION_FAILURE, UPDATE_ACTIVE_SECTION_SUCCESS,
    UPDATE_SECTION_ATTEMPT,
    UPDATE_SECTION_FAILURE,
    UPDATE_SECTION_SUCCESS
} from "./constants";

const initialState = {
    getSectionsRequesting: false,
    getSectionsSuccess: false,
    getSectionsError: '',
    createSectionRequesting: false,
    createSectionSuccess: false,
    createSectionError: '',
    updateSectionRequesting: false,
    updateSectionSuccess: false,
    updateSectionError: '',
    updateActiveRequesting: false,
    updateActiveSuccess: false,
    updateActiveError: '',
    sections: [],
}

const reducer = (state = initialState, action) => {
    switch (action.type){
        case GET_SECTIONS_ATTEMPT:
            return {
                ...state,
                getSectionsRequesting: true,
            };
        case GET_SECTIONS_SUCCESS:
            return {
                ...state,
                getSectionsRequesting: false,
                getSectionsSuccess: true,
                sections: action.sections
            };
        case GET_SECTIONS_FAILURE:
            return {
                ...state,
                getSectionsRequesting: false,
                getSectionsError: action.error,
            };
        case CREATE_SECTION_ATTEMPT:
            return {
                ...state,
                createSectionRequesting: true,
                createSectionSuccess: false,
                updateSectionSuccess: false,
                updateActiveSuccess: false,
            };
        case CREATE_SECTION_SUCCESS:
            return {
                ...state,
                createSectionRequesting: false,
                createSectionSuccess: true,
                sections: [...state.sections, action.section]
            };
        case CREATE_SECTION_FAILURE:
            return {
                ...state,
                createSectionRequesting: false,
                createSectionError: action.error,
            };
        case UPDATE_SECTION_ATTEMPT:
            return {
                ...state,
                updateSectionRequesting: true,
                updateSectionSuccess: false,
                createSectionSuccess: false,
                updateActiveSuccess: false,
            };
        case UPDATE_SECTION_SUCCESS:
            return {
                ...state,
                updateSectionRequesting: false,
                updateSectionSuccess: true,
                sections: state.sections.map(section => (
                    section.id === action.section.id ? action.section : section
                ))
            };
        case UPDATE_SECTION_FAILURE:
            return {
                ...state,
                updateSectionRequesting: false,
                updateSectionError: action.error,
            };
        case UPDATE_ACTIVE_SECTION_ATTEMPT:
            return {
                ...state,
                updateActiveRequesting: true,
                updateActiveSuccess: false,
                updateSectionSuccess: false,
                createSectionSuccess: false,
            };
        case UPDATE_ACTIVE_SECTION_SUCCESS:
            return {
                ...state,
                updateActiveRequesting: false,
                updateActiveSuccess: true,
                sections: state.sections.map(section => (
                    section.id === action.section.id ? action.section : section
                ))
            };
        case UPDATE_ACTIVE_SECTION_FAILURE:
            return {
                ...state,
                updateActiveRequesting: false,
                updateActiveError: action.error,
            };
        case CLEAN_SECTION:
            return {
                ...state,
                getSectionsRequesting: false,
                getSectionsSuccess: false,
                getSectionsError: '',
                createSectionRequesting: false,
                createSectionSuccess: false,
                createSectionError: '',
                updateSectionRequesting: false,
                updateSectionSuccess: false,
                updateSectionError: '',
                updateActiveRequesting: false,
                updateActiveSuccess: false,
                updateActiveError: '',
                sections: [],
            };
        default:
            return state;
    }
}

export default reducer;
