import {
    CLEAN_PROVIDER,
    CREATE_PROVIDER_ATTEMPT, CREATE_PROVIDER_FAILURE, CREATE_PROVIDER_SUCCESS,
    GET_PROVIDERS_ATTEMPT,
    GET_PROVIDERS_FAILURE,
    GET_PROVIDERS_SUCCESS, UPDATE_PROVIDER_ATTEMPT, UPDATE_PROVIDER_FAILURE, UPDATE_PROVIDER_SUCCESS
} from "./constants";

const initialState = {
    getProvidersRequesting: false,
    getProvidersSuccess: false,
    getProvidersError: '',
    providers: [],
    createProviderRequesting: false,
    createProviderSuccess: false,
    createProviderError: '',
    updateProviderRequesting: false,
    updateProviderSuccess: false,
    updateProviderError: '',
};

const reducer = (state = initialState, action) => {
    switch (action.type){
        case GET_PROVIDERS_ATTEMPT:
            return {
                ...state,
                getProvidersRequesting: true,
            };
        case GET_PROVIDERS_SUCCESS:
            return {
                ...state,
                getProvidersRequesting: false,
                getProvidersSuccess: true,
                providers: action.providers.data,
            };
        case GET_PROVIDERS_FAILURE:
            return {
                ...state,
                getProvidersRequesting: false,
                getProvidersError: action.error,
            };
        case CREATE_PROVIDER_ATTEMPT:
            return {
                ...state,
                createProviderRequesting: true,
                createProviderSuccess: false,
                updateProviderSuccess: false,
            };
        case CREATE_PROVIDER_SUCCESS:
            return {
                ...state,
                createProviderRequesting: false,
                createProviderSuccess: true,
                providers: [...state.providers, action.provider],
            };
        case CREATE_PROVIDER_FAILURE:
            return {
                ...state,
                createProviderRequesting: false,
                createProviderError: action.error,
            };
        case UPDATE_PROVIDER_ATTEMPT:
            return {
                ...state,
                updateProviderRequesting: true,
                updateProviderSuccess: false,
                createProviderSuccess: false,
            };
        case UPDATE_PROVIDER_SUCCESS:
            return {
                ...state,
                updateProviderRequesting: false,
                updateProviderSuccess: true,
                providers: state.providers.map(provider => (
                    provider.id === action.provider.id ? action.provider : provider
                ))
            };
        case UPDATE_PROVIDER_FAILURE:
            return {
                ...state,
                updateProviderRequesting: false,
                updateProviderError: action.error,
            };
        case CLEAN_PROVIDER:
            return {
                ...state,
                getProvidersRequesting: false,
                getProvidersSuccess: false,
                getProvidersError: '',
                providers: [],
                createProviderRequesting: false,
                createProviderSuccess: false,
                createProviderError: '',
                updateProviderRequesting: false,
                updateProviderSuccess: false,
                updateProviderError: '',
            }
        default:
            return state;
    }
}

export default reducer;
