import React, {useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import {useDispatch, useSelector} from "react-redux";
import {addPackingProducts} from "../../redux/product/actions";

export const AddPackingToProduct = ({
                                        visible,
                                        close,
                                        focus,
                                        packing,
}) => {

    const dispatch = useDispatch();

    const [selectedOption, setSelectedOption] = useState(focus.packing);

    const {addPackingProductRequesting} = useSelector(state => state.product);
    const {token} = useSelector(state => state.auth);

    const handleAddPacking = () => {
        let returnPacking = selectedOption.map(packing => {return {packing_id: packing.value }});
        let packingDelete = focus.packing.filter(x => selectedOption.indexOf(x) === -1);
        let returnDelete = packingDelete.map(packing => {return {packing_id: packing.value }});
        let values = {
            "packing": returnPacking,
            "delete": returnDelete.length > 0,
            "packing_delete": returnDelete
        }
        dispatch(addPackingProducts(token, values, focus.id))
    };

    return (
        <Modal show={visible} onHide={() =>{ return true}} style={{ display: 'block' }}>
            <div className="modal-content">
                <Modal.Header className="modal-header" closeButton onClick={close}>
                    <h5 className="modal-title  fw-bold" id="addProduct"> Añadir empaque al producto</h5>
                </Modal.Header>
                <Modal.Body>
                    <Select
                        defaultValue={selectedOption}
                        onChange={setSelectedOption}
                        options={packing}
                        isMulti
                        placeholder={'Seleccionar'}
                        noOptionsMessage={() =>
                             "No hay empaques"
                        }
                    />
                </Modal.Body>
                <div className="modal-footer">
                    {!addPackingProductRequesting && (
                        <button type="button"
                                className="btn btn-secondary col-sm-3"
                                onClick={close}
                                data-bs-dismiss="modal">Cancelar</button>
                    )}
                    {addPackingProductRequesting && (
                        <button className="btn btn-primary lift btn-spinner container-spinner col-sm-3">
                            <i className="fa fa-spinner fa-spin"></i>
                        </button>
                    )}
                    {!addPackingProductRequesting && (
                        <button type="submit" onClick={handleAddPacking} className="btn btn-primary col-sm-3">Actualizar</button>
                    )}
                </div>
            </div>
        </Modal>
    )
};
