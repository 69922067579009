import React from "react";
import Modal from "react-bootstrap/Modal";
import {Formik} from "formik";
import {useDispatch, useSelector} from "react-redux";
import {addStockPacking} from "../../redux/packing/actions";

export const AddStockPacking = ({
                             visible,
                             close,
                             focus,
}) => {

    const dispatch = useDispatch();

    const {token} = useSelector(state => state.auth);
    const {addStockPackingRequesting} = useSelector(state => state.packing);

    const handleAddStockPacking = values => {

        let body = {
            stock: values.stock,
            packing_id: focus.id,
        };

        dispatch(addStockPacking(token, body));
    };

    return (
        <Modal show={visible} onHide={close} style={{ display: 'block' }}>
            <div className="modal-content">
                <Modal.Header className="modal-header" closeButton>
                    <h5 className="modal-title  fw-bold" id="addProduct">{`Agregar stock al empaque ${focus.name}`}</h5>
                </Modal.Header>
                <Formik
                    initialValues={{ stock: '' }}
                    onSubmit={(values ) => {
                        handleAddStockPacking(values)
                    }}
                >
                    {({
                          handleChange,
                          handleSubmit,
                      }) => (
                        <form onSubmit={handleSubmit}>
                            <Modal.Body className="modal-body">
                                <div className="mb-3 mt-1">
                                    <label className="mb-1 form-label">{`Cantidad a agregar`}</label>
                                    <input type="text" className="form-control"
                                           placeholder={focus.stock}
                                           name={'stock'}
                                           onChange={handleChange}
                                    />
                                </div>
                            </Modal.Body>
                            <div className="modal-footer">
                                {!addStockPackingRequesting && (
                                    <button className="btn btn-secondary col-sm-4"
                                            onClick={close}
                                            data-bs-dismiss="modal">Cancelar</button>
                                )}
                                {addStockPackingRequesting && (
                                    <button className="btn btn-primary lift btn-spinner container-spinner col-sm-4">
                                        <i className="fa fa-spinner fa-spin"></i>
                                    </button>
                                )}
                                {!addStockPackingRequesting && (
                                    <button type={"submit"}
                                            className="btn btn-primary col-sm-4">
                                        Agregar stocks
                                    </button>
                                )}
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </Modal>
    )
};
