import {all, call, put, takeEvery} from 'redux-saga/effects'
import {
    createDamagedPackingFailure,
    createDamagedPackingSuccess,
    getDamagedPackingFailure,
    getDamagedPackingSuccess, updateDamagedPackingFailure, updateDamagedPackingSuccess
} from "./actions";
import {CREATE_DAMAGED_PACKING_ATTEMPT, GET_DAMAGED_PACKING_ATTEMPT, UPDATE_DAMAGED_PACKING_ATTEMPT} from "./constants";
import {getPack} from "../packing/actions";

const URL = `${process.env.REACT_APP_API_KEY}`;

const createDamagedPackingAPI = (token, values) => {
    return fetch(`${URL}damaged_packing/damaged_packing`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(values),
    })
        .then(response => response.json())
        .then(json => {
            if (json.code === 200){
                return json.data;
            }
        }).catch((error) => {
            throw error;
        })
}

function* createDamagedPackingFlow(action) {
    try {
        const {token, values} = action;
        const damagedPacking = yield call(createDamagedPackingAPI, token, values);
        yield put(createDamagedPackingSuccess(damagedPacking));
        yield put(getPack(damagedPacking.packing));
    } catch (error) {
        yield put(createDamagedPackingFailure(error));
    }
}

const getDamagedPackingAPI = (token, page) => {
    return fetch(`${URL}damaged_packing/damaged_packing?page=${page}&top=10`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    })
        .then(response => response.json())
        .then(json => {
            if (json.data.length === 0){
                throw 'No hay empaques averiados';
            }
            if (json.data.length > 0){
                return json;
            }
        }).catch((error) => {
            throw error;
        })
}

function* getDamagedPackingFlow(action) {
    try {
        const {token, page} = action;
        const damagedPacking = yield call(getDamagedPackingAPI, token, page);
        yield put(getDamagedPackingSuccess(damagedPacking));
    } catch (error) {
        yield put(getDamagedPackingFailure(error));
    }
}

const updateDamagedPackingAPI = (token, values) => {
    return fetch(`${URL}damaged_packing/damaged_packing/${values.id}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(values),
    })
        .then(response => response.json())
        .then(json => {
            if (json.code === 200){
                return json.damagedPacking;
            }
        }).catch((error) => {
            throw error;
        })
}

function* updateDamagedPackingFlow(action) {
    try {
        const {token, values} = action;
        const damagedPacking = yield call(updateDamagedPackingAPI, token, values);
        yield put(updateDamagedPackingSuccess(damagedPacking));
    } catch (error) {
        yield put(updateDamagedPackingFailure(error));
    }
}

function* damagedPackingWatcher(){
    yield all([
        takeEvery(CREATE_DAMAGED_PACKING_ATTEMPT, createDamagedPackingFlow),
        takeEvery(GET_DAMAGED_PACKING_ATTEMPT, getDamagedPackingFlow),
        takeEvery(UPDATE_DAMAGED_PACKING_ATTEMPT, updateDamagedPackingFlow)
    ])
}

export default damagedPackingWatcher;

