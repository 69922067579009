import React, {useEffect, useState} from 'react';
import {Sidebar} from "../../components/Sidebar/Sidebar";
import {Header} from "../../components/Header/Header";
import {useDispatch, useSelector} from "react-redux";
import {TableAccount} from "../../components/Account/Table";
import {getAccounts, postShould} from "../../redux/account/actions";
import {ModalHistory} from "../../components/Account/ModalHistory";
import {ModalPayment} from "../../components/Account/ModalPayment";
import {getAllPayments} from "../../redux/payment/actions";

export const Account = () => {

    const dispatch = useDispatch();

    const {user, token, successMe, sidebarMini} = useSelector(state => state.auth);
    const {getAccountSuccess, accounts, metaAccounts,
        pageAccounts, postShouldError, postShouldRequesting,
        postShouldSuccess} = useSelector(state => state.account);
    const {getAllPaymentsSuccess, allPayments} = useSelector(state => state.payment);

    const [modalHistory, setModalHistory] = useState(false);
    const [modalAddPayment, setModalAddPayment] = useState(false);
    const [focusHistory, setFocusHistory] = useState({});
    const [focusState, setFocusState] = useState({});
    const [focusAccountId, setFocusAccountId] = useState(0);

    const handleChangePage = (page) => {
        if(page !== pageAccounts && page !== 0){
            dispatch(getAccounts(token, page))
        }
    }

    const handleShould = (values) => {
        let data = {
            should: values.should,
            payment_id: parseInt(values.paymentId),
            account_id: focusAccountId,
        };
        dispatch(postShould(token, data));
    };

    useEffect(() => {
        if(postShouldSuccess){
            setModalAddPayment(false);
        }
    }, [postShouldSuccess]);

    useEffect(() => {
        if(!getAccountSuccess && successMe)
            dispatch(getAccounts(token, 1))
        if(!getAllPaymentsSuccess && successMe)
            dispatch(getAllPayments())
    }, [successMe]);

    return (
        <div className={'d-flex'}>
            <Sidebar isSidebarMini={sidebarMini}
                     routeCurrency={'/accounts'} />
            <div className='main px-lg-12 px-md-4' >
                <Header user={user}/>
                <div className={`${sidebarMini ? 'table-widthSideMini': 'table-widthSide'} body d-flex py-3`}>
                    <TableAccount
                        accounts={accounts}
                        metaAccounts={metaAccounts}
                        pageCurrency={pageAccounts}
                        handleChangePage={handleChangePage}
                        modalHistory={setModalHistory}
                        setFocusHistory={setFocusHistory}
                        setFocusState={setFocusState}
                        setModalAddPayment={setModalAddPayment}
                        setFocusAccountId={setFocusAccountId}
                    />
                </div>
            </div>
            {modalHistory && (
                <ModalHistory
                    visible={modalHistory}
                    close={() => {
                        setModalHistory(false);
                        setFocusHistory({});
                        setFocusState({});
                    }}
                    histories={focusHistory}
                    state={focusState}
                />
            )}
            {modalAddPayment && (
                <ModalPayment
                    visible={modalAddPayment}
                    close={() => setModalAddPayment(false)}
                    payments={allPayments}
                    handleShould={handleShould}
                    requesting={postShouldRequesting}
                    error={postShouldError}
                />
            )}
        </div>
    )
}
