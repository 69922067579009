import {all, call, put, takeEvery} from 'redux-saga/effects'
import {getAccountsFailure, getAccountsSuccess, postShouldFailure, postShouldSuccess} from "./actions";
import {GET_ACCOUNTS_ATTEMPT, POST_SHOULD_ATTEMPT} from "./constants";

const URL = `${process.env.REACT_APP_API_KEY}`;

const getAccountsAPI = (token, page) => {
    return fetch(`${URL}accounts/accounts?page=${page}&top=10`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    })
        .then(response => response.json())
        .then(json => {
            if (json.data.length === 0){
                throw 'No hay cuentas';
            }
            if (json.data.length > 0){
                return json;
            }
        }).catch((error) => {
            throw error;
        })
}

function* getAccountsFlow(action) {
    try {
        const {token, page} = action;
        const accounts = yield call(getAccountsAPI, token, page);
        yield put(getAccountsSuccess(accounts));
    } catch (error) {
        yield put(getAccountsFailure(error));
    }
}

const postShouldAPI = (token, values) => {
    return fetch(`${URL}history_account/history_account`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        mode: 'cors',
        body: JSON.stringify(values)
    })
        .then(response => response.json())
        .then(json => {
            if (json.code === 200){
                return json.order;
            }else if(json.code === 400){
                throw json.message;
            }else {
                throw json.message;
            }
        }).catch((error) => {
            throw error;
        })
}

function* postShouldFlow(action) {
    try {
        const {token, values} = action;
        const account = yield call(postShouldAPI, token, values);
        yield put(postShouldSuccess(account));
    } catch (error) {
        yield put(postShouldFailure(error));
    }
}


function* accountWatcher(){
    yield all([
        takeEvery(GET_ACCOUNTS_ATTEMPT, getAccountsFlow),
        takeEvery(POST_SHOULD_ATTEMPT, postShouldFlow)
    ])
}

export default accountWatcher;
