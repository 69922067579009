import {
    CLEAN_PAYMENT,
    CREATE_PAYMENT_ATTEMPT, CREATE_PAYMENT_FAILURE, CREATE_PAYMENT_SUCCESS,
    GET_ALL_PAYMENTS_ATTEMPT,
    GET_ALL_PAYMENTS_FAILURE,
    GET_ALL_PAYMENTS_SUCCESS, UPDATE_PAYMENT_ATTEMPT, UPDATE_PAYMENT_FAILURE, UPDATE_PAYMENT_SUCCESS
} from "./constants";

export const getAllPayments = ()  => ({
    type: GET_ALL_PAYMENTS_ATTEMPT,
});

export const getAllPaymentsSuccess = payments => ({
    type: GET_ALL_PAYMENTS_SUCCESS,
    payments,
});

export const getAllPaymentsFailure = error => ({
    type: GET_ALL_PAYMENTS_FAILURE,
    error,
});

export const createPayment = (token, values) => ({
    type: CREATE_PAYMENT_ATTEMPT,
    token,
    values,
});

export const createPaymentSuccess = payment => ({
    type: CREATE_PAYMENT_SUCCESS,
    payment,
});

export const createPaymentFailure = error => ({
    type: CREATE_PAYMENT_FAILURE,
    error,
});

export const updatePayment = (token, values) => ({
    type: UPDATE_PAYMENT_ATTEMPT,
    token,
    values,
});

export const updatePaymentSuccess = payment => ({
    type: UPDATE_PAYMENT_SUCCESS,
    payment,
});

export const updatePaymentFailure = error => ({
    type: UPDATE_PAYMENT_FAILURE,
    error,
});

export const cleanPayment = () => ({
    type: CLEAN_PAYMENT,
});
