import {all, call, put, takeEvery} from 'redux-saga/effects'
import {
    createCategoryFailure,
    createCategorySuccess,
    getCategoriesFailure,
    getCategoriesSuccess, updateActiveCategoryFailure, updateActiveCategorySuccess, updateCategoryFailure,
    updateCategorySuccess
} from "./actions";
import {
    CREATE_CATEGORY_ATTEMPT,
    GET_CATEGORIES_ATTEMPT,
    UPDATE_ACTIVE_CATEGORY_ATTEMPT,
    UPDATE_CATEGORY_ATTEMPT
} from "./constants";

const URL = `${process.env.REACT_APP_API_KEY}`;

const getCategoriesAPI = token => {
    return fetch(`${URL}categories/categories`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    })
        .then(response => response.json())
        .then(json => {
            if (json.data.length === 0){
                throw 'No hay categorias';
            }
            if (json.data.length > 0){
                return json;
            }
        }).catch((error) => {
            throw error;
        })
}

function* getCategoriesFlow(action) {
    try {
        const {token} = action;
        const categories = yield call(getCategoriesAPI, token);
        yield put(getCategoriesSuccess(categories));
    } catch (error) {
        yield put(getCategoriesFailure(error));
    }
}

const createCategoryAPI = (token, values) => {
    return fetch(`${URL}categories/category`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(values)
    })
        .then(response => response.json())
        .then(json => {
            if (json?.code && json.code === 200){
                return json.category;
            }else {
                throw json.errors
            }
        }).catch((error) => {
            throw error;
        })
}

function* createCategoryFlow(action) {
    try {
        const {token, values} = action;
        const category = yield call(createCategoryAPI, token, values);
        yield put(createCategorySuccess(category));
    } catch (error) {
        yield put(createCategoryFailure(error));
    }
}

const updateCategoryAPI = (token, values) => {
    return fetch(`${URL}categories/category/${values.id}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(values),
    })
        .then(response => response.json())
        .then(json => {
            if (json?.code && json.code === 200){
                return json.category;
            }else if (json?.code && json.code === 400){
                throw json;
            }else {
                throw json.errors
            }
        }).catch((error) => {
            throw error;
        })
}

function* updateCategoryFlow(action) {
    try {
        const {token, values} = action;
        const category = yield call(updateCategoryAPI, token, values);
        yield put(updateCategorySuccess(category));
    } catch (error) {
        yield put(updateCategoryFailure(error));
    }
}

const updateActiveCategoryAPI = (token, values) => {
    return fetch(`${URL}categories/change_category/${values.id}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(values),
    })
        .then(response => response.json())
        .then(json => {
            if (json?.code && json.code === 200){
                return json.category;
            }else if (json?.code && json.code === 400){
                throw json;
            }else {
                throw json.errors
            }
        }).catch((error) => {
            throw error;
        })
}

function* updateActiveCategoryFlow(action) {
    try {
        const {token, values} = action;
        const category = yield call(updateActiveCategoryAPI, token, values);
        yield put(updateActiveCategorySuccess(category));
    } catch (error) {
        yield put(updateActiveCategoryFailure(error));
    }
}


function* categoryWatcher() {
    yield all([
        takeEvery(GET_CATEGORIES_ATTEMPT, getCategoriesFlow),
        takeEvery(CREATE_CATEGORY_ATTEMPT, createCategoryFlow),
        takeEvery(UPDATE_CATEGORY_ATTEMPT, updateCategoryFlow),
        takeEvery(UPDATE_ACTIVE_CATEGORY_ATTEMPT, updateActiveCategoryFlow)
    ])
}

export default categoryWatcher;
