import {
    CLEAN_RESET_PASSWORD,
    RESET_PASSWORD_ATTEMPT, RESET_PASSWORD_FAILURE, RESET_PASSWORD_SUCCESS,
    SEND_TOKEN_ATTEMPT,
    SEND_TOKEN_FAILURE,
    SEND_TOKEN_SUCCESS,
    VERIFY_TOKEN_ATTEMPT, VERIFY_TOKEN_FAILURE,
    VERIFY_TOKEN_SUCCESS
} from "./constants";

const initialState = {
    requestingSendToken: false,
    successSendEmail: false,
    errorSendEmail: '',
    messageSendEmail: '',
    viewActive: 'send_token',
    requestingVerifyToken: false,
    successVerifyToken: false,
    errorVerifyToken: '',
    messageVerifyToken: '',
    requestingResetPassword: false,
    successResetPassword: false,
    errorResetPassword: '',
    messageResetPassword: '',
};

const reducer = (state = initialState, action) => {
    switch (action.type){
        case SEND_TOKEN_ATTEMPT:
            return {
                ...state,
                requestingSendToken: true,
                messageSendEmail: '',
                successResetPassword: false,
                messageResetPassword: '',
            };
        case SEND_TOKEN_SUCCESS:
            return {
                ...state,
                requestingSendToken: false,
                successSendEmail: true,
                messageSendEmail: action.message,
                viewActive: 'validate_token',
            };
        case SEND_TOKEN_FAILURE:
            return {
                ...state,
                requestingSendToken: false,
                errorSendEmail: action.error,
            };
        case VERIFY_TOKEN_ATTEMPT:
            return {
                ...state,
                requestingVerifyToken: true,
                messageVerifyEmail: '',
                successSendEmail: false,
                messageSendEmail: '',
            };
        case VERIFY_TOKEN_SUCCESS:
            return {
                ...state,
                requestingVerifyToken: false,
                successVerifyToken: true,
                messageVerifyToken: action.message,
                viewActive: 'reset_password',
            };
        case VERIFY_TOKEN_FAILURE:
            return {
                ...state,
                requestingVerifyToken: false,
                errorVerifyToken: action.error,
            };
        case RESET_PASSWORD_ATTEMPT:
            return {
                ...state,
                requestingResetPassword: true,
                messageResetPassword: '',
                successVerifyToken: false,
                messageVerifyToken: '',
            };
        case RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                requestingResetPassword: false,
                successResetPassword: true,
                messageResetPassword: action.message,
                viewActive: 'send_token',
            };
        case RESET_PASSWORD_FAILURE:
            return {
                ...state,
                requestingResetPassword: false,
                errorResetPassword: action.error,
            };
        case CLEAN_RESET_PASSWORD:
            return {
                ...state,
                requestingSendToken: false,
                successSendEmail: false,
                errorSendEmail: '',
                messageSendEmail: '',
                viewActive: 'send_token',
                requestingVerifyToken: false,
                successVerifyToken: false,
                errorVerifyToken: '',
                messageVerifyToken: '',
                requestingResetPassword: false,
                successResetPassword: false,
                errorResetPassword: '',
                messageResetPassword: '',
            };
        default:
            return state;
    }
}

export default reducer;
