import React from 'react'
import {Modal} from "react-bootstrap";

export const ModalBuyer = ({
                               visible,
                               close,
                               buyer
}) => {
    return (
        <Modal show={visible} onHide={close} style={{ display: 'block' }}>
            <div className="modal-content">
                <Modal.Header className="modal-header" closeButton>
                    <h5 className="modal-title  fw-bold" id="expeditLabel1111">Información del comprador</h5>
                </Modal.Header>
                <Modal.Body className="modal-body">
                    <div className="deadline-form">
                        <div className="col-sm-12 mt-1">
                            <label htmlFor="item100" className="form-label">Nombre</label>
                            <input type="text" className="form-control" id="item100" value={buyer.name} disabled={true} />
                        </div>
                        <div className="col-sm-12 mt-4">
                            <label htmlFor="item100" className="form-label">Teléfono</label>
                            <input type="text" className="form-control" id="item100" value={buyer.phone} disabled={true} />
                        </div>
                        {buyer.address !== null && (
                            <div className="col-sm-12 mt-4">
                                <label htmlFor="item100" className="form-label">Dirección</label>
                                <input type="text" className="form-control" id="item100" value={buyer.address} disabled={true} />
                            </div>
                        )}
                    </div>
                </Modal.Body>
            </div>
        </Modal>
    )
}
