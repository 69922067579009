import {all, call, put, takeEvery} from 'redux-saga/effects'
import {
    resetPasswordFailure,
    resetPasswordSuccess,
    sendTokenFailure,
    sendTokenSuccess,
    verifyTokenFailure,
    verifyTokenSuccess
} from "./actions";
import {RESET_PASSWORD_ATTEMPT, SEND_TOKEN_ATTEMPT, VERIFY_TOKEN_ATTEMPT} from "./constants";

const URL = `${process.env.REACT_APP_API_KEY}`;

const sendTokenAPI = (email) => {

    return fetch(`${URL}send_token`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        mode: 'cors',
        body: JSON.stringify(email)
    })
        .then(response => response.json())
        .then(json => {
            if (json?.errors){
                throw json.errors;
            }
            if (json.code === 400){
                throw json;
            }
            if (json.code === 200){
                return json.message;
            }
        }).catch((error) => {
            throw error;
        })
};

function* sendTokenFlow(action) {
    try {
        const {email} = action;
        const message = yield call(sendTokenAPI, email);
        yield put(sendTokenSuccess(message));
    } catch (error) {
        yield put(sendTokenFailure(error))
    }
}

const verifyTokenAPI = (values) => {

    return fetch(`${URL}validate_token`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        mode: 'cors',
        body: JSON.stringify(values)
    })
        .then(response => response.json())
        .then(json => {
            if (json?.errors){
                throw json.errors;
            }
            if (json.code === 400){
                throw json;
            }
            if (json.code === 200){
                return json.message;
            }
        }).catch((error) => {
            throw error;
        })
};

function* verifyTokenFlow(action) {
    try {
        const {values} = action;
        const message = yield call(verifyTokenAPI, values);
        yield put(verifyTokenSuccess(message));
    } catch (error) {
        yield put(verifyTokenFailure(error))
    }
}

const resetPasswordAPI = (values) => {

    return fetch(`${URL}reset_password`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        mode: 'cors',
        body: JSON.stringify(values)
    })
        .then(response => response.json())
        .then(json => {
            if (json?.errors){
                throw json.errors;
            }
            if (json.code === 400){
                throw json;
            }
            if (json.code === 200){
                return json.message;
            }
        }).catch((error) => {
            throw error;
        })
};

function* resetPasswordFlow(action) {
    try {
        const {values} = action;
        const message = yield call(resetPasswordAPI, values);
        yield put(resetPasswordSuccess(message));
    } catch (error) {
        yield put(resetPasswordFailure(error))
    }
}

function* forgotPasswordWatcher() {
    yield all([
        takeEvery(SEND_TOKEN_ATTEMPT, sendTokenFlow),
        takeEvery(VERIFY_TOKEN_ATTEMPT, verifyTokenFlow),
        takeEvery(RESET_PASSWORD_ATTEMPT, resetPasswordFlow),
    ])
}

export default forgotPasswordWatcher;
