import React, {useEffect, useState} from "react";
import {Sidebar} from "../../components/Sidebar/Sidebar";
import {Header} from "../../components/Header/Header";
import {useDispatch, useSelector} from "react-redux";
import {toast, ToastContainer} from 'react-toastify';
import {createSection, getSections, updateActiveSection, updateSection} from "../../redux/section/actions";
import {TableSection} from "../../components/Section/Table";
import {AddSection} from "../../components/Section/AddSection";
import {EditSection} from "../../components/Section/EditSection";

export const Section = () => {

    const dispatch = useDispatch();

    const {user, successMe, token, sidebarMini} = useSelector(state => state.auth);
    const {getSectionSuccess, sections, createSectionSuccess,
        createSectionError, createSectionRequesting, updateSectionError,
        updateSectionRequesting, updateSectionSuccess} = useSelector(state => state.section);

    const [modal, setModal] = useState('');
    const [focusSection, setFocusSection] = useState({});

    const handleCreateSection = (values) => {
        let body = {
            name: values.name
        }
        dispatch(createSection(token, body));
    };

    const handleUpdateSection = values => {
        let body = {
            '_method': 'PUT',
            id: focusSection.id,
            name: values.name !== '' ? values.name : focusSection.name,
        }
        dispatch(updateSection(token, body));
    };

    const handleUpdateActiveSection = values => {
        let body = {
            '_method': 'PUT',
            id: values.id,
            active: values.active,
        }
        dispatch(updateActiveSection(token, body))
    };

    useEffect(() => {
        if(!getSectionSuccess && successMe)
            dispatch(getSections(token));
    }, []);

    useEffect(() => {
        if(createSectionSuccess){
            setModal('');
            toast("Sección creada", {type: 'success'});
        }
        if(updateSectionSuccess){
            setModal('');
            toast("Sección actualizada", {type: 'success'});
        }
    }, [createSectionSuccess, updateSectionSuccess])


    return (
        <div className={'d-flex'}>
            <Sidebar isSidebarMini={sidebarMini}
                     routeCurrency={'/sections'} />
            <div className='main px-lg-12 px-md-4' >
                <Header
                    search={false}
                    user={user}/>
                <button
                    type="button"
                    className="btn btn-primary align-self-end"
                    onClick={() => setModal('Add')}
                >
                    Crear Sección
                </button>
                <div className={`${sidebarMini ? 'table-widthSideMini': 'table-widthSide'} body d-flex py-3`}>
                    <div className={'table-widthMedium'}>
                        <TableSection
                            sections={sections}
                            setFocusSection={setFocusSection}
                            setModal={setModal}
                            handleUpdateActive={handleUpdateActiveSection}
                        />
                    </div>
                </div>
                <AddSection
                    visible={modal === 'Add'}
                    close={() => setModal('')}
                    error={createSectionError}
                    createSection={handleCreateSection}
                    requesting={createSectionRequesting}
                />
                <EditSection
                    visible={modal === 'Edit'}
                    close={() => setModal('')}
                    error={updateSectionError}
                    update={handleUpdateSection}
                    requesting={updateSectionRequesting}
                    focus={focusSection}
                />
                <ToastContainer autoClose={3000} />
            </div>
        </div>
    )
}
