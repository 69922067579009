import React, {useEffect, useState} from 'react'
import {Modal, Spinner} from "react-bootstrap";
import {FormatNumber} from "../../constants/FormatNumber";

export const ModalCart = ({
                               visible,
                               close,
                               products,
                               requesting
}) => {

    const [productsWithStock, setProductsWithStock] = useState([]);
    const [viewPacking, setViewPacking] = useState(0);

    const handleToggle = (product) => {
        if(product !== viewPacking){
            setViewPacking(product);
        }else{
            setViewPacking(0);
        }
    }

    useEffect(() => {
            if(!requesting){
                setProductsWithStock(products.filter(product => product.quantity > 0));
            }
    }, [requesting]);

    return (
        <Modal show={visible} onHide={close} style={{ display: 'block' }}>
            <div className="modal-content">
                <Modal.Header className="modal-header" closeButton>
                    <h5 className="modal-title  fw-bold" id="expeditLabel1111">Información de los productos de la orden</h5>
                </Modal.Header>
                {!requesting && (
                    <Modal.Body className="modal-body">
                        {productsWithStock.length > 0 && productsWithStock.map((product, index) => {
                            let packingWithStock = product.packing.filter(packing => packing.quantity > 0);
                            return   <div key={index}>
                                <label htmlFor="item100" className="form-label">Producto {index + 1}</label>
                                <div className="deadline-form">
                                    <div className="row g-3 mb-3">
                                        <div className="col-sm-12">
                                            <label htmlFor="item100" className="form-label">Nombre del producto:</label>
                                            <input type="text" className="form-control" id="item100" value={product.product_name} disabled={true} />
                                        </div>
                                        <div className="col-sm-12">
                                            <label htmlFor="item100" className="form-label">Subcategoría:</label>
                                            <input type="text" className="form-control" id="item100" value={product.sub_category} disabled={true} />
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-sm-4">
                                                <label htmlFor="item100" className="form-label">Sku:</label>
                                                <input type="text" className="form-control" id="item100" value={product.sku} disabled={true} />
                                            </div>
                                            <div className="col-sm-8">
                                                <label htmlFor="item100" className="form-label">Proveedor:</label>
                                                <input type="text" className="form-control" id="item100" value={product.provider} disabled={true} />
                                            </div>
                                        </div>
                                        {product?.reference_name && (
                                            <div className="col-sm-12">
                                                <label htmlFor="abc11edit" className="form-label">Referencia:</label>
                                                <input type="text" className="form-control" id="item100" value={product.reference_name} disabled={true} />
                                            </div>
                                        )}
                                        <div className="row g-3 mb-3">
                                            <div className="col-sm-6">
                                                {product.images.length > 0 && (
                                                    <img className="avatar img-thumbnail image-product" src={product.images[0].url} alt="profile" />
                                                )}
                                            </div>
                                            <div className="col-sm-6">
                                                <label htmlFor="abc11edit" className="form-label">Precio de venta:</label>
                                                <FormatNumber value={product.sale_price} disabled={true} className="form-control"/>
                                                <label htmlFor="abc11edit" className="form-label">Cantidad:</label>
                                                <input type="text" className="form-control" id="item100" value={product.quantity} disabled={true} />
                                                <label htmlFor="abc11edit" className="form-label">Total compra del producto:</label>
                                                <FormatNumber value={product.sale_price * product.quantity} disabled={true} className="form-control"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {packingWithStock.length > 0 && (
                                    <>
                                        <div className="container-pack" onClick={() => handleToggle(product.product_id)}>
                                            <div  className="form-label">Empaques del producto {index + 1} </div>
                                            <i className={viewPacking === product.product_id ? "fa-solid fa-arrow-up" : "fa-solid fa-arrow-down"}></i>
                                        </div>
                                        {viewPacking === product.product_id && packingWithStock.map((pack, index) => (
                                            <div key={index}>
                                                <label htmlFor="item100" className="form-label">Empaque {index + 1}</label>
                                                <div className="deadline-form">
                                                    <div className="row g-3 mb-3">
                                                        <div className="col-sm-12">
                                                            <label htmlFor="item100" className="form-label">Nombre del empaque:</label>
                                                            <input type="text" className="form-control" id="item100" value={pack.packing_name} disabled={true} />
                                                        </div>
                                                        <div className="row g-3 mb-3">
                                                            <div className="col-sm-6">
                                                                {pack.images.length > 0 && (
                                                                    <img className="avatar img-thumbnail image-product" src={pack.images[0].url} alt="profile" />
                                                                )}
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <label htmlFor="abc11edit" className="form-label">Precio de venta:</label>
                                                                <FormatNumber value={pack.sale_price} disabled={true} className="form-control"/>
                                                                <label htmlFor="abc11edit" className="form-label">Cantidad:</label>
                                                                <input type="text" className="form-control" id="item100" value={pack.quantity} disabled={true} />
                                                                <label htmlFor="abc11edit" className="form-label">Total compra del empaque:</label>
                                                                <FormatNumber value={pack.sale_price * pack.quantity} disabled={true} className="form-control"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                )}

                            </div>
                        })}
                    </Modal.Body>
                )}
                {requesting && <>
                    <Spinner animation="border" role="status" size="xm" className={'spinner'}/>
                    <span className="name-spinner" >Cargando productos...</span>
                </>
              }
            </div>
        </Modal>
    )
}
