import React, {useEffect, useState} from "react";
import {Sidebar} from "../../components/Sidebar/Sidebar";
import {Header} from "../../components/Header/Header";
import {useDispatch, useSelector} from "react-redux";
import {createCategory, getCategories, updateActiveCategory, updateCategory} from "../../redux/category/actions";
import {TableCategory} from "../../components/Category/Table";
import {AddCategory} from "../../components/Category/AddCategory";
import {EditCategory} from "../../components/Category/EditCategory";
import { ToastContainer, toast } from 'react-toastify';
import {getSections} from "../../redux/section/actions";


export const Category = () => {

    const dispatch = useDispatch();

    const {user, successMe, token, sidebarMini} = useSelector(state => state.auth);
    const {getCategoriesSuccess, categories, createCategoryError,
    createCategoryRequesting, createCategorySuccess, updateCategoryError,
        updateCategoryRequesting, updateCategorySuccess} = useSelector(state => state.category);
    const {getSectionSuccess, sections} = useSelector(state => state.section);

    const [modalCategory, setModalCategory] = useState(false);
    const [modalEditCategory, setModalEditCategory] = useState(false);
    const [focusCategory, setFocusCategory] = useState({});

    const handleCreateCategory = values => {
        let body = {
            name: values.name,
            section_id: values.section
        }
        dispatch(createCategory(token, body));
    };

    const handleUpdateCategory = values => {
        let body = {
            '_method': 'PUT',
            id: focusCategory.id,
            name: values.name !== '' ? values.name : focusCategory.name,
            section_id: values.section !== '' ? values.section : focusCategory.section_id,
        }
        dispatch(updateCategory(token, body));
    };

    const handleUpdateActiveCategory = values => {
        let body = {
            '_method': 'PUT',
            id: values.id,
            active: values.active,
        }
        dispatch(updateActiveCategory(token, body));
    };

    useEffect(() => {
        if(!getCategoriesSuccess && successMe)
            dispatch(getCategories(token));
        if(!getSectionSuccess && successMe)
            dispatch(getSections(token));
    }, []);

    useEffect(() => {
        if(createCategorySuccess){
            setModalCategory(false);
            toast("Categoría creada", {type: 'success'});
        }
        if(updateCategorySuccess){
            setModalEditCategory(false);
            toast("Categoría modificada", {type: 'success'});
        }
    }, [createCategorySuccess, updateCategorySuccess]);

    return (
        <div className={'d-flex'}>
            <Sidebar isSidebarMini={sidebarMini}
                     routeCurrency={'/categories'} />
            <div className='main px-lg-12 px-md-4' >
                <Header
                    search={false}
                    user={user}/>
                <button
                    type="button"
                    className="btn btn-primary align-self-end"
                    onClick={() => setModalCategory(true)}
                >
                    Crear categoría
                </button>
                <div className={`${sidebarMini ? 'table-widthSideMini': 'table-widthSide'} body d-flex py-3`}>
                    <div className={'table-widthMini'}>
                        <TableCategory
                            categories={categories}
                            setFocusCategory={setFocusCategory}
                            setModalEditCategory={setModalEditCategory}
                            handleUpdateActive={handleUpdateActiveCategory}
                        />
                    </div>
                </div>
                <AddCategory
                    visible={modalCategory}
                    close={() => setModalCategory(false)}
                    error={createCategoryError}
                    createCategory={handleCreateCategory}
                    requesting={createCategoryRequesting}
                    sections={sections}
                />
                <EditCategory
                    visible={modalEditCategory}
                    close={() => setModalEditCategory(false)}
                    error={updateCategoryError}
                    update={handleUpdateCategory}
                    requesting={updateCategoryRequesting}
                    focus={focusCategory}
                    sections={sections}
                />
                <ToastContainer autoClose={3000} />
            </div>
        </div>
    )
}
