export const GET_PROVIDERS_ATTEMPT = 'GET_PROVIDERS_ATTEMPT';
export const GET_PROVIDERS_SUCCESS = 'GET_PROVIDERS_SUCCESS';
export const GET_PROVIDERS_FAILURE = 'GET_PROVIDERS_FAILURE';

export const CREATE_PROVIDER_ATTEMPT = 'CREATE_PROVIDER_ATTEMPT';
export const CREATE_PROVIDER_SUCCESS = 'CREATE_PROVIDER_SUCCESS';
export const CREATE_PROVIDER_FAILURE = 'CREATE_PROVIDER_FAILURE';

export const UPDATE_PROVIDER_ATTEMPT = 'UPDATE_PROVIDER_ATTEMPT';
export const UPDATE_PROVIDER_SUCCESS = 'UPDATE_PROVIDER_SUCCESS';
export const UPDATE_PROVIDER_FAILURE = 'UPDATE_PROVIDER_FAILURE';

export const CLEAN_PROVIDER = 'CLEAN_PROVIDER';
