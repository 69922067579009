import React from 'react';
import { NumericFormat } from 'react-number-format';

export const FormatNumber = ({...props}) => (
    <NumericFormat
        {...props}
        prefix={'$ '}
        thousandSeparator=","
    />
)
