import {
    CHANGE_STATE_ORDER_ATTEMPT, CHANGE_STATE_ORDER_FAILURE, CHANGE_STATE_ORDER_SUCCESS,
    CLEAN_ORDER,
    GET_CART_PRODUCTS_ATTEMPT, GET_CART_PRODUCTS_FAILURE, GET_CART_PRODUCTS_SUCCESS,
    GET_ORDERS_ATTEMPT,
    GET_ORDERS_FAILURE,
    GET_ORDERS_SUCCESS,
    REVERSE_ORDER_ATTEMPT, REVERSE_ORDER_FAILURE,
    REVERSE_ORDER_SUCCESS
} from "./constants";

export const getOrders = (token, page) => ({
    type: GET_ORDERS_ATTEMPT,
    token,
    page,
});

export const getOrdersSuccess = orders => ({
    type: GET_ORDERS_SUCCESS,
    orders,
});

export const getOrdersFailure = error => ({
    type: GET_ORDERS_FAILURE,
    error,
});

export const reverseOrder = (orderId, buyerId, values, token) => ({
    type: REVERSE_ORDER_ATTEMPT,
    orderId,
    buyerId,
    values,
    token,
});

export const reverseOrderSuccess = order => ({
    type: REVERSE_ORDER_SUCCESS,
    order,
});

export const reverseOrderFailure = error => ({
    type: REVERSE_ORDER_FAILURE,
    error,
});

export const getCartProducts = (token, id) => ({
    type: GET_CART_PRODUCTS_ATTEMPT,
    token,
    id,
});

export const getCartProductsSuccess = cart => ({
    type: GET_CART_PRODUCTS_SUCCESS,
    cart,
});

export const getCartProductsFailure = error => ({
    type: GET_CART_PRODUCTS_FAILURE,
    error,
});

export const changeStateOrder = (token, orderId) => ({
    type: CHANGE_STATE_ORDER_ATTEMPT,
    token,
    orderId,
});

export const changeStateOrderSuccess = order => ({
    type: CHANGE_STATE_ORDER_SUCCESS,
    order
});

export const changeStateOrderFailure = error => ({
    type: CHANGE_STATE_ORDER_FAILURE,
    error,
});

export const cleanOrder = () => ({
    type: CLEAN_ORDER,
});
