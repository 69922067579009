export const GET_SECTIONS_ATTEMPT = 'GET_SECTIONS_ATTEMPT';
export const GET_SECTIONS_SUCCESS = 'GET_SECTIONS_SUCCESS';
export const GET_SECTIONS_FAILURE = 'GET_SECTIONS_FAILURE';

export const CREATE_SECTION_ATTEMPT = 'CREATE_SECTION_ATTEMPT';
export const CREATE_SECTION_SUCCESS = 'CREATE_SECTION_SUCCESS';
export const CREATE_SECTION_FAILURE = 'CREATE_SECTION_FAILURE';

export const UPDATE_SECTION_ATTEMPT = 'UPDATE_SECTION_ATTEMPT';
export const UPDATE_SECTION_SUCCESS = 'UPDATE_SECTION_SUCCESS';
export const UPDATE_SECTION_FAILURE = 'UPDATE_SECTION_FAILURE';

export const UPDATE_ACTIVE_SECTION_ATTEMPT = 'UPDATE_ACTIVE_SECTION_ATTEMPT';
export const UPDATE_ACTIVE_SECTION_SUCCESS = 'UPDATE_ACTIVE_SECTION_SUCCESS';
export const UPDATE_ACTIVE_SECTION_FAILURE = 'UPDATE_ACTIVE_SECTION_FAILURE';

export const CLEAN_SECTION = 'CLEAN_SECTION';
