import {
    ADD_PACKING_PRODUCTS_ATTEMPT, ADD_PACKING_PRODUCTS_FAILURE, ADD_PACKING_PRODUCTS_SUCCESS,
    ADD_STOCK_PRODUCT_ATTEMPT,
    ADD_STOCK_PRODUCT_FAILURE,
    ADD_STOCK_PRODUCT_SUCCESS,
    CHANGE_STATE_PRODUCT_ATTEMPT,
    CHANGE_STATE_PRODUCT_FAILURE,
    CHANGE_STATE_PRODUCT_SUCCESS,
    CLEAN_ERROR_PRODUCT_REFERENCE,
    CLEAN_PRODUCT, CLEAN_SEARCH,
    CREATE_PRODUCT_ATTEMPT,
    CREATE_PRODUCT_FAILURE,
    CREATE_PRODUCT_REFERENCES_ATTEMPT,
    CREATE_PRODUCT_REFERENCES_FAILURE,
    CREATE_PRODUCT_REFERENCES_SUCCESS,
    CREATE_PRODUCT_SUCCESS, GET_PRODUCT,
    GET_PRODUCTS_ATTEMPT,
    GET_PRODUCTS_FAILURE,
    GET_PRODUCTS_SUCCESS, SEARCH_PRODUCTS_ATTEMPT, SEARCH_PRODUCTS_FAILURE, SEARCH_PRODUCTS_SUCCESS,
    UPDATE_IMAGE_ATTEMPT,
    UPDATE_IMAGE_FAILURE,
    UPDATE_IMAGE_SUCCESS,
    UPDATE_PRODUCT_ATTEMPT,
    UPDATE_PRODUCT_FAILURE,
    UPDATE_PRODUCT_REFERENCES_ATTEMPT,
    UPDATE_PRODUCT_REFERENCES_FAILURE,
    UPDATE_PRODUCT_REFERENCES_SUCCESS,
    UPDATE_PRODUCT_SUCCESS
} from "./constants";

const initialState = {
    requestingCreate: false,
    successCreate: false,
    errorCreate: '',
    requestingProducts: false,
    successProducts: false,
    errorProducts: '',
    products: [],
    linkProducts: {},
    metaProducts: {},
    pageProducts: 0,
    changeStateRequesting: false,
    changeStateSuccess: false,
    changeStateFailure: '',
    createReferenceRequesting: false,
    createReferenceSuccess: false,
    createReferenceError: '',
    updateReferenceRequesting: false,
    updateReferenceSuccess: false,
    updateReferenceError: '',
    addStockProductRequesting: false,
    addStockProductSuccess: false,
    addStockProductFailure: '',
    updateProductRequesting: false,
    updateProductSuccess: false,
    updateProductError: '',
    updateImageProductRequesting: false,
    updateImageProductSuccess: false,
    updateImageProductError: '',
    addPackingProductRequesting: false,
    addPackingProductSuccess: false,
    addPackingProductError: '',
    searchProductsRequesting: false,
    searchProductsSuccess: false,
    searchProductsError: '',
    pageSearchProducts: 0,
    productsSearch: [],
    linkSearchProducts: {},
    metaSearchProducts: {},
}

const reducer = (state = initialState, action) => {
    switch (action.type){
        case CREATE_PRODUCT_ATTEMPT:
            return {
                ...state,
                requestingCreate: true,
                successCreate: false,
                createReferenceSuccess: false,
                updateReferenceSuccess: false,
                addStockProductSuccess: false,
                updateProductSuccess: false,
                updateImageProductSuccess: false,
                addPackingProductSuccess: false,
            };
        case CREATE_PRODUCT_SUCCESS:
            return {
                ...state,
                requestingCreate: false,
                successCreate: true,
                products: [action.product, ...state.products],
            };
        case CREATE_PRODUCT_FAILURE:
            return {
                ...state,
                requestingCreate: false,
                errorCreate: action.error,
            };
        case GET_PRODUCTS_ATTEMPT:
            return {
                ...state,
                requestingProducts: true,
                pageProducts: action.page,
            };
        case GET_PRODUCTS_SUCCESS:
            return {
                ...state,
                requestingProducts: false,
                successProducts: true,
                products: action.products.data,
                linkProducts: action.products.links,
                metaProducts: action.products.meta,
            };
        case GET_PRODUCTS_FAILURE:
            return {
                ...state,
                requestingProducts: false,
                errorProducts: action.error,
            };
        case CHANGE_STATE_PRODUCT_ATTEMPT:
            return {
                ...state,
                changeStateRequesting: true,
                changeStateSuccess: false,
            };
        case CHANGE_STATE_PRODUCT_SUCCESS:
            return {
                ...state,
                changeStateRequesting: false,
                changeStateSuccess: true,
                products: state.products.map(product => (
                    product.id === action.product.id ? action.product : product
                ))
            };
        case CHANGE_STATE_PRODUCT_FAILURE:
            return {
                ...state,
                changeStateRequesting: false,
                changeStateFailure: action.error,
            };
        case CREATE_PRODUCT_REFERENCES_ATTEMPT:
            return {
                ...state,
                createReferenceRequesting: true,
                createReferenceSuccess: false,
                updateReferenceSuccess: false,
                successCreate: false,
                addStockProductSuccess: false,
                updateProductSuccess: false,
                updateImageProductSuccess: false,
                addPackingProductSuccess: false,
            };
        case CREATE_PRODUCT_REFERENCES_SUCCESS:
            return {
                ...state,
                createReferenceRequesting: false,
                createReferenceSuccess: true,
                products: state.products.map(product => (
                    product.id === action.product.id ? action.product : product
                ))
            };
        case CREATE_PRODUCT_REFERENCES_FAILURE:
            return {
                ...state,
                createReferenceRequesting: false,
                createReferenceError: action.error,
            };
        case UPDATE_PRODUCT_REFERENCES_ATTEMPT:
            return {
                ...state,
                updateReferenceRequesting: true,
                updateReferenceSuccess: false,
                createReferenceSuccess: false,
                successCreate: false,
                addStockProductSuccess: false,
                updateProductSuccess: false,
                updateImageProductSuccess: false,
                addPackingProductSuccess: false,
            };
        case UPDATE_PRODUCT_REFERENCES_SUCCESS:
            return {
                ...state,
                updateReferenceRequesting: false,
                updateReferenceSuccess: true,
                products: state.products.map(product => (
                    product.id === action.product.id ? action.product : product
                ))
            };
        case UPDATE_PRODUCT_REFERENCES_FAILURE:
            return {
                ...state,
                updateReferenceRequesting: false,
                updateReferenceError: action.error,
            };
        case CLEAN_ERROR_PRODUCT_REFERENCE:
            return {
                ...state,
                updateReferenceError: '',
            };
        case ADD_STOCK_PRODUCT_ATTEMPT:
            return {
                ...state,
                addStockProductRequesting: true,
                addStockProductSuccess: false,
                updateReferenceSuccess: false,
                createReferenceSuccess: false,
                successCreate: false,
                updateProductSuccess: false,
                updateImageProductSuccess: false,
                addPackingProductSuccess: false,
            };
        case ADD_STOCK_PRODUCT_SUCCESS:
            return {
                ...state,
                addStockProductRequesting: false,
                addStockProductSuccess: true,
                products: state.products.map(product => (
                    product.id === action.product.id ? action.product : product
                ))
            };
        case ADD_STOCK_PRODUCT_FAILURE:
            return {
                ...state,
                addStockProductRequesting: false,
                addStockProductFailure: action.error,
            };
        case UPDATE_PRODUCT_ATTEMPT:
            return {
                ...state,
                updateProductRequesting: true,
                updateProductSuccess: false,
                addStockProductSuccess: false,
                updateReferenceSuccess: false,
                createReferenceSuccess: false,
                successCreate: false,
                updateImageProductSuccess: false,
                addPackingProductSuccess: false,
            };
        case UPDATE_PRODUCT_SUCCESS:
            return {
                ...state,
                updateProductRequesting: false,
                updateProductSuccess: true,
                products: state.products.map(product => (
                    product.id === action.product.id ? action.product : product
                ))
            };
        case UPDATE_PRODUCT_FAILURE:
            return {
                ...state,
                updateProductRequesting: false,
                updateProductError: action.error,
            };
        case UPDATE_IMAGE_ATTEMPT:
            return {
                ...state,
                updateImageProductRequesting: true,
                updateImageProductSuccess: false,
                updateProductSuccess: false,
                addStockProductSuccess: false,
                updateReferenceSuccess: false,
                createReferenceSuccess: false,
                successCreate: false,
                addPackingProductSuccess: false,
            };
        case UPDATE_IMAGE_SUCCESS:
            return {
                ...state,
                updateImageProductRequesting: false,
                updateImageProductSuccess: true,
                products: state.products.map(product => (
                    product.id === action.product.id ? action.product : product
                ))
            };
        case UPDATE_IMAGE_FAILURE:
            return {
                ...state,
                updateImageProductRequesting: false,
                updateImageProductError: action.error,
            };
        case ADD_PACKING_PRODUCTS_ATTEMPT:
            return {
                ...state,
                addPackingProductRequesting: true,
                addPackingProductSuccess: false,
                updateImageProductSuccess: false,
                updateProductSuccess: false,
                addStockProductSuccess: false,
                updateReferenceSuccess: false,
                createReferenceSuccess: false,
                successCreate: false,
            };
        case ADD_PACKING_PRODUCTS_SUCCESS:
            return {
                ...state,
                addPackingProductRequesting: false,
                addPackingProductSuccess: true,
                products: state.products.map(product => (
                    product.id === action.product.id ? action.product : product
                ))
            };
        case ADD_PACKING_PRODUCTS_FAILURE:
            return {
                ...state,
                addPackingProductRequesting: false,
                addStockProductFailure: action.error,
            };
        case GET_PRODUCT:
            return {
                ...state,
                products: state.products.map(product => (
                    product.id === action.product.id ? action.product : product
                ))
            };
        case SEARCH_PRODUCTS_ATTEMPT:
            return {
                ...state,
                searchProductsRequesting: true,
                pageSearchProducts: action.page,
            };
        case SEARCH_PRODUCTS_SUCCESS:
            return {
                ...state,
                searchProductsRequesting: false,
                searchProductsSuccess: true,
                productsSearch: action.products.data,
                linkSearchProducts: action.products.links,
                metaSearchProducts: action.products.meta,
            };
        case SEARCH_PRODUCTS_FAILURE:
            return {
                ...state,
                searchProductsRequesting: false,
                searchProductsError: action.error,
            };
        case CLEAN_SEARCH:
            return {
                ...state,
                searchProductsRequesting: false,
                searchProductsSuccess: false,
                searchProductsError: '',
                pageSearchProducts: 0,
                searchProducts: [],
                linkSearchProducts: {},
                metaSearchProducts: {},
            };
        case CLEAN_PRODUCT:
            return {
                ...state,
                requestingCreate: false,
                successCreate: false,
                errorCreate: '',
                requestingProducts: false,
                successProducts: false,
                errorProducts: '',
                products: [],
                linkProducts: {},
                metaProducts: {},
                pageProducts: 0,
                changeStateRequesting: false,
                changeStateSuccess: false,
                changeStateFailure: '',
                createReferenceRequesting: false,
                createReferenceSuccess: false,
                createReferenceError: '',
                updateReferenceRequesting: false,
                updateReferenceSuccess: false,
                updateReferenceError: '',
                addStockProductRequesting: false,
                addStockProductSuccess: false,
                addStockProductFailure: '',
                updateProductRequesting: false,
                updateProductSuccess: false,
                updateProductError: '',
                updateImageProductRequesting: false,
                updateImageProductSuccess: false,
                updateImageProductError: '',
                addPackingProductRequesting: false,
                addPackingProductSuccess: false,
                addPackingProductError: '',
                searchProductsRequesting: false,
                searchProductsSuccess: false,
                searchProductsError: '',
                pageSearchProducts: 0,
                productsSearch: [],
                linkSearchProducts: {},
                metaSearchProducts: {},
            };
        default:
            return state;
    }
}

export default reducer;
