import React from "react";
import Modal from 'react-bootstrap/Modal';
import {createProductReferences} from "../../redux/product/actions";
import {useDispatch, useSelector} from "react-redux";

export const AddReferences = ({
                                  visible,
                                  close,
                                  isAddedReference,
                                  references,
                                  handleAddReference,
                                  handle,
                                  focus,
}) => {

    const dispatch = useDispatch();

    const {token} = useSelector(state => state.auth);
    const {createReferenceRequesting} = useSelector(state => state.product);

    const handleCreateReferences = () => {
        let quantities = references.reduce((previous, current) => { return previous + parseInt(current.quantity)}, 0);
        let productReferences = references.map((reference) => {
            return {reference: reference.reference, referenceQuantity: reference.quantity}
        })
        let values = {
            references: productReferences,
            all_quantity: quantities || 0,
        };
        dispatch(createProductReferences(token, values, focus.id));
    };

    return (
        <Modal show={visible} onHide={close} style={{ display: 'block' }}>
            <div className="modal-content">
                <Modal.Header className="modal-header" closeButton>
                    <h5 className="modal-title  fw-bold" id="addProduct">{`Agregar referencias al producto ${focus.name}`}</h5>
                </Modal.Header>
                <Modal.Body className="modal-body">
                    {isAddedReference && (
                        <div >
                            {references.length > 0 && references.map((reference, index) => (
                               <div key={index}>
                                   <div className="mb-3 mt-1">
                                       <label className="mb-1 form-label" >{`Nombre de referencia ${index +1 }`}</label>
                                       <input type="text" className="form-control"
                                              placeholder="Referencia"
                                              name={'reference'}
                                              onChange={(value) => handle(index, `reference`, value)}
                                       />
                                   </div>
                                   <div className="mb-3 mt-1">
                                       <label className="mb-1 form-label">{`Cantidad de referencia ${index + 1}`}</label>
                                       <input type="text" className="form-control"
                                              placeholder="Cantidad"
                                              name={'quantity'}
                                              onChange={(value) => handle(index, `quantity`, value)}
                                       />
                                   </div>
                               </div>
                            ))}
                        </div>
                    )}
                    <div className="col-sm-12  d-flex justify-content-center">
                        <button type="button" onClick={handleAddReference}
                                className="btn btn-primary">Añadir otra referencia</button>
                    </div>
                </Modal.Body>
                <div className="modal-footer">
                    {!createReferenceRequesting && (
                        <button className="btn btn-secondary col-sm-5"
                                onClick={close}
                                data-bs-dismiss="modal">Cancelar</button>
                    )}
                    {createReferenceRequesting && (
                        <button className="btn btn-primary lift btn-spinner container-spinner col-sm-5">
                            <i className="fa fa-spinner fa-spin"></i>
                        </button>
                    )}
                    {!createReferenceRequesting && (
                        <button onClick={handleCreateReferences}
                                className="btn btn-primary col-sm-5">
                            Agregar referencias
                        </button>
                    )}
                </div>
            </div>
        </Modal>
    )
}
