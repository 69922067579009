import {
    CLEAN_ACCOUNT,
    GET_ACCOUNTS_ATTEMPT,
    GET_ACCOUNTS_FAILURE,
    GET_ACCOUNTS_SUCCESS,
    POST_SHOULD_ATTEMPT, POST_SHOULD_FAILURE,
    POST_SHOULD_SUCCESS
} from "./constants";

export const getAccounts = (token, page) => ({
    type: GET_ACCOUNTS_ATTEMPT,
    token,
    page,
});

export const getAccountsSuccess = accounts => ({
    type: GET_ACCOUNTS_SUCCESS,
    accounts,
});

export const getAccountsFailure = error => ({
    type: GET_ACCOUNTS_FAILURE,
    error,
});

export const postShould = (token, values) => ({
    type: POST_SHOULD_ATTEMPT,
    token,
    values,
});

export const postShouldSuccess = account => ({
    type: POST_SHOULD_SUCCESS,
    account,
});

export const postShouldFailure = error => ({
    type: POST_SHOULD_FAILURE,
    error,
});

export const cleanAccount = () => ({
    type: CLEAN_ACCOUNT,
});
