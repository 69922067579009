import React from "react";
import DataTable from "react-data-table-component";
import {FormatNumber} from "../../constants/FormatNumber";
import Switch from "react-switch";
import {PaginateComponent} from "../Paginate/Paginate";

export const TableProducts = ({
                                  products,
                                  metaProducts,
                                  pageCurrency,
                                  handleChangePage,
                                  openModal,
                                  setFocusProduct,
                                  changeState,
                                  handleAddReference,
}) => {
    const columns = [
        {
            name: 'Nombre',
            selector: row => row.name,
            center: true
        },
        {
            name: 'Sku',
            selector: row => row.sku,
            center: true
        },
        {
            name: 'Stock',
            selector: row => row.stock,
            center: true
        },
        {
            name: 'Precio de venta',
            selector: row => <FormatNumber value={row.sale_price}
                                           className={'numeric-input-disabled'}
                                           disabled={true}/>,
            center: true,
            width: '150px'
        },
        {
            name: 'Precio de compra',
            selector: row => <FormatNumber value={row.purchase_price}
                                           className={'numeric-input-disabled'}
                                           disabled={true}/>,
            center: true,
            width: '150px'
        },
        {
            name: 'Descripción',
            selector: row => row.description,
            center: true,
            width: '150px'
        },
        {
            name: 'Imagen',
            cell:row=><><img onClick={() => {
                openModal('viewPhotos')
                setFocusProduct(row)
            }}
                             className="avatar rounded lg border"
                             src={row.images.length > 0 ? row.images[0].url :
                                 'https://dlinkmea.com/images/no-product.png'}
                             alt="" /></>,
            center: true,
        },
        {
            name: 'Referencias',
            selector: row => <div >
                <button type="button"
                        onClick={() => {
                            openModal('addReferences')
                            setFocusProduct(row);
                            handleAddReference();
                        }}
                        className="btn btn-primary buttons">
                    Agregar Referencias</button>
                {row.references.length > 0 &&
                <button type="button"
                        onClick={() => {
                            openModal('viewReferences')
                            setFocusProduct(row);
                        }}
                        className="btn btn-second button-edit-references">
                    Ver Referencias</button>}
            </div>,
            width: '200px',
            center: true
        },
        {
            name: 'Empaques',
            selector: row =>  <div>
                {row.packing.length > 0 && (
                    <div>
                        {row.packing.map((data, index) => (
                            <p key={index} style={{marginBottom: 2}}>{`• ${data.label}`}</p>
                        ))}
                    </div>
                )}
                <button type="button"
                        onClick={() => {
                            openModal('addPacking')
                            setFocusProduct(row);
                        }}
                        className="btn btn-primary buttons">
                    Añadir empaques</button>
            </div>,
            width: '200px',
            center: true
        },
        {
            name: 'Proveedor',
            selector: row => row.provider.name,
            width: '150px',
            center: true
        },
        {
            name: 'Subcategoría',
            selector: row => row.sub_category.name,
            width: '150px',
            center: true
        },
        {
            name: 'Categoría',
            selector: row => row.category.name,
            width: '150px',
            center: true
        },
        {
            name: 'Sección',
            selector: row => row.section.name,
            width: '150px',
            center: true
        },
        {
            name: 'Estado',
            selector: row => <div className={'button-state'} style={{background: row.state.color}}>
                <div style={{color: 'white'}}>{row.state.name}</div>
            </div>,
            width: '200px',
            center: true
        },
        {
            name: 'Cambiar estado',
            selector: row => <>{(row.state.id === 3 || row.state.id === 13) &&
                <Switch
                    onChange={() => changeState(row.id)}
                    checked={row.state.id === 13}
                    uncheckedIcon={false}
                    checkedIcon={false}
                />
            }</>,
            width: '150px',
            center: true
        },
        {
            name: 'Acciones',
            selector: row => <div >
                <button type="button" className="btn button-table-account margin-buttons"
                        onClick={() => {
                            openModal('addStock');
                            setFocusProduct(row);
                        }}>
                    <i className="fa-solid fa-plus fa-lg icon-table" />
                </button>
                <button type="button" className="btn button-table-category margin-buttons"
                        onClick={() => {
                            openModal('editProduct');
                            setFocusProduct(row);
                        }}>
                    <i className="fa-solid fa-pen-to-square fa-lg icon-table" />
                </button>
                <button type="button" className="btn button-table-account margin-buttons"
                        onClick={() => {
                            openModal('editImage');
                            setFocusProduct(row);
                        }}>
                    <i className="fa-solid fa-image fa-lg icon-table" />
                </button>
                {row.stock > 0 && (
                    <button type="button" className="btn button-table-damaged"
                            onClick={() => {
                                openModal('addDamaged');
                                setFocusProduct(row);
                            }}>
                        <i className="fa-solid fa-screwdriver-wrench icon-table" />
                    </button>
                )}
                </div>,
            width: '400px',
            center: true
        },
    ];

    return (
        <div className="card mb-3">
            <DataTable
                title="Datatable Product"
                columns={columns}
                data={products}
                defaultSortField="title"
                pagination={false}
                selectableRows={false}
                className="table myDataTable table-hover align-middle mb-0 d-row nowrap dataTable no-footer dtr-inline"
                highlightOnHover={true}
                noDataComponent={'No hay productos en estos momentos'}
            />
            {metaProducts.total > 9 && (
                <PaginateComponent
                    pageCurrency={pageCurrency}
                    handleChangePage={handleChangePage}
                    meta={metaProducts}
                />
            )}
        </div>
    );
}
