import React, {useEffect, useState} from "react";
import {Sidebar} from "../../components/Sidebar/Sidebar";
import {Header} from "../../components/Header/Header";
import {useDispatch, useSelector} from "react-redux";
import {createSubcategory, getSubcategory, updateSubcategory} from "../../redux/subcategory/actions";
import {TableSubcategory} from "../../components/Subcategory/Table";
import {AddSubcategory} from "../../components/Subcategory/AddSubcategory";
import {getCategories} from "../../redux/category/actions";
import {toast, ToastContainer} from "react-toastify";
import {EditSubcategory} from "../../components/Subcategory/EditSubcategory";

export const Subcategory = () => {

    const dispatch = useDispatch();

    const {user, successMe, token, sidebarMini} = useSelector(state => state.auth);
    const {requestingSubcategory, subcategories, createSubcategoryError,
        createSubcategorySuccess, createSubcategoryRequesting,
        updateSubcategoryError, updateSubcategoryRequesting,
        updateSubcategorySuccess} = useSelector(state => state.subcategory);
    const {categories, getCategoriesSuccess} = useSelector(state => state.category);

    const [modalSubcategory, setModalSubcategory] = useState(false);
    const [modalEditSubcategory, setModalEditSubcategory] = useState(false);
    const [focusSubcategory, setFocusSubcategory] = useState({});

    const handlerCreateSubcategory = (values) => {
        let body = {
            name: values.name,
            category_id: values.category,
        }
        dispatch(createSubcategory(token, body));
    };

    const handleUpdateSubcategory = (values) => {
        let body = {
            '_method': 'PUT',
            id: focusSubcategory.id,
            name: values.name !== '' ? values.name : focusSubcategory.name,
            category_id: values.category !== 0 ? values.category : focusSubcategory.category.id,
        }
        dispatch(updateSubcategory(token, body));
    };


    useEffect(() => {
        if(!requestingSubcategory && successMe)
            dispatch(getSubcategory(token));
        if(!getCategoriesSuccess && successMe)
            dispatch(getCategories(token));
    }, []);

    useEffect(() => {
        if(createSubcategorySuccess){
            setModalSubcategory(false);
            toast("Subcategoría creada", {type: 'success'});
        }
        if(updateSubcategorySuccess){
            setModalEditSubcategory(false);
            toast("Subcategoría modificada", {type: 'success'});
        }
    }, [createSubcategorySuccess, updateSubcategorySuccess]);

    return (
        <div className={'d-flex'}>
            <Sidebar isSidebarMini={sidebarMini}
                     routeCurrency={'/subcategories'} />
            <div className='main px-lg-12 px-md-4' >
                <Header
                    search={false}
                    user={user}
                />
                <button
                    type="button"
                    className="btn btn-primary align-self-end"
                    onClick={() => setModalSubcategory(true)}
                >
                    Crear subcategoría
                </button>
                <div className={`${sidebarMini ? 'table-widthSideMini': 'table-widthSide'} body d-flex py-3`}>
                    <div className={'table-widthMini'}>
                        <TableSubcategory
                            subcategories={subcategories}
                            setModalEditSubcategory={setModalEditSubcategory}
                            setFocusSubcategory={setFocusSubcategory}
                        />
                    </div>
                </div>
                <AddSubcategory
                    visible={modalSubcategory}
                    close={() => setModalSubcategory(false)}
                    error={createSubcategoryError}
                    createSubcategory={handlerCreateSubcategory}
                    requesting={createSubcategoryRequesting}
                    categories={categories}
                />
                <EditSubcategory
                    visible={modalEditSubcategory}
                    close={() => setModalEditSubcategory(false)}
                    error={updateSubcategoryError}
                    update={handleUpdateSubcategory}
                    requesting={updateSubcategoryRequesting}
                    categories={categories}
                    focus={focusSubcategory}
                />
                <ToastContainer autoClose={3000} />
            </div>
        </div>
    )
}
