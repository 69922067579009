import {useMemo, useState} from 'react';

const mockData = {
    reference : '',
    quantity : ''
}

export const useReferenceProduct = () => {

    const [references, setReferences] = useState([]);

    const handleChangeFormReference = (position, key, value) => {
        const updateReferences = references.map((reference, index) => {
            if (index === position) {
                return {
                    ...reference,
                    [key]: value.target.value
                };
            }
            return reference;
        });
        setReferences(updateReferences);
    };

    const deleteReference = () => {
        if(references.length === 1){
            setReferences([])
        }else{
            let _ = require('lodash');
            let newReference = _.initial(references);
            setReferences(newReference);
        }
    };

    const isAddedReference = useMemo(() => references.length > 0,[references])

    const handleAddReference = () => {
        setReferences(current => [...current, mockData]);
    };

    const cleanReference = () => {
        setReferences([]);
    };

    return {references, handleChangeFormReference, isAddedReference, cleanReference,
        handleAddReference, deleteReference};
};
