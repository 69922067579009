import React from 'react';
import {paginateTables} from "../../hooks/General/Pagination";

export const PaginateComponent = ({
                                      pageCurrency,
                                      handleChangePage,
                                      meta,
}) => {

    const {previous, current, next} = paginateTables(pageCurrency);

    return(
        <div className="row g-3 mb-3 p-3">
            <div className="col-md-12">
                <nav className="justify-content-end d-flex">
                    <ul className="pagination">
                        <li className={`page-item ${pageCurrency === 1 ? 'disabled' : ''}`}>
                            <a className="page-link" onClick={() => handleChangePage(previous)}>{'<'}</a>
                        </li>
                        {meta.current_page === meta.last_page && (previous - 1) !== 0 && (
                            <li className={`page-item ${previous === 0 ? pageCurrency === 1 ? 'active' : pageCurrency === current ? 'active' : '' : ''}`}>
                                <a className="page-link" onClick={() => handleChangePage(previous - 1)}>{pageCurrency === 1 ? 1 : previous - 1}</a>
                            </li>
                        )}
                        <li className={`page-item ${previous === 0 ? pageCurrency === 1 ? 'active' : pageCurrency === current ? 'active' : '' : ''}`}>
                            <a className="page-link" onClick={() => handleChangePage(previous)}>{pageCurrency === 1 ? 1 : previous}</a>
                        </li>
                        {meta.last_page >= 2 && (
                            <li className={`page-item ${current === 1 ? '' : pageCurrency === current ? 'active' : ''}`}>
                                <a className="page-link" onClick={() => handleChangePage(pageCurrency === 1 ? 2 : current )}>{pageCurrency === 1 ? 2 : current}</a>
                            </li>
                        )}
                        {meta.last_page >= 3 && next <= meta.last_page && (
                            <li className={`page-item `} >
                                <a className="page-link" onClick={() => handleChangePage(pageCurrency === 1 ? 3 : next)}>{pageCurrency === 1 ? 3 : next}</a>
                            </li>
                        )}
                        <li className={`page-item ${pageCurrency === meta.last_page ? 'disabled' : ''}`}>
                            <a className="page-link" onClick={() => handleChangePage(next)}> > </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    )
}
