export const GET_ORDERS_ATTEMPT = 'GET_ORDERS_ATTEMPT';
export const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS';
export const GET_ORDERS_FAILURE = 'GET_ORDERS_FAILURE';

export const REVERSE_ORDER_ATTEMPT = 'REVERSE_ORDER_ATTEMPT';
export const REVERSE_ORDER_SUCCESS = 'REVERSE_ORDER_SUCCESS';
export const REVERSE_ORDER_FAILURE = 'REVERSE_ORDER_FAILURE';

export const GET_CART_PRODUCTS_ATTEMPT = 'GET_CART_PRODUCTS_ATTEMPT';
export const GET_CART_PRODUCTS_SUCCESS = 'GET_CART_PRODUCTS_SUCCESS';
export const GET_CART_PRODUCTS_FAILURE = 'GET_CART_PRODUCTS_FAILURE';

export const CHANGE_STATE_ORDER_ATTEMPT = 'CHANGE_STATE_ORDER_ATTEMPT';
export const CHANGE_STATE_ORDER_SUCCESS = 'CHANGE_STATE_ORDER_SUCCESS';
export const CHANGE_STATE_ORDER_FAILURE = 'CHANGE_STATE_ORDER_FAILURE';

export const CLEAN_ORDER = 'CLEAN_ORDER';
