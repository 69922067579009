import React, {useEffect, useState} from "react";
import {Sidebar} from "../../components/Sidebar/Sidebar";
import {Header} from "../../components/Header/Header";
import {useDispatch, useSelector} from "react-redux";
import {TablePayment} from "../../components/Payment/Table";
import {createPayment, getAllPayments, updatePayment} from "../../redux/payment/actions";
import {AddPayment} from "../../components/Payment/AddPayment";
import {EditPayment} from "../../components/Payment/EditPayment";
import {toast, ToastContainer} from "react-toastify";

export const Payment = () => {

    const dispatch = useDispatch();

    const {user, successMe, token, sidebarMini} = useSelector(state => state.auth);
    const {allPayments, getAllPaymentsSuccess, createPaymentRequesting,
        createPaymentSuccess, createPaymentError, updatePaymentError,
        updatePaymentRequesting, updatePaymentSuccess} = useSelector(state => state.payment);

    const [modalPayment, setModalPayment] = useState(false);
    const [modalEditPayment, setModalEditPayment] = useState(false);
    const [focusPayment, setFocusPayment] = useState({});

    const handlerCreatePayment = values => {
        let body = {
            name: values.name
        }
        dispatch(createPayment(token, body));
    }

    const handlerUpdatePayment = values => {
        let body = {
            '_method': 'PUT',
            id: focusPayment.id,
            name: values.name !== '' ? values.name : focusPayment.name,
        }
        dispatch(updatePayment(token, body));
    }

    useEffect(() => {
        if(!getAllPaymentsSuccess && successMe)
            dispatch(getAllPayments())
    }, []);

    useEffect(() => {
        if(createPaymentSuccess){
            setModalPayment(false);
            toast("Método de pago creado", {type: 'success'});
        }
        if(updatePaymentSuccess){
            setModalEditPayment(false);
            toast("Método de pago modificado", {type: 'success'});
        }
    }, [createPaymentSuccess, updatePaymentSuccess]);

    return (
        <div className={'d-flex'}>
            <Sidebar isSidebarMini={sidebarMini}
                     routeCurrency={'/payments'} />
            <div className='main px-lg-12 px-md-4' >
                <Header
                    search={false}
                    user={user}
                />
                <button
                    type="button"
                    className="btn btn-primary align-self-end"
                    onClick={() => setModalPayment(true)}
                >
                    Crear método de pago
                </button>
                <div className={`${sidebarMini ? 'table-widthSideMini': 'table-widthSide'} body d-flex py-3`}>
                    <div className={'table-widthMiniCategory'}>
                        <TablePayment
                            payments={allPayments}
                            setModalEditPayment={setModalEditPayment}
                            setFocusPayment={setFocusPayment}
                        />
                    </div>
                </div>
                <AddPayment
                    visible={modalPayment}
                    close={() => setModalPayment(false)}
                    createPayment={handlerCreatePayment}
                    error={createPaymentError}
                    requesting={createPaymentRequesting}
                />
                <EditPayment
                    visible={modalEditPayment}
                    close={() => setModalEditPayment(false)}
                    error={updatePaymentError}
                    update={handlerUpdatePayment}
                    requesting={updatePaymentRequesting}
                    focus={focusPayment}
                />
                <ToastContainer autoClose={3000} />
            </div>
        </div>
    )
}
