import {
    CHANGE_STATE_ORDER_ATTEMPT, CHANGE_STATE_ORDER_FAILURE, CHANGE_STATE_ORDER_SUCCESS,
    CLEAN_ORDER,
    GET_CART_PRODUCTS_ATTEMPT, GET_CART_PRODUCTS_FAILURE, GET_CART_PRODUCTS_SUCCESS,
    GET_ORDERS_ATTEMPT,
    GET_ORDERS_FAILURE,
    GET_ORDERS_SUCCESS,
    REVERSE_ORDER_ATTEMPT, REVERSE_ORDER_FAILURE,
    REVERSE_ORDER_SUCCESS
} from "./constants";

const initialState = {
    getOrderRequesting: false,
    getOrderSuccess: false,
    getOrderError: '',
    orders: [],
    linkOrders: {},
    metaOrders: {},
    pageOrders: 0,
    reverseRequesting: false,
    reverseSuccess: false,
    reverseError: '',
    getProductsRequesting: false,
    getProductsSuccess: false,
    getProductsError: '',
    changeStateRequesting: false,
    changeStateSuccess: false,
    changeStateFailure: '',
    cart: {}
}

const reducer = (state = initialState, action) => {
    switch (action.type){
        case GET_ORDERS_ATTEMPT:
            return {
                ...state,
                getOrderRequesting: true,
                pageOrders: action.page
            };
        case GET_ORDERS_SUCCESS:
            return {
                ...state,
                getOrderRequesting: false,
                getOrderSuccess: true,
                orders: action.orders.data,
                linkOrders: action.orders.links,
                metaOrders: action.orders.meta,
            };
        case GET_ORDERS_FAILURE:
            return {
                ...state,
                getOrderRequesting: false,
                getOrderError: action.error,
            };
        case REVERSE_ORDER_ATTEMPT:
            return {
                ...state,
                reverseRequesting: true,
                reverseSuccess: false,
            };
        case REVERSE_ORDER_SUCCESS:
            return {
                ...state,
                reverseRequesting: false,
                reverseSuccess: true,
                orders: state.orders.map(order => (
                    order.id === action.order.id ? action.order : order
                )),
            };
        case REVERSE_ORDER_FAILURE:
            return {
                ...state,
                reverseRequesting: false,
                reverseError: action.error,
            };
        case GET_CART_PRODUCTS_ATTEMPT:
            return {
                ...state,
                getProductsRequesting: true,
                getProductsSuccess: false,
            };
        case GET_CART_PRODUCTS_SUCCESS:
            return {
                ...state,
                getProductsRequesting: false,
                getProductsSuccess: true,
                cart: action.cart
            };
        case GET_CART_PRODUCTS_FAILURE:
            return {
                ...state,
                getProductsRequesting: false,
                getProductsError: action.error,
            };
        case CHANGE_STATE_ORDER_ATTEMPT:
            return {
                ...state,
                changeStateRequesting: true,
                changeStateSuccess: false,
            };
        case CHANGE_STATE_ORDER_SUCCESS:
            return {
                ...state,
                changeStateRequesting: false,
                changeStateSuccess: true,
                orders: state.orders.map(order => (
                    order.id === action.order.id ? action.order : order
                )),
            };
        case CHANGE_STATE_ORDER_FAILURE:
            return {
                ...state,
                changeStateRequesting: false,
                changeStateFailure: action.error,
            };
        case CLEAN_ORDER:
            return {
                ...state,
                getOrderRequesting: false,
                getOrderSuccess: false,
                getOrderError: '',
                orders: [],
                linkOrders: {},
                metaOrders: {},
                pageOrders: 0,
                reverseRequesting: false,
                reverseSuccess: false,
                reverseError: '',
                getProductsRequesting: false,
                getProductsSuccess: false,
                getProductsError: '',
                changeStateRequesting: false,
                changeStateSuccess: false,
                changeStateFailure: '',
                cart: {}
            };
        default:
            return state;
    }
}

export default reducer;
