import React, {useEffect} from 'react';
import {Sidebar} from "../../components/Sidebar/Sidebar";
import {Header} from "../../components/Header/Header";
import {useDispatch, useSelector} from "react-redux";
import {getBuyers} from "../../redux/buyer/actions";
import {TableBuyer} from "../../components/Buyer/Table";


export const Buyer = () => {

    const dispatch = useDispatch();

    const {user, successMe, token, sidebarMini} = useSelector(state => state.auth);
    const {getBuyersSuccess, buyers, metaBuyers, pageBuyers} = useSelector(state => state.buyer);

    const handleChangePage = (page) => {
        if(page !== pageBuyers && page !== 0){
            dispatch(getBuyers(token, page));
        }
    }

    useEffect(() => {
        if(!getBuyersSuccess && successMe)
            dispatch(getBuyers(token, 1));
    }, []);

    return (
        <div className={'d-flex'}>
            <Sidebar isSidebarMini={sidebarMini}
                     routeCurrency={'/buyers'} />
            <div className='main px-lg-12 px-md-4' >
                <Header user={user}/>
                <div className={`${sidebarMini ? 'table-widthSideMini': 'table-widthSide'} body d-flex py-3`}>
                    <div className={'table-widthMini'}>
                        <TableBuyer
                            buyers={buyers}
                            metaBuyers={metaBuyers}
                            pageCurrency={pageBuyers}
                            handleChangePage={handleChangePage}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
