import React from 'react';
import {Formik} from "formik";
import Images from "../../assets/images/forgot-password.svg";
import {Link} from "react-router-dom";

export const VerifyToken = ({
                                            handle,
                                            error,
                                            requesting,
                                            message,
                                        }) => {

    return (
        <div>
            <Formik
                initialValues={{ token: ''}}
                onSubmit={(values ) => {
                    handle(values)
                }}
            >
                {({
                      handleChange,
                      handleSubmit,

                  }) => (
                    <form className="row g-1 p-3 p-md-4" onSubmit={handleSubmit}>
                        <div className="col-12 text-center mb-1">
                            <img src={Images} className="w240 mb-4" alt="" />
                        </div>
                        <div className="text-center mb-4">
                            <span>Valida el código que te fue enviado.</span>
                        </div>
                        {error?.message && (
                            <div className='text-danger fs-12'>
                                {error.message}
                            </div>
                        )}
                        {message !== '' && (
                            <div className='text-success fs-12 mb-3'>
                                {message}
                            </div>
                        )}
                        <div className="col-12">
                            <div className="mb-2">
                                <label className="form-label">Token</label>
                                <input type="text"
                                       className="form-control"
                                       placeholder="token"
                                       name={'token'}
                                       autoComplete={"off"}
                                       onChange={handleChange}
                                />
                            </div>
                            {error.token && <div className="text-danger fs-12">{error.token}</div>}
                        </div>
                        <div className="col-12 text-center mt-4">
                            {requesting && (
                                <button className="btn btn-primary lift btn-spinner">
                                    <i className="fa fa-spinner fa-spin"></i>
                                </button>
                            )}
                            {!requesting && (
                                <button type={"submit"} className="btn btn-primary btn-lg lift">
                                    Validar Token
                                </button>
                            )}
                        </div>
                        <div className="col-12 text-center mt-4">
                            <Link to={'/'} className="text-secondary">Volver</Link>
                        </div>
                    </form>
                )}
            </Formik>
        </div>
    )
};
