import {getMe} from "../redux/auth/actions";

export const userAuthenticated = (dispatch, navigate, errorMe = '', successMe = false, route, routeCurrency = '/') => {
    const token = localStorage.getItem('@thivisha');
    if(successMe){
        navigate(route)
    }else {
        if(token !== null && errorMe === ''){
            dispatch(getMe(token));
        } else {
            navigate(routeCurrency);
            localStorage.removeItem('@thivisha');
            localStorage.removeItem('@thivisha_date');
            localStorage.removeItem('@thivisha_dashboard');
        }
    }
}
