export const GET_SUBCATEGORY_ATTEMPT = 'GET_SUBCATEGORY_ATTEMPT';
export const GET_SUBCATEGORY_SUCCESS = 'GET_SUBCATEGORY_SUCCESS';
export const GET_SUBCATEGORY_FAILURE = 'GET_SUBCATEGORY_FAILURE';

export const CREATE_SUBCATEGORY_ATTEMPT = 'CREATE_SUBCATEGORY_ATTEMPT';
export const CREATE_SUBCATEGORY_SUCCESS = 'CREATE_SUBCATEGORY_SUCCESS';
export const CREATE_SUBCATEGORY_FAILURE = 'CREATE_SUBCATEGORY_FAILURE';

export const UPDATE_SUBCATEGORY_ATTEMPT = 'UPDATE_SUBCATEGORY_ATTEMPT';
export const UPDATE_SUBCATEGORY_SUCCESS = 'UPDATE_SUBCATEGORY_SUCCESS';
export const UPDATE_SUBCATEGORY_FAILURE = 'UPDATE_SUBCATEGORY_FAILURE';

export const CLEAN_SUBCATEGORY = 'CLEAN_SUBCATEGORY';
