export const GET_CATEGORIES_ATTEMPT= 'GET_CATEGORIES_ATTEMPT';
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_FAILURE = 'GET_CATEGORIES_FAILURE';

export const CREATE_CATEGORY_ATTEMPT = 'CREATE_CATEGORY_ATTEMPT';
export const CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS';
export const CREATE_CATEGORY_FAILURE = 'CREATE_CATEGORY_FAILURE';

export const UPDATE_CATEGORY_ATTEMPT = 'UPDATE_CATEGORY_ATTEMPT';
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_FAILURE = 'UPDATE_CATEGORY_FAILURE';

export const UPDATE_ACTIVE_CATEGORY_ATTEMPT = 'UPDATE_ACTIVE_CATEGORY_ATTEMPT';
export const UPDATE_ACTIVE_CATEGORY_SUCCESS = 'UPDATE_ACTIVE_CATEGORY_SUCCESS';
export const UPDATE_ACTIVE_CATEGORY_FAILURE = 'UPDATE_ACTIVE_CATEGORY_FAILURE';

export const CLEAN_CATEGORY = 'CLEAN_CATEGORY';
