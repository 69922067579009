import {
    ADD_STOCK_PACKING_ATTEMPT,
    ADD_STOCK_PACKING_FAILURE,
    ADD_STOCK_PACKING_SUCCESS, ALL_GET_PACKING_ATTEMPT, ALL_GET_PACKING_FAILURE, ALL_GET_PACKING_SUCCESS,
    CLEAN_PACKING,
    CREATE_PACKING_ATTEMPT,
    CREATE_PACKING_FAILURE,
    CREATE_PACKING_SUCCESS, GET_PACK,
    GET_PACKING_ATTEMPT,
    GET_PACKING_FAILURE,
    GET_PACKING_SUCCESS,
    UPDATE_IMAGE_PACKING_ATTEMPT, UPDATE_IMAGE_PACKING_FAILURE, UPDATE_IMAGE_PACKING_SUCCESS,
    UPDATE_PACKING_ATTEMPT,
    UPDATE_PACKING_FAILURE,
    UPDATE_PACKING_SUCCESS
} from "./constants";

export const getPacking = (token, page) => ({
    type: GET_PACKING_ATTEMPT,
    token,
    page,
});

export const getPackingSuccess = packing => ({
    type: GET_PACKING_SUCCESS,
    packing
});

export const getPackingFailure = error => ({
    type: GET_PACKING_FAILURE,
    error,
});

export const createPacking = (token, values, photo) => ({
    type: CREATE_PACKING_ATTEMPT,
    token,
    values,
    photo
});

export const createPackingSuccess = packing => ({
    type: CREATE_PACKING_SUCCESS,
    packing
});

export const createPackingFailure = error => ({
    type: CREATE_PACKING_FAILURE,
    error
});

export const updatePacking = (token, values, focusPacking) =>({
    type: UPDATE_PACKING_ATTEMPT,
    token,
    values,
    focusPacking,
});

export const updatePackingSuccess = packing => ({
    type: UPDATE_PACKING_SUCCESS,
    packing,
});

export const updatePackingFailure = error => ({
    type: UPDATE_PACKING_FAILURE,
    error,
});

export const addStockPacking = (token, values) => ({
    type: ADD_STOCK_PACKING_ATTEMPT,
    token,
    values,
});

export const addStockPackingSuccess = packing => ({
    type: ADD_STOCK_PACKING_SUCCESS,
    packing,
});

export const addStockPackingFailure = error => ({
    type: ADD_STOCK_PACKING_FAILURE,
    error,
});

export const updateImagePacking = (token, values) => ({
    type: UPDATE_IMAGE_PACKING_ATTEMPT,
    token,
    values,
});

export const updateImagePackingSuccess = packing => ({
    type: UPDATE_IMAGE_PACKING_SUCCESS,
    packing,
});

export const updateImagePackingFailure = error => ({
    type: UPDATE_IMAGE_PACKING_FAILURE,
    error,
});

export const allGetPacking = token => ({
    type: ALL_GET_PACKING_ATTEMPT,
    token,
});

export const allGetPackingSuccess = allPacking => ({
    type: ALL_GET_PACKING_SUCCESS,
    allPacking,
});

export const allGetPackingFailure = error => ({
    type: ALL_GET_PACKING_FAILURE,
    error,
});

export const getPack = pack => ({
    type: GET_PACK,
    pack,
});

export const cleanPacking = () => ({
    type: CLEAN_PACKING,
});
