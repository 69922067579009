import {
    CACHE_DASHBOARD,
    CLEAN_DASHBOARD,
    GET_DASHBOARD_ATTEMPT,
    GET_DASHBOARD_FAILURE,
    GET_DASHBOARD_SUCCESS
} from "./constants";

const initialState = {
    getDashboardRequesting: false,
    getDashboardSuccess: false,
    getDashboardError: '',
    dashboardHeader: [],
    dashboardCard: [],
}

const reducer = (state = initialState, action) => {
    switch (action.type){
        case GET_DASHBOARD_ATTEMPT:
            return {
                ...state,
                getDashboardRequesting: true
            };
        case GET_DASHBOARD_SUCCESS:
            return {
                ...state,
                getDashboardRequesting: false,
                getDashboardSuccess: true,
                dashboardHeader: [
                    {title: 'Ingresos', value: action.dashboard.income, icon: 'fa-dollar', color: 'bg-success', background: 'alert-success', price: true},
                    {title: 'Pendientes', value: action.dashboard.should, icon: 'fa-hand-holding-dollar', color: 'bg-info', background: 'alert-info',  price: true},
                    {title: 'Compradores', value: action.dashboard.buyers, icon: 'fa-users', color: 'bg-warning', background: 'alert-warning',  price: false}
                ],
                dashboardCard: [
                    {title: 'Ordenes finalizadas', value: action.dashboard.orders_finish, icon: 'icofont-shopping-cart', colorIcon: 'color-light-success'},
                    {title: 'Ordenes en proceso', value: action.dashboard.orders_process, icon: 'icofont-shopping-cart', colorIcon: 'color-info-light'},
                    {title: 'Ordenes canceladas', value: action.dashboard.orders_cancel, icon: 'icofont-shopping-cart', colorIcon: 'color-alert'},
                    {title: 'Productos en stock', value: action.dashboard.products, icon: 'icofont-truck-loaded', colorIcon: 'color-light-success'},
                    {title: 'Productos agotados', value: action.dashboard.products_out, icon: 'icofont-truck-loaded', colorIcon: 'color-info-light'},
                    {title: 'Productos averiados', value: action.dashboard.products_damaged, icon: 'icofont-truck-loaded', colorIcon: 'color-alert'},
                    {title: 'Empaques en stock', value: action.dashboard.packing, icon: 'icofont-package', colorIcon: 'color-light-success'},
                    {title: 'Empaques agotados', value: action.dashboard.packing_out, icon: 'icofont-package', colorIcon: 'color-info-light'},
                    {title: 'Empaques averiados', value: action.dashboard.packing_damaged, icon: 'icofont-package', colorIcon: 'color-alert'},
                    {title: 'Comprador con más ordenes', value: action.dashboard.more_orders.buyer_orders, secondValue: action.dashboard.more_orders.more, icon: 'icofont-student-alt', colorIcon: 'color-light-success', price: false},
                    {title: 'Comprador que más ha gastado', value: action.dashboard.more_payment.buyer_payments,  secondValue: action.dashboard.more_payment.more_payments, icon: 'icofont-student-alt', colorIcon: 'color-info-light', price: true},
                    {title: 'Comprador que más deuda', value: action.dashboard.more_should.buyer_should,  secondValue: action.dashboard.more_should.more_should, icon: 'icofont-student-alt', colorIcon: 'color-alert', price: true},
                ]
            };
        case GET_DASHBOARD_FAILURE:
            return {
                ...state,
                getDashboardRequesting: false
            };
        case CACHE_DASHBOARD:
            return {
                ...state,
                dashboardHeader: [
                    {title: 'Ingresos', value: action.dashboard.income, icon: 'fa-dollar', color: 'bg-success', background: 'alert-success', price: true},
                    {title: 'Pendientes', value: action.dashboard.should, icon: 'fa-hand-holding-dollar', color: 'bg-info', background: 'alert-info',  price: true},
                    {title: 'Compradores', value: action.dashboard.buyers, icon: 'fa-users', color: 'bg-warning', background: 'alert-warning',  price: false}
                ],
                dashboardCard: [
                    {title: 'Ordenes finalizadas', value: action.dashboard.orders_finish, icon: 'icofont-shopping-cart', colorIcon: 'color-light-success'},
                    {title: 'Ordenes en proceso', value: action.dashboard.orders_process, icon: 'icofont-shopping-cart', colorIcon: 'color-info-light'},
                    {title: 'Ordenes canceladas', value: action.dashboard.orders_cancel, icon: 'icofont-shopping-cart', colorIcon: 'color-alert'},
                    {title: 'Productos en stock', value: action.dashboard.products, icon: 'icofont-truck-loaded', colorIcon: 'color-light-success'},
                    {title: 'Productos agotados', value: action.dashboard.products_out, icon: 'icofont-truck-loaded', colorIcon: 'color-info-light'},
                    {title: 'Productos averiados', value: action.dashboard.products_damaged, icon: 'icofont-truck-loaded', colorIcon: 'color-alert'},
                    {title: 'Empaques en stock', value: action.dashboard.packing, icon: 'icofont-package', colorIcon: 'color-light-success'},
                    {title: 'Empaques agotados', value: action.dashboard.packing_out, icon: 'icofont-package', colorIcon: 'color-info-light'},
                    {title: 'Empaques averiados', value: action.dashboard.packing_damaged, icon: 'icofont-package', colorIcon: 'color-alert'},
                    {title: 'Comprador con más ordenes', value: action.dashboard.more_orders.buyer_orders, secondValue: action.dashboard.more_orders.more, icon: 'icofont-student-alt', colorIcon: 'color-light-success', price: false},
                    {title: 'Comprador que más ha gastado', value: action.dashboard.more_payment.buyer_payments,  secondValue: action.dashboard.more_payment.more_payments, icon: 'icofont-student-alt', colorIcon: 'color-info-light', price: true},
                    {title: 'Comprador que más debe', value: action.dashboard.more_should.buyer_should,  secondValue: action.dashboard.more_should.more_should, icon: 'icofont-student-alt', colorIcon: 'color-alert', price: true},
                ]
            };
        case CLEAN_DASHBOARD:
            return {
                ...state,
                getDashboardRequesting: false,
                getDashboardSuccess: false,
                getDashboardError: '',
                dashboardHeader: [],
                dashboardCard: [],
            };
        default:
            return state;
    }
}

export default reducer;
