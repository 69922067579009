import React from "react";
import DataTable from "react-data-table-component";
import {FormatNumber} from "../../constants/FormatNumber";
import {PaginateComponent} from "../Paginate/Paginate";
import Switch from "react-switch";

export const TableOrders = ({
                                orders,
                                metaOrders,
                                pageCurrency,
                                handleChangePage,
                                infoBuyer,
                                handledCartProducts,
                                setModal,
                                setFocusOrder,
                                setFocusReturnProducts,
                                setFocusReturnPacking,
                                changeState,
}) => {

    const columns = [
        {
            name: 'Id',
            selector: row => row.id,
            center: true,
            width: '50px'
        },
        {
            name: 'Fecha',
            selector: row => row.date.substring(0,10),
            center: true,
            width: '150px'
        },
        {
            name: 'Valor de la compra',
            selector: row => <FormatNumber value={row.price_total}
                                           className={'numeric-input-disabled'}
                                           disabled={true}/> ,
            center: true,
            width: '150px'
        },
        {
            name: 'Comprador',
            selector: row => <div onClick={() => {
                setModal('buyer');
                infoBuyer(row.buyer);
            }}>{row.buyer.name}</div>,
            center: true,
            width: '150px'
        },
        {
            name: 'Teléfono del Comprador',
            selector: row => row.buyer.phone,
            center: true,
            width: '200px'
        },
        {
            name: 'Cantidad de productos',
            selector: row => <div onClick={() => {
                if(row.cart.products.length > 0) {
                    setModal('cart');
                    handledCartProducts(row.cart.id)
                }
            }}>
                {row.cart.products.length}</div>,
            center: true,
            width: '200px'
        },
        {
            name: 'Cantidad de productos devueltos',
            selector: row => <div onClick={() =>
            {if(row.return_products.length > 0 || row.return_packing.length > 0){
                setModal('return');
                setFocusReturnProducts(row.return_products);
                setFocusReturnPacking(row.return_packing);
            }}}>
                {row.return_products.length + row.return_packing.length}</div>,
            center: true,
            width: '250px'
        },
        {
            name: 'Estado',
            selector: row => <div className={'button-state'} style={{background: row.state.color}}>
                <div style={{color: 'white'}}>{row.state.name}</div>
            </div>,
            center: true,
            width: '200px'
        },
        {
            name: 'Devolver productos',
            selector: row => <>{row.state.id === 7 || row.state.id === 12 ?
                <button
                    type="button" onClick={() => {
                    setModal('reverse');
                    handledCartProducts(row.cart.id);
                    setFocusOrder(row)
                }} className="btn button-table-reverse">
                    <div style={{color: 'white'}}>
                        <i className="fa fa-rotate-left" />
                        </div>
                </button> : ''}</>,
            center: true,
        },
        {
            name: 'Producto entregado',
            selector: row => <Switch
                onChange={() => changeState(row.id)}
                checked={row.state.id !== 11 && row.state.id !== 7 && row.state.id !== 12}
                uncheckedIcon={false}
                checkedIcon={false}
                disabled={row.state.id !== 7 && row.state.id !== 12}
            />,
            width: '180px',
            center: true
        },
    ];

    return (
        <div className="card mb-3">
            <DataTable
                title="Datatable Product"
                columns={columns}
                data={orders}
                defaultSortField="title"
                pagination={false}
                selectableRows={false}
                className="table myDataTable table-hover align-middle mb-0 d-row nowrap dataTable no-footer dtr-inline"
                highlightOnHover={true}
                noDataComponent={'No hay ordenes en estos momentos'}
            />
            {metaOrders.total > 9 && (
                <PaginateComponent
                    pageCurrency={pageCurrency}
                    handleChangePage={handleChangePage}
                    meta={metaOrders}
                />
            )}
        </div>
    );
}
