import React from 'react';
import Modal from 'react-bootstrap/Modal';
import {Formik} from "formik";
import {FormatNumber} from "../../constants/FormatNumber";
import {useDispatch, useSelector} from "react-redux";
import {updatePacking} from "../../redux/packing/actions";

export const EditPacking = ({
                               visible,
                               close,
                               focus,
}) => {

    const dispatch = useDispatch();

    const {token} = useSelector(state => state.auth);
    const {updatePackingRequesting, updatePackingError} = useSelector(state => state.packing);

    const handleUpdatePacking = (values) => {
        dispatch(updatePacking(token, values, focus));
    };

    return (
        <Modal show={visible} onHide={close} style={{ display: 'block' }}>
            <div className="modal-content">
                <Modal.Header className="modal-header" closeButton>
                    <h5 className="modal-title  fw-bold" id="addProduct">Editar empaque</h5>
                </Modal.Header>
                <Formik
                    initialValues={{ name: '', sku: '', stock: '',
                        sale_price: '', purchase_price: '', description: '',
                        sub_category_id: ''}}
                    onSubmit={(values ) => {
                        handleUpdatePacking(values)
                    }}
                >
                    {({
                          handleChange,
                          handleSubmit,
                          setFieldValue,
                      }) => (
                        <form onSubmit={handleSubmit}>
                            <Modal.Body className="modal-body">
                                <div className="deadline-form">
                                    <div className="row g-3 mb-3">
                                        <div className="col-sm-12">
                                            <label htmlFor="it em100" className="form-label">Nombre</label>
                                            <input type="text"
                                                   className="form-control"
                                                   name={'name'}
                                                   onChange={handleChange}
                                                   defaultValue={focus.name}
                                            />
                                            {updatePackingError.name && <div className="text-danger fs-12">
                                                {updatePackingError.name}
                                            </div>}
                                        </div>
                                        <div className="col-sm-12">
                                            <label htmlFor="it    em100" className="form-label">Sku</label>
                                            <input type="text"
                                                   className="form-control"
                                                   name={'sku'}
                                                   onChange={handleChange}
                                                   defaultValue={focus.sku}
                                            />
                                            {updatePackingError.sku && <div className="text-danger fs-12">
                                                {updatePackingError.sku}
                                            </div>}
                                        </div>
                                        <div className="col-sm-12">
                                            <label htmlFor="it    em100" className="form-label">Stock</label>
                                            <input type="numeric"
                                                   className="form-control"
                                                   name={'stock'}
                                                   onChange={handleChange}
                                                   defaultValue={focus.stock}
                                            />
                                            {updatePackingError.stock && <div className="text-danger fs-12">
                                                {updatePackingError.stock}
                                            </div>}
                                        </div>
                                        <div className="col-sm-12">
                                            <label htmlFor="it    em100" className="form-label">Precio de venta</label>
                                            <FormatNumber
                                                className="form-control"
                                                onValueChange={(value) => setFieldValue('sale_price', value.floatValue)}
                                                value={focus.sale_price}
                                            />
                                            {updatePackingError.sale_price && <div className="text-danger fs-12">
                                                {updatePackingError.sale_price}
                                            </div>}
                                        </div>
                                        <div className="col-sm-12">
                                            <label htmlFor="it    em100" className="form-label">Precio de compra</label>
                                            <FormatNumber
                                                className="form-control"
                                                onValueChange={(value) => setFieldValue('purchase_price', value.floatValue)}
                                                value={focus.purchase_price}
                                            />
                                            {updatePackingError.purchase_price && <div className="text-danger fs-12">
                                                {updatePackingError.purchase_price}
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                            <div className="modal-footer">
                                {!updatePackingRequesting && (
                                    <button type="button"
                                            className="btn btn-secondary col-sm-3"
                                            onClick={close}
                                            data-bs-dismiss="modal">Cancelar</button>
                                )}
                                {updatePackingRequesting && (
                                    <button className="btn btn-primary lift btn-spinner container-spinner col-sm-3">
                                        <i className="fa fa-spinner fa-spin"></i>
                                    </button>
                                )}
                                {!updatePackingRequesting && (
                                    <button type="submit" className="btn btn-primary col-sm-3">Actualizar</button>
                                )}
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </Modal>
    )
};
