import {all, call, put, takeEvery} from 'redux-saga/effects'
import {
    createSectionFailure,
    createSectionSuccess,
    getSectionsFailure,
    getSectionsSuccess, updateActiveSectionFailure, updateActiveSectionSuccess, updateSectionFailure,
    updateSectionSuccess
} from "./actions";
import {
    CREATE_SECTION_ATTEMPT,
    GET_SECTIONS_ATTEMPT,
    UPDATE_ACTIVE_SECTION_ATTEMPT,
    UPDATE_SECTION_ATTEMPT
} from "./constants";

const URL = `${process.env.REACT_APP_API_KEY}`;

const getSectionsAPI = token => {
    return fetch(`${URL}sections/sections`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    })
        .then(response => response.json())
        .then(json => {
            if (json.data.length === 0){
                throw 'No hay sección';
            }
            if (json.data.length > 0){
                return json.data;
            }
        }).catch((error) => {
            throw error;
        })
}

function* getSectionsFlow(action){
    try {
        const {token} = action;
        const sections = yield call(getSectionsAPI, token);
        yield put(getSectionsSuccess(sections));
    }catch (error) {
        yield put(getSectionsFailure(error));
    }
}

const createSectionAPI = (token, values) => {
    return fetch(`${URL}sections/section`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(values)
    })
        .then(response => response.json())
        .then(json => {
            if (json?.code && json.code === 200){
                return json.section;
            }else {
                throw json.errors
            }
        }).catch((error) => {
            throw error;
        })
}

function* createSectionFlow(action){
    try {
        const {token, values} = action;
        const section = yield call(createSectionAPI, token, values);
        yield put(createSectionSuccess(section));
    }catch (error) {
        yield put(createSectionFailure(error));
    }
}

const updateSectionAPI = (token, values) => {
    return fetch(`${URL}sections/section/${values.id}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(values),
    })
        .then(response => response.json())
        .then(json => {
            if (json?.code && json.code === 200){
                return json.section;
            }else if (json?.code && json.code === 400){
                throw json;
            }else {
                throw json.errors
            }
        }).catch((error) => {
            throw error;
        })
}

function* updateSectionFlow(action){
    try {
        const {token, values} = action;
        const section = yield call(updateSectionAPI, token, values);
        yield put(updateSectionSuccess(section));
    }catch (error) {
        yield put(updateSectionFailure(error));
    }
}

const updateActiveSectionAPI = (token, values) => {
    return fetch(`${URL}sections/change_section/${values.id}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(values),
    })
        .then(response => response.json())
        .then(json => {
            if (json?.code && json.code === 200){
                return json.section;
            }else if (json?.code && json.code === 400){
                throw json;
            }else {
                throw json.errors
            }
        }).catch((error) => {
            throw error;
        })
}

function* updateActiveSectionFlow(action){
    try {
        const {token, values} = action;
        const section = yield call(updateActiveSectionAPI, token, values);
        yield put(updateActiveSectionSuccess(section));
    }catch (error) {
        yield put(updateActiveSectionFailure(error));
    }
}

function* sectionWatcher() {
    yield all([
        takeEvery(GET_SECTIONS_ATTEMPT, getSectionsFlow),
        takeEvery(CREATE_SECTION_ATTEMPT, createSectionFlow),
        takeEvery(UPDATE_SECTION_ATTEMPT, updateSectionFlow),
        takeEvery(UPDATE_ACTIVE_SECTION_ATTEMPT, updateActiveSectionFlow)
    ])
}

export default sectionWatcher;
