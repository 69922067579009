import {
    CLEAN_CATEGORY,
    CREATE_CATEGORY_ATTEMPT,
    CREATE_CATEGORY_FAILURE,
    CREATE_CATEGORY_SUCCESS,
    GET_CATEGORIES_ATTEMPT,
    GET_CATEGORIES_FAILURE,
    GET_CATEGORIES_SUCCESS,
    UPDATE_ACTIVE_CATEGORY_ATTEMPT, UPDATE_ACTIVE_CATEGORY_FAILURE, UPDATE_ACTIVE_CATEGORY_SUCCESS,
    UPDATE_CATEGORY_ATTEMPT,
    UPDATE_CATEGORY_FAILURE,
    UPDATE_CATEGORY_SUCCESS
} from "./constants";

export const getCategories = token => ({
    type: GET_CATEGORIES_ATTEMPT,
    token,
});

export const getCategoriesSuccess = categories => ({
    type: GET_CATEGORIES_SUCCESS,
    categories
});

export const getCategoriesFailure = error => ({
    type: GET_CATEGORIES_FAILURE,
    error,
});

export const createCategory = (token, values) => ({
    type: CREATE_CATEGORY_ATTEMPT,
    token,
    values,
});

export const createCategorySuccess = category => ({
    type: CREATE_CATEGORY_SUCCESS,
    category,
});

export const createCategoryFailure = error => ({
    type: CREATE_CATEGORY_FAILURE,
    error,
});

export const updateCategory = (token, values) => ({
    type: UPDATE_CATEGORY_ATTEMPT,
    token,
    values,
});

export const updateCategorySuccess = category => ({
    type: UPDATE_CATEGORY_SUCCESS,
    category,
});

export const updateCategoryFailure = error => ({
    type: UPDATE_CATEGORY_FAILURE,
    error,
});

export const updateActiveCategory = (token, values) => ({
    type: UPDATE_ACTIVE_CATEGORY_ATTEMPT,
    token,
    values
});

export const updateActiveCategorySuccess = category => ({
    type: UPDATE_ACTIVE_CATEGORY_SUCCESS,
    category,
});

export const updateActiveCategoryFailure = error => ({
    type: UPDATE_ACTIVE_CATEGORY_FAILURE,
    error
});

export const cleanCategory = () => ({
    type: CLEAN_CATEGORY,
});
