import {combineReducers} from 'redux';
import auth from './auth/reducer';
import forgotPassword from './forgotPassword/reducer';
import subcategory from './subcategory/reducer';
import product from './product/reducer';
import order from './order/reducer';
import account from './account/reducer';
import payment from './payment/reducer';
import buyer from './buyer/reducer';
import category from './category/reducer';
import provider from './provider/reducer';
import packing from './packing/reducer';
import section from './section/reducer';
import dashboard from './dashboard/reducer';
import damagedProduct from './damagedProduct/reducer';
import state from './state/reducer';
import damagedPacking from './damagedPacking/reducer';
import generalBackoffice from './generalBackoffice/reducer';

export const IndexReducer = combineReducers({
    auth,
    forgotPassword,
    subcategory,
    product,
    order,
    account,
    payment,
    buyer,
    category,
    provider,
    packing,
    section,
    dashboard,
    damagedProduct,
    state,
    damagedPacking,
    generalBackoffice,
});
