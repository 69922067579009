import {all, call, put, takeEvery} from 'redux-saga/effects'
import {getStatesDamagedProductFailure, getStatesDamagedProductSuccess} from "./actions";
import {GET_STATES_DAMAGED_PRODUCT_ATTEMPT} from "./constants";

const URL = `${process.env.REACT_APP_API_KEY}`;

const getStateDamagedProductAPI = token => {
    return fetch(`${URL}states/states_damaged_products`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    })
        .then(response => response.json())
        .then(json => {
            if (json.code === 200){
                return json.state;
            }
        }).catch((error) => {
            throw error;
        })
}

 function* getStateDamagedProductFlow(action){
    try {
        const {token} = action;
        const statesDamagedProduct = yield call(getStateDamagedProductAPI, token);
        yield put(getStatesDamagedProductSuccess(statesDamagedProduct));
    }catch (error) {
        yield put(getStatesDamagedProductFailure(error));
    }
}

function* stateWatcher() {
    yield all([
        takeEvery(GET_STATES_DAMAGED_PRODUCT_ATTEMPT, getStateDamagedProductFlow),
    ])
}

export default stateWatcher;
