import React from 'react';
import ImageSrc from "../../assets/images/login-img.svg";
import LogoText from "../../assets/images/logo_text.png";

export const AuthLeft = () => {
    return (
        <div className="col-lg-6 d-none d-lg-flex justify-content-center align-items-center rounded-lg auth-h100">
            <div style={{ maxWidth: '25rem' }}>
                <div className={'d-flex justify-content-center'}>
                    <img style={{width: 250, height: 100}} src={LogoText} alt="logo" />
                </div>
                <div className="mb-5">
                    <h2 className="color-900 text-center">Por un comienzo juntos.</h2>
                </div>
                <div className="">
                    <img src={ImageSrc} alt="login-img" />
                </div>
            </div>
        </div>
    );
}

