import React, {useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import Notfound from '../../assets/images/not_found.svg';
import {AuthLeft} from "../../components/Auth/AuthLeft";
import {useDispatch, useSelector} from "react-redux";
import {userAuthenticated} from "../../constants/UserStorage";

export const NotFound = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {user} = useSelector(state => state.auth);

    useEffect(() => {
        userAuthenticated(dispatch, navigate);
    }, []);

    return (
        <div className='main p-2 py-3 p-xl-5 '>
            <div className='body d-flex p-0 p-xl-5'>
                <div className='container-xxl'>
                    <div className='row g-0'>
                        <AuthLeft />
                        <div className="col-lg-6 d-flex justify-content-center align-items-center border-0 rounded-lg auth-h100">
                            <div className="w-100 p-3 p-md-5 card border-0 shadow-sm" style={{ maxWidth: '32rem' }}>
                                <form className="row g-1 p-3 p-md-4">
                                    <div className="col-12 text-center mb-4">
                                        <img src={Notfound} className="w240 mb-4" alt="" />
                                        <h5>OPS! PÁGINA NO ENCONTRADA</h5>
                                        <span className="">Perdón, la página que esta buscando no existe, revise el link y vuelva a intentarlo.</span>
                                    </div>
                                    <div className="col-12 text-center">
                                        <Link to={Object.keys(user).length > 0 ? "/orders" :"/"} title="" className="btn btn-primary btn-lg btn-block btn-light lift text-uppercase">Volver</Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
