import {all, call, put, takeEvery} from 'redux-saga/effects'
import {getBuyersFailure, getBuyersSuccess} from "./actions";
import {GET_BUYERS_ATTEMPT} from "./constants";

const URL = `${process.env.REACT_APP_API_KEY}`;

const getBuyersAPI = (token, page) => {
    return fetch(`${URL}buyers/buyers?page=${page}&top=10`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    })
        .then(response => response.json())
        .then(json => {
            if (json.data.length === 0){
                throw 'No hay compradores';
            }
            if (json.data.length > 0){
                return json;
            }
        }).catch((error) => {
            throw error;
        })
}

function* getBuyersFlow(action) {
    try {
        const {token, page} = action;
        const buyers = yield call(getBuyersAPI, token, page);
        yield put(getBuyersSuccess(buyers));
    } catch (error) {
        yield put(getBuyersFailure(error));
    }
}

function* buyerWatcher(){
    yield all([
        takeEvery(GET_BUYERS_ATTEMPT, getBuyersFlow)
    ]);
}

export default buyerWatcher;
