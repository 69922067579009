import React from 'react';
import Modal from 'react-bootstrap/Modal';
import {Formik} from "formik";

export const AddSection = ({
                                visible,
                                close,
                                error,
                                createSection,
                                requesting
}) => {

    return (
        <Modal show={visible} onHide={close} style={{ display: 'block' }}>
            <div className="modal-content">
                <Modal.Header className="modal-header" closeButton>
                    <h5 className="modal-title  fw-bold" id="addProduct"> Agregar Sección</h5>
                </Modal.Header>
                <Formik
                    initialValues={{ name: ''}}
                    onSubmit={(values ) => {
                        createSection(values)
                    }}
                >
                    {({
                          handleChange,
                          handleSubmit,

                      }) => (
                        <form onSubmit={handleSubmit}>
                            <Modal.Body className="modal-body">
                                <div className="deadline-form">
                                    <div className="row g-3 mb-3">
                                        <div className="col-sm-12">
                                            <label htmlFor="it em100" className="form-label">Nombre</label>
                                            <input type="text"
                                                   className="form-control"
                                                   placeholder="Nombre"
                                                   name={'name'}
                                                   onChange={handleChange}
                                            />
                                            {error.name && <div className="text-danger fs-12 mt-2">{error.name}</div>}
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                            <div className="modal-footer">
                                {!requesting && (
                                    <button type="button"
                                            className="btn btn-secondary col-sm-3"
                                            onClick={close}
                                            data-bs-dismiss="modal">Cancelar</button>
                                )}
                                {requesting && (
                                    <button className="btn btn-primary lift btn-spinner container-spinner col-sm-3">
                                        <i className="fa fa-spinner fa-spin "></i>
                                    </button>
                                )}
                                {!requesting && (
                                    <button type="submit" className="btn btn-primary col-sm-3">Crear</button>
                                )}
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </Modal>
    )
};
