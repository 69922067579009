import React from 'react'
import {Modal} from "react-bootstrap";

export const ModalHistoriesPacking = ({
                                 visible,
                                 close,
                                 histories,
}) => {
    return (
        <Modal show={visible} onHide={close} style={{ display: 'block'}} size={'lg'}>
            <div className="modal-content">
                <Modal.Header className="modal-header" closeButton>
                    <h5 className="modal-title  fw-bold" id="expeditLabel1111">Historial de devoluciones</h5>
                </Modal.Header>
                <Modal.Body className="modal-body">
                    <div className="deadline-form">
                        {histories.map((history, index) => (
                            <div className={`row g-3 ${index !== 0 ? 'mt-2' : ''}`} key={index}>
                                <div className="col-sm-1">
                                    <label htmlFor="item100" className="form-label">Cantidad</label>
                                    <input type="text" className="form-control" id="item100" value={history.quantity} disabled={true} />
                                </div>
                                <div className="col-sm-6">
                                    <label htmlFor="item100" className="form-label">Razón</label>
                                    <input type="text" className="form-control" id="item100" value={history.reason} disabled={true} />
                                </div>
                                <div className="col-sm-3">
                                    <label htmlFor="item100" className="form-label">Fecha</label>
                                    <input type="text" className="form-control" id="item100" value={history.date.substring(0,10)} disabled={true} />
                                </div>
                                <div className="col-sm-2">
                                    <label htmlFor="item100" className="form-label">Estado</label>
                                    <input type="text" disabled={true} style={{background: history.state.color, color: 'white'}}
                                           className="form-control" value={history.state.name}
                                           id="item100" />
                                </div>
                            </div>
                        ))}
                    </div>
                </Modal.Body>
            </div>
        </Modal>
    )
}
