import React from "react";
import Modal from "react-bootstrap/Modal";
import {Formik} from "formik";
import {addStockProduct} from "../../redux/product/actions";
import {useDispatch, useSelector} from "react-redux";

export const AddStock = ({
                             visible,
                             close,
                             focus,
                             addStock,
                             handleAddStock,
}) => {

    const dispatch = useDispatch();

    const {token} = useSelector(state => state.auth);
    const {addStockProductRequesting} = useSelector(state => state.product);

    const handleAddStockProduct = values => {
        let stocks = addStock.reduce((previous, current) => { return previous + parseInt(current.stock)}, 0);
        let bodyNotReferences = {
            stock: values.stock,
            product_id: focus.id,
        };
        let bodyReferences = {
            stock: stocks,
            product_id: focus.id,
            references: addStock,
        };
        dispatch(addStockProduct(token, addStock.length > 0 ? bodyReferences  : bodyNotReferences));
    };

    return (
        <Modal show={visible} onHide={close} style={{ display: 'block' }}>
            <div className="modal-content">
                <Modal.Header className="modal-header" closeButton>
                    <h5 className="modal-title  fw-bold" id="addProduct">{`Agregar stock al producto ${focus.name}`}</h5>
                </Modal.Header>
                {focus.references.length === 0 && (
                    <Formik
                        initialValues={{ stock: '' }}
                        onSubmit={(values ) => {
                            handleAddStockProduct(values)
                        }}
                    >
                        {({
                              handleChange,
                              handleSubmit,
                          }) => (
                            <form onSubmit={handleSubmit}>
                                <Modal.Body className="modal-body">
                                    <div className="mb-3 mt-1">
                                        <label className="mb-1 form-label">{`Cantidad a agregar`}</label>
                                        <input type="text" className="form-control"
                                               placeholder={focus.stock}
                                               name={'stock'}
                                               onChange={handleChange}
                                        />
                                    </div>
                                </Modal.Body>
                                <div className="modal-footer">
                                    {!addStockProductRequesting && (
                                        <button className="btn btn-secondary col-sm-4"
                                                onClick={close}
                                                data-bs-dismiss="modal">Cancelar</button>
                                    )}
                                    {addStockProductRequesting && (
                                        <button className="btn btn-primary lift btn-spinner container-spinner col-sm-4">
                                            <i className="fa fa-spinner fa-spin"></i>
                                        </button>
                                    )}
                                    {!addStockProductRequesting && (
                                        <button type={"submit"}
                                                className="btn btn-primary col-sm-4">
                                            Agregar stocks
                                        </button>
                                    )}
                                </div>
                            </form>
                        )}
                    </Formik>
                )}
                {focus.references.length > 0 && (
                   <>
                       <Modal.Body className="modal-body">
                           {focus.references.map((reference, index) => (
                               <div key={index}>
                                   <div className="mb-3 mt-1">
                                       <label className="mb-1 form-label" >{`Nombre de referencia ${index + 1} `}</label>
                                       <input type="text" className="form-control"
                                              placeholder="Referencia"
                                              name={'reference'}
                                              value={reference.reference}
                                              disabled={true}
                                       />
                                   </div>
                                   <div className="mb-3 mt-1">
                                       <label className="mb-1 form-label">{`Cantidad a agregar ${index + 1}`}</label>
                                       <input type="text" className="form-control"
                                              placeholder={reference.quantity}
                                              name={'stock_reference'}
                                              onChange={(value) => handleAddStock({reference_id: reference.id, stock: value.target.value}, 'val')}
                                       />
                                   </div>
                               </div>
                           ))}
                       </Modal.Body>
                       <div className="modal-footer">
                           {!addStockProductRequesting && (
                               <button className="btn btn-secondary col-sm-4"
                                       onClick={close}
                                       data-bs-dismiss="modal">Cancelar</button>
                           )}
                           {addStockProductRequesting && (
                               <button className="btn btn-primary lift btn-spinner container-spinner col-sm-4">
                                   <i className="fa fa-spinner fa-spin"></i>
                               </button>
                           )}
                           {!addStockProductRequesting && (
                               <button onClick={handleAddStockProduct}
                                       className="btn btn-primary col-sm-4">
                                   Agregar stocks
                               </button>
                           )}
                       </div>
                   </>
                )}
            </div>
        </Modal>
    )
};
