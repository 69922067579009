import React from "react";
import Modal from "react-bootstrap/Modal";
import {Formik} from "formik";
import {useDispatch, useSelector} from "react-redux";
import {createDamagedProduct} from "../../redux/damagedProduct/actions";

export const AddDamagedProduct = ({
                             visible,
                             close,
                             focus,
}) => {

    const dispatch = useDispatch();

    const {token} = useSelector(state => state.auth);
    const {createDamagedProductRequesting} = useSelector(state => state.damagedProduct);

    let referenceActive = focus.references.length > 0 ?
        focus.references.filter(reference => reference.quantity > 0) : []


    const handleDamagedProduct = (values) => {
        let body = {
            product_id: focus.id,
            quantity: parseInt(values.quantity),
            reason: values.reason,
        };
        let bodyReferences = {
            product_id: focus.id,
            quantity: parseInt(values.quantity),
            reason: values.reason,
            reference_id: parseInt(values.reference_id)
        }
        let bodyCreate = values.reference_id ? bodyReferences : body
        dispatch(createDamagedProduct(token, bodyCreate));
    };

    return (
        <Modal show={visible} onHide={close} style={{ display: 'block' }}>
            <div className="modal-content">
                <Modal.Header className="modal-header" closeButton>
                    <h5 className="modal-title  fw-bold" id="addProduct">{`Agregar producto averiado`}</h5>
                </Modal.Header>
                <Formik
                    initialValues={{ quantity: '', reason: '', reference_id: '' }}
                    onSubmit={(values ) => {
                        handleDamagedProduct(values)
                    }}
                >
                    {({
                          handleChange,
                          handleSubmit,
                      }) => (
                        <form onSubmit={handleSubmit}>
                            <Modal.Body className="modal-body">
                                <div className="mb-3 mt-1">
                                    {referenceActive.length > 0 && (
                                        <>
                                            <label className="form-label">Referencia</label>
                                            <select name={'reference_id'} className="form-control" onChange={handleChange}>
                                                <option
                                                    value={0}>
                                                    Seleccionar referencia
                                                </option>
                                                {referenceActive.map((reference, index) => (
                                                    <option
                                                        key={index}
                                                        value={reference.id}>
                                                        {reference.reference}
                                                    </option>
                                                ))}
                                            </select>
                                            <label className="mt-2 mb-1 form-label">{`Cantidad a agregar`}</label>
                                            <input type="text" className="form-control"
                                                   placeholder={'0'}
                                                   name={'quantity'}
                                                   onChange={handleChange}
                                            />
                                        </>
                                    )}
                                    {focus.references.length === 0 && (
                                        <>
                                            <label className="mb-1 form-label">{`Cantidad a agregar`}</label>
                                            <input type="text" className="form-control"
                                                   placeholder={focus.stock}
                                                   name={'quantity'}
                                                   onChange={handleChange}
                                            />
                                        </>
                                    )}
                                        <textarea name="reason"
                                              rows="4"
                                              placeholder={'Escribe la razón de la avería'}
                                              className="form-control mt-2"
                                              onChange={handleChange}
                                    />
                                </div>
                            </Modal.Body>
                            <div className="modal-footer">
                                {!createDamagedProductRequesting && (
                                    <button className="btn btn-secondary col-sm-4"
                                            onClick={close}
                                            data-bs-dismiss="modal">Cancelar</button>
                                )}
                                {createDamagedProductRequesting && (
                                    <button className="btn btn-primary lift btn-spinner container-spinner col-sm-4">
                                        <i className="fa fa-spinner fa-spin"></i>
                                    </button>
                                )}
                                {!createDamagedProductRequesting && (
                                    <button type={"submit"}
                                            className="btn btn-primary col-sm-4">
                                        Agregar avería
                                    </button>
                                )}
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </Modal>
    )
};
