import {all, call, put, takeEvery} from 'redux-saga/effects'
import {
    createPaymentFailure,
    createPaymentSuccess,
    getAllPaymentsFailure,
    getAllPaymentsSuccess, updatePaymentFailure,
    updatePaymentSuccess
} from "./actions";
import {CREATE_PAYMENT_ATTEMPT, GET_ALL_PAYMENTS_ATTEMPT, UPDATE_PAYMENT_ATTEMPT} from "./constants";

const URL = `${process.env.REACT_APP_API_KEY}`;

const getAllPayments = () => {
    return fetch(`${URL}payments/all_payments`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
        },
    })
        .then(response => response.json())
        .then(json => {
            if (json.data.length === 0){
                throw 'No hay metodos de pago';
            }
            if (json.data.length > 0){
                return json.data;
            }
        }).catch((error) => {
            throw error;
        })
}

function* getAllPaymentsFlow(action){
    try {
        const {token} = action;
        const payments = yield call(getAllPayments, token);
        yield put(getAllPaymentsSuccess(payments));
    } catch (error) {
        yield put(getAllPaymentsFailure(error));
    }
}

const createAllPayments = (token, values) => {
    return fetch(`${URL}payments/payment`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(values)
    })
        .then(response => response.json())
        .then(json => {
            if (json?.code && json.code === 200){
                return json.payment;
            }else {
                throw json.errors
            }
        }).catch((error) => {
            throw error;
        })
}

function* createPaymentFlow(action){
    try {
        const {token, values} = action;
        const payment = yield call(createAllPayments, token, values);
        yield put(createPaymentSuccess(payment));
    } catch (error) {
        yield put(createPaymentFailure(error));
    }
}

const updatePaymentAPI = (token, values) => {
    return fetch(`${URL}payments/payment/${values.id}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(values),
    })
        .then(response => response.json())
        .then(json => {
            if (json?.code && json.code === 200){
                return json.payment;
            }else if (json?.code && json.code === 400){
                throw json;
            }else {
                throw json.errors
            }
        }).catch((error) => {
            throw error;
        })
}

function* updatePaymentFlow(action){
    try {
        const {token, values} = action;
        const payment = yield call(updatePaymentAPI, token, values);
        yield put(updatePaymentSuccess(payment));
    } catch (error) {
        yield put(updatePaymentFailure(error));
    }
}

function* paymentsWatcher() {
    yield all([
        takeEvery(GET_ALL_PAYMENTS_ATTEMPT, getAllPaymentsFlow),
        takeEvery(CREATE_PAYMENT_ATTEMPT, createPaymentFlow),
        takeEvery(UPDATE_PAYMENT_ATTEMPT, updatePaymentFlow),
    ])
}

export default paymentsWatcher;
