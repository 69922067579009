import React, {useEffect, useState} from 'react';
import {Sidebar} from "../../components/Sidebar/Sidebar";
import {Header} from "../../components/Header/Header";
import {useDispatch, useSelector} from "react-redux";
import {TableOrders} from "../../components/Order/Table";
import {changeStateOrder, getCartProducts, getOrders, reverseOrder} from "../../redux/order/actions";
import {ModalBuyer} from "../../components/Order/ModalBuyer";
import {ModalCart} from "../../components/Order/ModalCart";
import {ModalReverse} from "../../components/Order/ModalReverse";
import {ModalReturnProducts} from "../../components/Order/ModalReturnProducts";

export const Order = () => {

    const dispatch = useDispatch();

    const {user, token, successMe, sidebarMini} = useSelector(state => state.auth);
    const {getOrderSuccess, orders,
        metaOrders, pageOrders, reverseRequesting,
        cart, getProductsRequesting, reverseSuccess} = useSelector(state => state.order);

    const [focusOrder, setFocusOrder] = useState({});
    const [focusReturnProducts, setFocusReturnProducts] = useState([]);
    const [focusReturnPacking, setFocusReturnPacking] = useState([]);
    const [buyer, setBuyer] = useState({});
    const [modal, setModal] = useState('');

    const [changeProducts, setChangesProduct] = useState([]);
    const [changePacking, setChangesPacking] = useState([]);
    const [packingAll, setPackingAll] = useState([]);

    const handleChangePage = (page) => {
        if(page !== pageOrders && page !== 0){
            dispatch(getOrders(token, page))
        }
    }

    const handleReverseOrder = () => {
        if(changeProducts.length > 0 || changePacking.length > 0){
            let totalPrice = changeProducts.reduce((previous , current) => {return previous + (current.quantity * current.sale_price) }, 0)
            let totalPricePacking = changePacking.reduce((previous , current) => {return previous + (current.quantity * current.sale_price) }, 0)
            let values = {
                '_method': 'PUT',
                price_total: totalPrice + totalPricePacking,
                products: changeProducts,
                packing: changePacking,
                allPacking: packingAll,
            }
            dispatch(reverseOrder(focusOrder.id, focusOrder.buyer.id, values, token))
            setChangesProduct([]);
            setChangesPacking([]);
            setPackingAll([]);
        }else{
            alert('No selecciono nada para devolver')
        }
    };

    const handledCartProducts = (id) => {
        dispatch(getCartProducts(token, id))
    }

    const handleChangeState = (orderId) => {
        dispatch(changeStateOrder(token, orderId))
    };

    useEffect(() => {
        if(!getOrderSuccess && successMe)
            dispatch(getOrders(token, 1))
    }, [successMe])

    useEffect(() => {
        if(!reverseRequesting && reverseSuccess)
            setModal('')
    }, [reverseRequesting])

    return (
        <div className={'d-flex'}>
            <Sidebar isSidebarMini={sidebarMini}
                     routeCurrency={'/orders'} />
            <div className='main px-lg-12 px-md-4' >
                <Header user={user}/>
                <button
                    type="button"
                    className="btn btn-primary align-self-end"
                   // onClick={handleShow}
                >
                    Agregar orden
                </button>
                <div className={`${sidebarMini ? 'table-widthSideMini': 'table-widthSide'} body d-flex py-3`}>
                <TableOrders
                    orders={orders}
                    metaOrders={metaOrders}
                    pageCurrency={pageOrders}
                    handleChangePage={handleChangePage}
                    infoBuyer={setBuyer}
                    handledCartProducts={handledCartProducts}
                    setModal={setModal}
                    setFocusOrder={setFocusOrder}
                    setFocusReturnProducts={setFocusReturnProducts}
                    setFocusReturnPacking={setFocusReturnPacking}
                    changeState={handleChangeState}
                />
                </div>
            </div>
            {modal === 'buyer' && <ModalBuyer
                    visible={modal === 'buyer'}
                    close={() => {
                        setModal('');
                        setBuyer({});
                    }}
                    buyer={buyer}
                />
            }
            {modal === 'cart' && <ModalCart
                visible={modal === 'cart'}
                close={() => {
                    setModal('');
                }}
                products={cart.products}
                requesting={getProductsRequesting}
            />}
            {modal === 'reverse' && <ModalReverse
                visible={modal === 'reverse'}
                close={() => {
                    setModal('');
                    setChangesProduct([]);
                }}
                handleReverseOrder={handleReverseOrder}
                requesting={reverseRequesting}
                products={cart.products}
                requestingProducts={getProductsRequesting}
                enabledProduct={changeProducts}
                setEnabledProduct={setChangesProduct}
                enabledPacking={changePacking}
                setEnabledPacking={setChangesPacking}
                setPackingAll={setPackingAll}
                packingAll={packingAll}
            />}
            {modal === 'return' && <ModalReturnProducts
                visible={modal === 'return'}
                close={() => {
                    setModal('');
                }}
                products={focusReturnProducts}
                packing={focusReturnPacking}
            />}
        </div>
    )
};
