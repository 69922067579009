import React, {useEffect, useState} from 'react'
import {Modal} from "react-bootstrap";

export const ModalReverse = ({
                                 visible,
                                 close,
                                 handleReverseOrder,
                                 requesting,
                                 products,
                                 requestingProducts,
                                 enabledProduct,
                                 setEnabledProduct,
                                 enabledPacking,
                                 setEnabledPacking,
                                 setPackingAll,
                                 packingAll,
}) => {

    const [productsWithStock, setProductsWithStock] = useState([]);
    const [viewPacking, setViewPacking] = useState(0);

    const handleEnabledProduct = (product) => {
        let isProduct = enabledProduct.find(productEnabled => productEnabled.product_id === product.product_id);
        if(isProduct !== undefined){
            setEnabledProduct(enabledProduct.filter(productEnabled => productEnabled.product_id !== product.product_id));
        }else{
            setEnabledProduct([...enabledProduct, product])
        }
    };

    const handleEnabledPacking = (packing) => {
        let isPacking = enabledPacking.find(packingEnabled => packingEnabled.packing_id === packing.packing_id && packingEnabled.cart_product_id === packing.cart_product_id);
        if(isPacking !== undefined){
            setEnabledPacking(enabledPacking.filter(packingEnabled => packingEnabled.cart_product_id !== packing.cart_product_id || packingEnabled.packing_id !== packing.packing_id));
            handleAllPacking(packing, true);
        }else{
            setEnabledPacking([...enabledPacking, packing]);
            handleAllPacking(packing, false);
        }
    };

    const handleAllPacking = (packing, clean) => {
        let isAllPacking = packingAll.find(packingEnabled => packingEnabled.packing_id === packing.packing_id );
        if(isAllPacking !== undefined){
            if(clean){
                if(isAllPacking.quantity === 1 ){
                    setPackingAll(packingAll.filter(packingChange => packingChange.packing_id !== packing.packing_id));
                }else{
                    let changePacking = packingAll.map((packingChange) => (
                        packingChange.packing_id === packing.packing_id ?
                            {...packingChange, quantity: packingChange.quantity - 1}
                            : packingChange
                    ))
                    setPackingAll(changePacking)
                }
            }else{
                let changePacking = packingAll.map((packingChange) => (
                    packingChange.packing_id === packing.packing_id ?
                        {...packingChange, quantity: packing.quantity + 1}
                        : packingChange
                ))
                setPackingAll(changePacking)
            }
        }else{
            setPackingAll([...packingAll, packing])
        }
    }

    const changeQuantity = (quantity, product, currencyQuantity) => {
        if(currencyQuantity >= quantity){
            let changeProduct = enabledProduct.map((productChange) => (
                productChange.product_id === product.product_id ?
                    {...productChange, quantity: quantity === '' ? 0 : parseInt(quantity)}
                    : productChange
            ))
            setEnabledProduct(changeProduct)
        }else {
            alert('No puede agregar una cantidad mayor a la que habia en la orden, su valor se convertira en 0 cuando se envie sino se cambia.')
        }
    };

    const changeQuantityPacking = (quantity, pack, currencyQuantity) => {
        if(currencyQuantity >= quantity){
            let changePacking = enabledPacking.map((packing) => (
                packing.packing_id === pack.packing_id ?
                    {...packing, quantity: quantity === '' ? 0 : parseInt(quantity)}
                    : packing
            ))
            let changeAllPacking = packingAll.map((packingChange) => (
                packingChange.packing_id === pack.packing_id ?
                    {...packingChange, quantity: quantity === '' ? 0 : parseInt(quantity)}
                    : packingChange
            ))
            setPackingAll(changeAllPacking)
            setEnabledPacking(changePacking)
        }else {
            alert('No puede agregar una cantidad mayor a la que habia en la orden, su valor se convertira en 0 cuando se envie sino se cambia.')
        }
    };

    const handleToggle = (product) => {
        if(product !== viewPacking){
            setViewPacking(product);
        }else{
            setViewPacking(0);
        }
    }

    useEffect(() => {
        if(!requestingProducts){
            setProductsWithStock(products.filter(product => product.quantity > 0));
        }
    }, [requestingProducts]);

    return (
        <Modal show={visible} onHide={close} style={{ display: 'block' }}>
            <div className="modal-content">
                <Modal.Header className="modal-header" closeButton>
                    <h5 className="modal-title  fw-bold" id="expeditLabel1111">¿Qué productos se devolvieron?</h5>
                </Modal.Header>
                <h5 className="title-center mt-3 fw-semibold">Indique la cantidad del producto a devolver</h5>
                {!requestingProducts && (
                    <Modal.Body className="modal-body">
                        {productsWithStock.map((product, index) => {
                            let isProduct = enabledProduct.find(productEnabled => productEnabled.product_id === product.product_id);
                            let packingWithStock = product.packing.filter(packing => packing.quantity > 0);
                            return  <div key={index}>
                                <label htmlFor="item100" className="form-label">Producto {index + 1}</label>
                                <div className="deadline-form">
                                    <div className="row g-3 mb-3">
                                        <div className="col-sm-12">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox"
                                                       onClick={() => handleEnabledProduct(product)}
                                                       id="defaultCheck1" />
                                                <label htmlFor="item100" className="form-label">Nombre del producto:</label>
                                            </div>
                                            <input type="text" className="form-control" id="item100" value={product.product_name} disabled={true} />
                                        </div>
                                        {product?.reference_name && (
                                            <div className="col-sm-12">
                                                <label htmlFor="abc11edit" className="form-label">Referencia:</label>
                                                <input type="text" className="form-control" id="item100" value={product.reference_name} disabled={true} />
                                            </div>
                                        )}
                                        <div className="row mt-2 mb-3 align-items-center">
                                            {product.images.length > 0 && (
                                                <img className="avatar  mini-image-product" src={product.images[0].url} alt="profile" />
                                            )}
                                            <div className="col-sm-5">
                                                <label htmlFor="abc11edit" className="form-label">Cantidad a devolver:</label>
                                                <input type="text" className="form-control col-sm-4" id="item100"
                                                       placeholder={product.quantity}
                                                       defaultValue={product.quantity}
                                                       onChange={(event) => changeQuantity(event.target.value, product,product.quantity)}
                                                       disabled={isProduct?.product_id !== product.product_id} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {packingWithStock.length > 0 && (
                                    <>
                                        <div className="container-pack" onClick={() => handleToggle(product.product_id)}>
                                            <div  className="form-label">Empaques del producto {index + 1} </div>
                                            <i className={viewPacking === product.product_id ? "fa-solid fa-arrow-up" : "fa-solid fa-arrow-down"}></i>
                                        </div>
                                        {viewPacking === product.product_id && packingWithStock.map((pack, index) => {
                                            let isPacking = enabledPacking.find(packingEnabled => packingEnabled.packing_id === pack.packing_id && packingEnabled.cart_product_id === pack.cart_product_id);
                                            return  <div key={index}>
                                                <label htmlFor="item100" className="form-label">Empaque {index + 1}</label>
                                                <div className="deadline-form">
                                                    <div className="row g-3 mb-3">
                                                        <div className="col-sm-12">
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox"
                                                                       disabled={pack.quantity === 0}
                                                                       onClick={() => handleEnabledPacking(pack)}
                                                                       id="defaultCheck1" />
                                                                <label htmlFor="item100" className="form-label">Nombre del Empaque:</label>
                                                            </div>
                                                            <input type="text" className="form-control" id="item100" value={pack.packing_name} disabled={true} />
                                                        </div>
                                                        <div className="row mt-2 mb-3 align-items-center">
                                                            {pack.images.length > 0 && (
                                                                <img className="avatar  mini-image-product" src={pack.images[0].url} alt="profile" />
                                                            )}
                                                            <div className="col-sm-5">
                                                                <label htmlFor="abc11edit" className="form-label">Cantidad a devolver:</label>
                                                                <input type="text" className="form-control col-sm-4" id="item100"
                                                                       placeholder={pack.quantity}
                                                                       defaultValue={pack.quantity}
                                                                       onChange={(event) => changeQuantityPacking(event.target.value, pack, pack.quantity)}
                                                                       disabled={isPacking?.packing_id !== pack.packing_id || isPacking?.cart_product_id !== pack.cart_product_id}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        })}
                                    </>
                                )}
                            </div>
                        })}
                    </Modal.Body>
                )}
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary col-sm-4 "
                            onClick={close} data-bs-dismiss="modal">Cerrar</button>
                    <button type="submit" className="btn col-sm-4 button-reverse"
                            onClick={handleReverseOrder}>
                        {requesting ? <i className="fa fa-spinner fa-spin" />  : 'Reversar'}
                    </button>
                </div>
            </div>
        </Modal>
    )
}
