import React, {useEffect} from 'react';
import Profile from '../../assets/images/profile_av.svg';
import Modal from "react-bootstrap/Modal";
import {Spinner} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {cleanAccount} from "../../redux/account/actions";
import {cleanBuyer} from "../../redux/buyer/actions";
import {cleanCategory} from "../../redux/category/actions";
import {cleanOrder} from "../../redux/order/actions";
import {cleanPayment} from "../../redux/payment/actions";
import {cleanProduct} from "../../redux/product/actions";
import {cleanProvider} from "../../redux/provider/actions";
import {cleanSubcategory} from "../../redux/subcategory/actions";
import {logout, openModalLogout} from "../../redux/auth/actions";
import {cleanPacking} from "../../redux/packing/actions";
import {cleanSection} from "../../redux/section/actions";
import {cleanDashboard} from "../../redux/dashboard/actions";
import {cleanDamagedProduct} from "../../redux/damagedProduct/actions";
import {cleanDamagedPacking} from "../../redux/damagedPacking/actions";

export const Header = ({
                           user,
                           search = true,
                           placeHolderSearch = '*función no disponible aún',
                           handleSearch,
                           handleCleanSearch,
                           setSearchText,
                           searchText,
}) => {

    const dispatch = useDispatch();

    const {token, logoutRequesting, logoutSuccess, openModal} = useSelector(state => state.auth);

    const navigate = useNavigate();

    const closeModal = () => {
        dispatch(openModalLogout(false));
    };

    useEffect(() => {
        if(logoutSuccess){
            navigate('/');
            localStorage.removeItem('@thivisha');
            localStorage.removeItem('@thivisha_date');
            localStorage.removeItem('@thivisha_dashboard');
        }
    }, [logoutSuccess])


    const handleLogout = () => {
        let values = {
            email: user.email,
        }
        dispatch(logout(token, values));
        dispatch(cleanAccount());
        dispatch(cleanBuyer());
        dispatch(cleanCategory());
        dispatch(cleanOrder());
        dispatch(cleanPayment());
        dispatch(cleanProduct());
        dispatch(cleanProvider());
        dispatch(cleanSubcategory());
        dispatch(cleanPacking());
        dispatch(cleanSection());
        dispatch(cleanDashboard());
        dispatch(cleanDamagedProduct());
        dispatch(cleanDamagedPacking());
    };

    const handleTextSearch = (value) => {
        dispatch(setSearchText(value))
    }

    return (
        <nav className="navbar navbar-expand-lg py-4">
            <div className="container-xxl">
                <div className="h-right d-flex align-items-center mr-2 mr-lg-0 order-1 mt-4">
                    <div className="u-info me-2" >
                        <p className="mb-0 text-end line-height-sm "><span className="font-weight-bold">{user.name}</span></p>
                        <small>{user.rol.name}</small>
                    </div>
                    <img className="avatar lg rounded-circle img-thumbnail" src={Profile} alt="profile" />
                    <div className="setting ms-2">
                        <button className="navbar-toggler p-0 border-0 menu-toggle order-3" type="button" onClick={() => {
                            var sidebar = document.getElementById('mainsidemenu')
                            if (sidebar) {
                                if (sidebar.classList.contains('open')) {
                                    sidebar.classList.remove('open')
                                } else {
                                    sidebar.classList.add('open')
                                }
                            }
                        }}>
                            <span className="fa fa-bars"></span>
                        </button>
                    </div>
                </div>
                {search && (
                    <div className="col-lg-7">
                        <div className="input-group flex-nowrap input-group-lg mt-4">
                            <input type="search" className="form-control"
                                   onChange={(text) => {
                                       handleTextSearch(text.target.value)
                                   }}
                                   placeholder={placeHolderSearch}
                                   value={searchText}
                            />
                            <button type="button" className="input-group-text"
                                    onClick={() => {
                                        handleSearch()
                                    }}
                                    id="addon-wrapping"><i className={`fa fa-search`}></i></button>
                            {searchText !== '' && (
                                <button type="button" className="input-group-text"
                                        style={{background: 'blue', color: 'white'}}
                                        onClick={() => {
                                            handleCleanSearch()
                                            handleTextSearch('')
                                        }}
                                        id="addon-wrapping"><i className={`fa fa-close`} ></i></button>
                            )}
                        </div>
                    </div>
                )}
            </div>
            <Modal show={openModal}
                   centered

            >
                <div className='modal-dialog-centered'>
                    <div className="modal-content">
                        <Modal.Header
                            className="modal-header title-center">
                            <Modal.Title>
                                <h5 className="modal-title  fw-bold" >Cerrar sesión</h5>
                            </Modal.Title>
                        </Modal.Header>
                        {!logoutRequesting && (
                            <div className="modal-footer footer-buttons">
                                <button type="button"
                                        className="btn btn-secondary col-sm-5"
                                        onClick={() => closeModal()}
                                        data-bs-dismiss="modal">Cancelar</button>
                                <button type="submit"
                                        onClick={() => handleLogout()}
                                        className="btn btn-primary col-sm-5">
                                    Cerrar sesión</button>
                            </div>
                        )}
                        {logoutRequesting && (
                            <>
                                <Spinner animation="border" role="status" size="xm" className={'spinner'}/>
                                <a className="card-footer text-center border-top-0 mt-3 mb-3" > Estamos cerrando sesión</a>
                            </>
                        )}
                    </div>
                </div>
            </Modal>
        </nav>
    )
}
