import React from "react";
import DataTable from "react-data-table-component";
import Switch from "react-switch";

export const TableCategory = ({
                                  categories,
                                  setFocusCategory,
                                  setModalEditCategory,
                                  handleUpdateActive,
}) => {

    const columns = [
        {
            name: 'Id',
            selector: row => row.id,
            center: true,
            width: '50px'
        },
        {
            name: 'Nombre',
            selector: row => row.name,
            center: true,
            width: '350px'
        },
        {
            name: 'Activo',
            selector: row => <Switch
                onChange={() => handleUpdateActive({active:!row.active, id: row.id})}
                checked={row.active}
                uncheckedIcon={false}
                checkedIcon={false}
            />,
            center: true,
            width: '150px'
        },
        {
            name: 'Sección',
            selector: row => row.section.name,
            center: true,
            width: '150px'
        },
        {
            name: 'Acción',
            selector: row => <button type="button" className="btn button-table-category"
                                     onClick={() => {
                                         setFocusCategory(row);
                                         setModalEditCategory(true);
                                     }}>
                <i className="fa-solid fa-pen-to-square fa-lg icon-table" />
            </button>,
            center: true,
            width: '200px'
        },
    ];

    return (
        <div className="card mb-3">
            <DataTable
                title="Datatable Product"
                columns={columns}
                data={categories}
                defaultSortField="title"
                pagination={categories.length > 10}
                selectableRows={false}
                className="table myDataTable table-hover align-middle mb-0 d-row nowrap dataTable no-footer dtr-inline"
                highlightOnHover={true}
                noDataComponent={'No hay categorias en estos momentos'}
                paginationComponentOptions={{
                    rowsPerPageText: 'Filas por página',
                    rangeSeparatorText: 'de'
                }}
            />
        </div>
    );
}
