import {all, call, put, takeEvery} from 'redux-saga/effects'
import {
    createSubcategoryFailure,
    createSubcategorySuccess,
    getSubcategoryFailure,
    getSubcategorySuccess, updateSubcategoryFailure, updateSubcategorySuccess
} from "./actions";
import {CREATE_SUBCATEGORY_ATTEMPT, GET_SUBCATEGORY_ATTEMPT, UPDATE_SUBCATEGORY_ATTEMPT} from "./constants";

const URL = `${process.env.REACT_APP_API_KEY}`;

const getSubcategoriesAPI = (token) => {

    return fetch(`${URL}subcategories/all_subcategories`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    })
        .then(response => response.json())
        .then(json => {
            if (json.code === 400){
                throw json.message;
            }
            if (json.code === 200){
                return json.data;
            }
        }).catch((error) => {
            throw error;
        })
};

function* getSubcategoriesFlow(action) {
    try {
        const {token} = action;
        const subcategories = yield call(getSubcategoriesAPI, token);
        yield put(getSubcategorySuccess(subcategories));
    } catch (error) {
        yield put(getSubcategoryFailure(error));
    }
}

const createSubcategoriesAPI = (token, values) => {
    return fetch(`${URL}subcategories/subcategory`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(values)
    })
        .then(response => response.json())
        .then(json => {
            if (json?.code && json.code === 200){
                return json.subcategory;
            }else {
                throw json.errors
            }
        }).catch((error) => {
            throw error;
        })
};

function* createSubcategoriesFlow(action) {
    try {
        const {token, values} = action;
        const subcategory = yield call(createSubcategoriesAPI, token, values);
        yield put(createSubcategorySuccess(subcategory));
    } catch (error) {
        yield put(createSubcategoryFailure(error));
    }
}

const updateSubcategoriesAPI = (token, values) => {
    return fetch(`${URL}subcategories/subcategory/${values.id}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(values)
    })
        .then(response => response.json())
        .then(json => {
            if (json?.code && json.code === 200){
                return json.subcategory;
            }else if (json?.code && json.code === 400){
                throw json;
            }else {
                throw json.errors
            }
        }).catch((error) => {
            throw error;
        })
};

function* updateSubcategoriesFlow(action) {
    try {
        const {token, values} = action;
        const subcategory = yield call(updateSubcategoriesAPI, token, values);
        yield put(updateSubcategorySuccess(subcategory));
    } catch (error) {
        yield put(updateSubcategoryFailure(error));
    }
}

function* subcategoryWatcher(){
    yield all([
        takeEvery(GET_SUBCATEGORY_ATTEMPT, getSubcategoriesFlow),
        takeEvery(CREATE_SUBCATEGORY_ATTEMPT, createSubcategoriesFlow),
        takeEvery(UPDATE_SUBCATEGORY_ATTEMPT, updateSubcategoriesFlow)
    ])
}

export default subcategoryWatcher;
