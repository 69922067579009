import React from 'react';
import {Formik} from "formik";
import Images from "../../assets/images/forgot-password.svg";
import {Link} from "react-router-dom";

export const SendToken = ({
                              handle,
                              error,
                              requesting,
                              requestingMe
}) => {

    return (
        <div>
            <Formik
                initialValues={{ email: ''}}
                onSubmit={(values ) => {
                    handle(values)
                }}
            >
                {({
                      handleChange,
                      handleSubmit,

                  }) => (
                    <form className="row g-1 p-3 p-md-4" onSubmit={handleSubmit}>
                        <div className="col-12 text-center mb-1">
                            <img src={Images} className="w240 mb-4" alt="" />
                        </div>
                        <div className="text-center mb-4">
                            <span>Escribe tu correo para enviarte un código.</span>
                        </div>
                        {error?.message && (
                            <div className='text-danger fs-12'>
                                {error.message}
                            </div>
                        )}
                        <div className="col-12">
                            <div className="mb-2">
                                <label className="form-label">Correo electrónico</label>
                                <input type="email"
                                       className="form-control form-control-lg"
                                       placeholder="correo@example.com"
                                       name={'email'}
                                       autoComplete={"off"}
                                       onChange={handleChange}
                                />
                            </div>
                            {error.email && <div className="text-danger fs-12">{error.email}</div>}
                        </div>
                        <div className="col-12 text-center mt-4">
                            {requesting && (
                                <button className="btn btn-primary lift btn-spinner">
                                    <i className="fa fa-spinner fa-spin"></i>
                                </button>
                            )}
                            {!requesting && (
                                <button type={"submit"}
                                        disabled={requestingMe}
                                        className="btn btn-primary btn-lg lift">
                                    Enviar Token
                                </button>
                            )}
                        </div>
                        <div className="col-12 text-center mt-4">
                            <Link to={'/'} className="text-secondary">Volver</Link>
                        </div>
                    </form>
                )}
            </Formik>
        </div>
    )
};
