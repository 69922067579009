import React from "react";
import Modal from "react-bootstrap/Modal";
import {Formik} from "formik";
import {useDispatch, useSelector} from "react-redux";
import {createDamagedPacking} from "../../redux/damagedPacking/actions";

export const AddDamagedPacking = ({
                             visible,
                             close,
                             focus,
}) => {

    const dispatch = useDispatch();

    const {token} = useSelector(state => state.auth);
    const {createDamagedPackingRequesting} = useSelector(state => state.damagedPacking);

    const handleDamagedPacking = (values) => {
        let body = {
            packing_id: focus.id,
            quantity: parseInt(values.quantity),
            reason: values.reason,
        };
        dispatch(createDamagedPacking(token, body));
    };

    return (
        <Modal show={visible} onHide={close} style={{ display: 'block' }}>
            <div className="modal-content">
                <Modal.Header className="modal-header" closeButton>
                    <h5 className="modal-title  fw-bold" id="addProduct">{`Agregar empaque averiado`}</h5>
                </Modal.Header>
                <Formik
                    initialValues={{ quantity: '', reason: '' }}
                    onSubmit={(values ) => {
                        handleDamagedPacking(values)
                    }}
                >
                    {({
                          handleChange,
                          handleSubmit,
                      }) => (
                        <form onSubmit={handleSubmit}>
                            <Modal.Body className="modal-body">
                                <div className="mb-3 mt-1">
                                    <label className="mt-2 mb-1 form-label">{`Cantidad a agregar`}</label>
                                    <input type="text" className="form-control"
                                           placeholder={'0'}
                                           name={'quantity'}
                                           onChange={handleChange}
                                    />
                                    <textarea name="reason"
                                              rows="4"
                                              placeholder={'Escribe la razón de la avería'}
                                              className="form-control mt-2"
                                              onChange={handleChange}
                                    />
                                </div>
                            </Modal.Body>
                            <div className="modal-footer">
                                {!createDamagedPackingRequesting && (
                                    <button className="btn btn-secondary col-sm-4"
                                            onClick={close}
                                            data-bs-dismiss="modal">Cancelar</button>
                                )}
                                {createDamagedPackingRequesting && (
                                    <button className="btn btn-primary lift btn-spinner container-spinner col-sm-4">
                                        <i className="fa fa-spinner fa-spin"></i>
                                    </button>
                                )}
                                {!createDamagedPackingRequesting && (
                                    <button type={"submit"}
                                            className="btn btn-primary col-sm-4">
                                        Agregar avería
                                    </button>
                                )}
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </Modal>
    )
};
