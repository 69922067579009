import {all, fork} from 'redux-saga/effects';
import AuthSagas from './auth/sagas';
import ForgotPasswordSagas from './forgotPassword/sagas';
import SubcategorySagas from './subcategory/sagas';
import ProductSagas from './product/sagas';
import OrderSagas from './order/sagas';
import AccountSagas from './account/sagas';
import PaymentSagas from './payment/sagas';
import BuyerSagas from './buyer/sagas';
import CategorySagas from './category/sagas';
import ProviderSagas from './provider/sagas';
import PackingSagas from './packing/sagas';
import SectionSagas from './section/sagas';
import DashboardSagas from './dashboard/sagas';
import DamagedProductSagas from './damagedProduct/sagas';
import StateSagas from './state/sagas';
import DamagedPackingSagas from './damagedPacking/sagas';

export function* IndexSagas() {
    yield all([
        fork(AuthSagas),
        fork(ForgotPasswordSagas),
        fork(SubcategorySagas),
        fork(ProductSagas),
        fork(OrderSagas),
        fork(AccountSagas),
        fork(PaymentSagas),
        fork(BuyerSagas),
        fork(CategorySagas),
        fork(ProviderSagas),
        fork(PackingSagas),
        fork(SectionSagas),
        fork(DashboardSagas),
        fork(DamagedProductSagas),
        fork(StateSagas),
        fork(DamagedPackingSagas)
    ]);
}
