import {
    CLEAN_SECTION,
    CREATE_SECTION_ATTEMPT,
    CREATE_SECTION_FAILURE,
    CREATE_SECTION_SUCCESS,
    GET_SECTIONS_ATTEMPT,
    GET_SECTIONS_FAILURE,
    GET_SECTIONS_SUCCESS,
    UPDATE_ACTIVE_SECTION_ATTEMPT, UPDATE_ACTIVE_SECTION_FAILURE, UPDATE_ACTIVE_SECTION_SUCCESS,
    UPDATE_SECTION_ATTEMPT,
    UPDATE_SECTION_FAILURE,
    UPDATE_SECTION_SUCCESS
} from "./constants";

export const getSections = token => ({
    type: GET_SECTIONS_ATTEMPT,
    token,
});

export const getSectionsSuccess = sections => ({
    type: GET_SECTIONS_SUCCESS,
    sections,
});

export const getSectionsFailure = error => ({
    type: GET_SECTIONS_FAILURE,
    error,
});

export const createSection = (token, values) => ({
    type: CREATE_SECTION_ATTEMPT,
    token,
    values
});

export const createSectionSuccess = section => ({
    type: CREATE_SECTION_SUCCESS,
    section,
});

export const createSectionFailure = error => ({
    type: CREATE_SECTION_FAILURE,
    error,
});

export const updateSection = (token, values) => ({
    type: UPDATE_SECTION_ATTEMPT,
    token,
    values,
});

export const updateSectionSuccess = section => ({
    type: UPDATE_SECTION_SUCCESS,
    section,
});

export const updateSectionFailure = error => ({
    type: UPDATE_SECTION_FAILURE,
    error,
});

export const updateActiveSection = (token, values) => ({
    type: UPDATE_ACTIVE_SECTION_ATTEMPT,
    token,
    values,
});

export const updateActiveSectionSuccess = section => ({
    type: UPDATE_ACTIVE_SECTION_SUCCESS,
    section,
});

export const updateActiveSectionFailure = error => ({
    type: UPDATE_ACTIVE_SECTION_FAILURE,
    error,
});

export const cleanSection = () => ({
    type: CLEAN_SECTION
});
