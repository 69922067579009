export const CREATE_DAMAGED_PACKING_ATTEMPT = 'CREATE_DAMAGED_PACKING_ATTEMPT';
export const CREATE_DAMAGED_PACKING_SUCCESS = 'CREATE_DAMAGED_PACKING_SUCCESS';
export const CREATE_DAMAGED_PACKING_FAILURE = 'CREATE_DAMAGED_PACKING_FAILURE';

export const GET_DAMAGED_PACKING_ATTEMPT = 'GET_DAMAGED_PACKING_ATTEMPT';
export const GET_DAMAGED_PACKING_SUCCESS = 'GET_DAMAGED_PACKING_SUCCESS';
export const GET_DAMAGED_PACKING_FAILURE = 'GET_DAMAGED_PACKING_FAILURE';

export const UPDATE_DAMAGED_PACKING_ATTEMPT = 'UPDATE_DAMAGED_PACKING_ATTEMPT';
export const UPDATE_DAMAGED_PACKING_SUCCESS = 'UPDATE_DAMAGED_PACKING_SUCCESS';
export const UPDATE_DAMAGED_PACKING_FAILURE = 'UPDATE_DAMAGED_PACKING_FAILURE';

export const CLEAN_DAMAGED_PACKING = 'CLEAN_DAMAGED_PACKING';
