import {all, call, put, takeEvery} from 'redux-saga/effects'
import {getDashboardFailure, getDashboardSuccess} from "./actions";
import {GET_DASHBOARD_ATTEMPT} from "./constants";

const URL = `${process.env.REACT_APP_API_KEY}`;

const getDashboardAPI = token => {
    return fetch(`${URL}dashboard`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    })
        .then(response => response.json())
        .then(json => {
            if (json.code === 200){
                return json.dashboard;
            }
        }).catch((error) => {
            throw error;
        })
}

function* getDashboardFlow(action) {
    try {
        const {token} = action;
        const dashboard = yield call(getDashboardAPI, token);
        yield put(getDashboardSuccess(dashboard));
        localStorage.setItem('@thivisha_dashboard', JSON.stringify(dashboard));
    } catch (error) {
        yield put(getDashboardFailure(error));
    }
}

function* dashboardWatcher() {
    yield all([
        takeEvery(GET_DASHBOARD_ATTEMPT, getDashboardFlow),
    ])
}

export default dashboardWatcher;
