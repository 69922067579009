export const SEND_TOKEN_ATTEMPT = 'SEND_TOKEN_ATTEMPT';
export const SEND_TOKEN_SUCCESS = 'SEND_TOKEN_SUCCESS';
export const SEND_TOKEN_FAILURE = 'SEND_TOKEN_FAILURE';

export const VERIFY_TOKEN_ATTEMPT = 'VERIFY_TOKEN_ATTEMPT';
export const VERIFY_TOKEN_SUCCESS = 'VERIFY_TOKEN_SUCCESS';
export const VERIFY_TOKEN_FAILURE = 'VERIFY_TOKEN_FAILURE';

export const RESET_PASSWORD_ATTEMPT = 'RESET_PASSWORD_ATTEMPT';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export const CLEAN_RESET_PASSWORD = 'CLEAN_RESET_PASSWORD';
