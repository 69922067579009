import {
    SEND_TOKEN_ATTEMPT,
    SEND_TOKEN_SUCCESS,
    SEND_TOKEN_FAILURE,
    VERIFY_TOKEN_ATTEMPT,
    VERIFY_TOKEN_SUCCESS, RESET_PASSWORD_ATTEMPT, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAILURE, CLEAN_RESET_PASSWORD
} from "./constants";

export const sendToken = email => ({
    type: SEND_TOKEN_ATTEMPT,
    email,
});

export const sendTokenSuccess = message => ({
    type: SEND_TOKEN_SUCCESS,
    message,
});

export const sendTokenFailure = error => ({
    type: SEND_TOKEN_FAILURE,
    error,
});

export const verifyToken = values => ({
    type: VERIFY_TOKEN_ATTEMPT,
    values,
});

export const verifyTokenSuccess = message => ({
    type: VERIFY_TOKEN_SUCCESS,
    message,
});

export const verifyTokenFailure = error => ({
    type: VERIFY_TOKEN_SUCCESS,
    error,
});

export const resetPassword = values => ({
    type: RESET_PASSWORD_ATTEMPT,
    values,
});

export const resetPasswordSuccess = message => ({
    type: RESET_PASSWORD_SUCCESS,
    message,
});

export const resetPasswordFailure = error => ({
    type: RESET_PASSWORD_FAILURE,
    error,
});

export const cleanResetPassword = () => ({
    type: CLEAN_RESET_PASSWORD,
});
