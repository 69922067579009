import {useState} from "react";

export const useProductStockReference = () => {
    const [addStock, setAddStock] = useState([]);

    const handleAddStock = (values) => {
        let referenceId = addStock.find(stock => stock.reference_id === values.reference_id);
        if(referenceId === undefined){
            setAddStock([...addStock, values])
        }else{
            let referenceStock = addStock.map((stock) => (
                stock.reference_id === values.reference_id ? values : stock
            ))
            let referenceClean = referenceStock.filter(reference => reference.stock !== '');
            setAddStock(referenceClean)
        }
    };

    const cleanAddStock = () => {
        setAddStock([]);
    };

    return {handleAddStock, cleanAddStock, addStock};
}
