import {all, call, put, takeEvery} from 'redux-saga/effects'
import {
    addStockPackingFailure,
    addStockPackingSuccess, allGetPackingFailure, allGetPackingSuccess,
    createPackingFailure,
    createPackingSuccess,
    getPackingFailure,
    getPackingSuccess, updateImagePackingFailure, updateImagePackingSuccess, updatePackingFailure,
    updatePackingSuccess
} from "./actions";
import {
    ADD_STOCK_PACKING_ATTEMPT, ALL_GET_PACKING_ATTEMPT,
    CREATE_PACKING_ATTEMPT,
    GET_PACKING_ATTEMPT, UPDATE_IMAGE_PACKING_ATTEMPT,
    UPDATE_PACKING_ATTEMPT
} from "./constants";

const URL = `${process.env.REACT_APP_API_KEY}`;

const getPackingAPI = (token, page) => {
    return fetch(`${URL}packing/packing?page=${page}&top=10`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    })
        .then(response => response.json())
        .then(json => {
            if (json.data.length === 0){
                throw 'No hay paquetes';
            }
            if (json.data.length > 0){
                return json;
            }
        }).catch((error) => {
            throw error;
        })
}

function* getPackingFlow(action) {
    try {
        const {token, page} = action;
        const packing = yield call(getPackingAPI, token, page);
        yield put(getPackingSuccess(packing));
    } catch (error) {
        yield put(getPackingFailure(error));
    }
}

const createPackingAPI = (token, values, photo) => {

    let images = photo.map((photo, index) => {
        return {url: photo.path, name: photo.name, position: index + 1 }
    });

    let body =   {
        name: values?.name ? values.name : '',
        sku: values?.sku ? values.sku : '',
        stock: values?.stock ? values.stock : '',
        sale_price: values?.sale_price ? values.sale_price : '',
        purchase_price: values?.purchase_price ? values.purchase_price : '',
        images: images,
    }

    return fetch(`${URL}packing/packing`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
    })
        .then(response => response.json())
        .then(json => {
            if(json?.errors){
                throw json.errors;
            }
            if (json.code === 400){
                throw json.message;
            }
            if (json.code === 200){
                return json.packing;
            }
        }).catch((error) => {
            throw error;
        })
};

function* createPackingFlow(action) {
    try {
        const {token, values, photo} = action;
        const packing = yield call(createPackingAPI, token, values, photo);
        yield put(createPackingSuccess(packing));
    } catch (error) {
        yield put(createPackingFailure(error));
    }
}

const updatePackingAPI = (token, values, focusPacking) => {

    let body = {
        '_method': 'PUT',
        name: values.name !== '' ? values.name : focusPacking.name,
        sku: values.sku !== '' ? values.sku : focusPacking.sku,
        stock: values.stock !== '' ? parseInt(values.stock) : focusPacking.stock,
        sale_price: values.sale_price !== '' ? values.sale_price : focusPacking.sale_price,
        purchase_price: values.purchase_price !== '' ? values.purchase_price : focusPacking.purchase_price,
    }

    return fetch(`${URL}packing/packing/${focusPacking.id}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
    })
        .then(response => response.json())
        .then(json => {
            if(json?.errors){
                throw json.errors;
            }
            if (json.code === 400){
                throw json.message;
            }
            if (json.code === 200){
                return json.packing;
            }
        }).catch((error) => {
            throw error;
        })
};

function* updateProductFlow(action) {
    try {
        const {token, values, focusPacking} = action;
        const packing = yield call(updatePackingAPI, token, values, focusPacking);
        yield put(updatePackingSuccess(packing));
    } catch (error) {
        yield put(updatePackingFailure(error));
    }
}

const addStockPackingAPI = (token, values) => {

    return fetch(`${URL}packing/add_stock`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(values),
    })
        .then(response => response.json())
        .then(json => {
            if(json?.errors){
                throw json.errors;
            }
            if (json.code === 400){
                throw json;
            }
            if (json.code === 200){
                return json.packing;
            }
        }).catch((error) => {
            throw error;
        })
};

function* addStockPackingFlow(action) {
    try {
        const {token, values} = action;
        const packing = yield call(addStockPackingAPI, token, values);
        yield put(addStockPackingSuccess(packing));
    } catch (error) {
        yield put(addStockPackingFailure(error));
    }
}

const updateImagePackingAPI = (token, values) => {

    return fetch(`${URL}images/packing`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(values),
    })
        .then(response => response.json())
        .then(json => {
            if(json?.errors){
                throw json.errors;
            }
            if (json.code === 400){
                throw json.message;
            }
            if (json.code === 200){
                return json.packing;
            }
        }).catch((error) => {
            throw error;
        })
};

function* updateImagePackingFlow(action) {
    try {
        const {token, values} = action;
        const product = yield call(updateImagePackingAPI, token, values);
        yield put(updateImagePackingSuccess(product));
    } catch (error) {
        yield put(updateImagePackingFailure(error));
    }
}

const getAllPackingAPI = (token) => {
    return fetch(`${URL}packing/all_packing`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    })
        .then(response => response.json())
        .then(json => {
            if (json.data.length === 0){
                throw 'No hay paquetes';
            }
            if (json.data.length > 0){
                return json.data;
            }
        }).catch((error) => {
            throw error;
        })
}

function* getAllPackingFlow(action) {
    try {
        const {token} = action;
        const allPacking = yield call(getAllPackingAPI, token);
        yield put(allGetPackingSuccess(allPacking));
    } catch (error) {
        yield put(allGetPackingFailure(error));
    }
}

function* packingWatcher(){
    yield all([
        takeEvery(GET_PACKING_ATTEMPT, getPackingFlow),
        takeEvery(CREATE_PACKING_ATTEMPT, createPackingFlow),
        takeEvery(UPDATE_PACKING_ATTEMPT, updateProductFlow),
        takeEvery(ADD_STOCK_PACKING_ATTEMPT, addStockPackingFlow),
        takeEvery(UPDATE_IMAGE_PACKING_ATTEMPT, updateImagePackingFlow),
        takeEvery(ALL_GET_PACKING_ATTEMPT, getAllPackingFlow)
    ]);
}
export default packingWatcher;
