import {
    CLEAN_ACCOUNT,
    GET_ACCOUNTS_ATTEMPT,
    GET_ACCOUNTS_FAILURE,
    GET_ACCOUNTS_SUCCESS,
    POST_SHOULD_ATTEMPT, POST_SHOULD_FAILURE,
    POST_SHOULD_SUCCESS
} from "./constants";

const initialState = {
    getAccountRequesting: false,
    getAccountSuccess: false,
    getAccountError: '',
    accounts: [],
    linkAccounts: {},
    metaAccounts: {},
    pageAccounts: 0,
    postShouldRequesting: false,
    postShouldSuccess: false,
    postShouldError: '',
}

const reducer = (state = initialState, action) => {
    switch (action.type){
        case GET_ACCOUNTS_ATTEMPT:
            return {
                ...state,
                getAccountRequesting: true,
                pageAccounts: action.page,
            };
        case GET_ACCOUNTS_SUCCESS:
            return {
                ...state,
                getAccountRequesting: false,
                getAccountSuccess: true,
                accounts: action.accounts.data,
                linkAccounts: action.accounts.links,
                metaAccounts: action.accounts.meta,
            };
        case GET_ACCOUNTS_FAILURE:
            return {
                ...state,
                getAccountRequesting: false,
                getAccountError: action.error,
            };
        case POST_SHOULD_ATTEMPT:
            return {
                ...state,
                postShouldRequesting: true,
                postShouldSuccess: false,
            };
        case POST_SHOULD_SUCCESS:
            return {
                ...state,
                postShouldRequesting: false,
                postShouldSuccess: true,
                accounts: state.accounts.map(account => (
                    account.id === action.account.id ? action.account : account
                ))
            };
        case POST_SHOULD_FAILURE:
            return {
                ...state,
                postShouldRequesting: false,
                postShouldError: action.error,
            }
        case CLEAN_ACCOUNT:
            return {
                ...state,
                getAccountRequesting: false,
                getAccountSuccess: false,
                getAccountError: '',
                accounts: [],
                linkAccounts: {},
                metaAccounts: {},
                pageAccounts: 0,
                postShouldRequesting: false,
                postShouldSuccess: false,
                postShouldError: '',
            };
        default:
            return state;
    }
}

export default reducer;
