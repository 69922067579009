import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import Logo from "../../assets/images/logo.png";
import {useDispatch, useSelector} from "react-redux";
import { Formik } from 'formik';
import {cleanLogin, login} from "../../redux/auth/actions";
import {cleanResetPassword} from "../../redux/forgotPassword/actions";
import {AuthLeft} from "../../components/Auth/AuthLeft";
import {userAuthenticated} from "../../constants/UserStorage";

export const Login = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {requesting, error, requestingMe,
        successMe, errorMe, logoutSuccess} = useSelector(state => state.auth);
    const {messageResetPassword} = useSelector(state => state.forgotPassword);

    const [seePassword, setSeePassword] = useState(false);

    const loginUser = (data) => {
        let values = {
            'email': data.email,
            'password': data.password,
        }
        dispatch(login(values));
    };

    useEffect(() => {
        if(successMe){
            navigate('/dashboard')
            dispatch(cleanLogin());
            dispatch(cleanResetPassword());
        }
    }, [successMe]);

    useEffect(() => {
        if(!logoutSuccess)
            userAuthenticated(dispatch, navigate, errorMe);
    }, [errorMe]);

    return (
        <div className='main p-2 py-3 p-xl-5 '>
            <div className='body d-flex p-0 p-xl-5'>
                <div className='container-xxl'>
                    <div className='row g-0'>
                        <AuthLeft />
                        <div className="col-lg-6 d-flex justify-content-center align-items-center border-0 rounded-lg auth-h100 " >
                            <div className="w-100 p-3 p-md-5 card border-0 shadow-sm" style={{ maxwidth: "32rem" }}>

                                <Formik
                                    initialValues={{ email: '', password: ''}}
                                    onSubmit={(values ) => {
                                        loginUser(values)
                                    }}
                                >
                                    {({
                                          handleChange,
                                          handleSubmit,
                                      }) => (
                                        <form className="row g-1 p-3 p-md-4 mt-5"
                                              onSubmit={handleSubmit}>
                                            <div className={'d-flex justify-content-center'}>
                                                <img style={{width: 100, height: 80}}  src={Logo} alt="logo" />
                                            </div>
                                            <div className="col-12 text-center mb-0">
                                                <h1>Login</h1>
                                            </div>
                                            {error?.message && (
                                                <div className='text-danger fs-12'>
                                                    {error.message}
                                                </div>
                                            )}
                                            {messageResetPassword !== '' && (
                                                <div className='text-success fs-12 mb-3'>
                                                    {messageResetPassword}
                                                </div>
                                            )}
                                            <div className="col-12">
                                                <div className="mb-2">
                                                    <label className="form-label">Correo electrónico</label>
                                                    <input type="email"
                                                           className="form-control form-control-lg lift"
                                                           placeholder="correo@example.com"
                                                           name={'email'}
                                                           autoComplete={"off"}
                                                           onChange={handleChange}
                                                    />
                                                </div>
                                                {error.email && <div className="text-danger fs-12">{error.email}</div>}
                                            </div>
                                            <div className="col-12">
                                                <div className="mb-3">
                                                    <div className="form-label">
                                <span className="d-flex justify-content-between align-items-center">
                                    Contraseña
                                </span>
                                                    </div>
                                                    <input type={seePassword ? 'text' : 'password' }
                                                           className="form-control form-control-lg lift"
                                                           placeholder="***************"
                                                           name={'password'}
                                                           autoComplete={"off"}
                                                           onChange={handleChange}
                                                    />
                                                    <i onClick={() => setSeePassword(!seePassword)}
                                                       className={`fa ${seePassword ? 'fa-regular fa-eye' : 'fa-regular fa-eye-slash'} icon-password`}></i>
                                                </div>
                                                {error.password && <div className="text-danger fs-12">{error.password}</div>}
                                            </div>
                                            <div className="col-12">
                                                <Link className="text-muted" to={"/reset_password"}>¿Ha olvidado la contraseña?</Link>
                                            </div>
                                            <div className="col-12 text-center mt-4">
                                                {(requesting || requestingMe) && (
                                                    <button className="btn btn-primary lift btn-spinner">
                                                        <i className="fa fa-spinner fa-spin"></i>
                                                    </button>
                                                )}
                                                {(!requesting && !requestingMe) && (
                                                    <button type='submit'
                                                            disabled={requesting || requestingMe}
                                                            className="btn btn-primary btn-lg lift text-uppercase" >Iniciar Sesión</button>
                                                )}
                                            </div>
                                        </form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
