import {
    CLEAN_SUBCATEGORY,
    CREATE_SUBCATEGORY_ATTEMPT, CREATE_SUBCATEGORY_FAILURE, CREATE_SUBCATEGORY_SUCCESS,
    GET_SUBCATEGORY_ATTEMPT,
    GET_SUBCATEGORY_FAILURE,
    GET_SUBCATEGORY_SUCCESS, UPDATE_SUBCATEGORY_ATTEMPT, UPDATE_SUBCATEGORY_FAILURE, UPDATE_SUBCATEGORY_SUCCESS
} from "./constants";

const initialState = {
    requestingSubcategory: false,
    successSubcategory: false,
    errorSubcategory: '',
    subcategories: [],
    createSubcategoryRequesting: false,
    createSubcategorySuccess: false,
    createSubcategoryError: '',
    updateSubcategoryRequesting: false,
    updateSubcategorySuccess: false,
    updateSubcategoryError: '',
}

const reducer = (state = initialState, action) => {
    switch (action.type){
        case GET_SUBCATEGORY_ATTEMPT:
            return {
                ...state,
                requestingSubcategory: true,
            };
        case GET_SUBCATEGORY_SUCCESS:
            return {
                ...state,
                requestingSubcategory: false,
                successSubcategory: true,
                subcategories: action.subcategories,
            };
        case GET_SUBCATEGORY_FAILURE:
            return {
                ...state,
                requestingSubcategory: false,
                errorSubcategory: action.error,
            };
        case CREATE_SUBCATEGORY_ATTEMPT:
            return {
                ...state,
                createSubcategoryRequesting: true,
                createSubcategorySuccess: false,
                updateSubcategorySuccess: false,
            };
        case CREATE_SUBCATEGORY_SUCCESS:
            return {
                ...state,
                createSubcategoryRequesting: false,
                createSubcategorySuccess: true,
                subcategories: [ action.subcategory, ...state.subcategories]
            };
        case CREATE_SUBCATEGORY_FAILURE:
            return {
                ...state,
                createSubcategoryRequesting: false,
                createSubcategoryError: action.error,
            };
        case UPDATE_SUBCATEGORY_ATTEMPT:
            return {
                ...state,
                updateSubcategoryRequesting: true,
                updateSubcategorySuccess: false,
                createSubcategorySuccess: false,
            };
        case UPDATE_SUBCATEGORY_SUCCESS:
            return {
                ...state,
                updateSubcategoryRequesting: false,
                updateSubcategorySuccess: true,
                subcategories: state.subcategories.map(subcategory => (
                    subcategory.id === action.subcategory.id ? action.subcategory : subcategory
                ))
            };
        case UPDATE_SUBCATEGORY_FAILURE:
            return {
                ...state,
                updateSubcategoryRequesting: false,
                updateSubcategoryError: action.error,
            };
        case CLEAN_SUBCATEGORY:
            return {
                ...state,
                requestingSubcategory: false,
                successSubcategory: false,
                errorSubcategory: '',
                subcategories: [],
                createSubcategoryRequesting: false,
                createSubcategorySuccess: false,
                createSubcategoryError: '',
                updateSubcategoryRequesting: false,
                updateSubcategorySuccess: false,
                updateSubcategoryError: '',
            };
        default:
            return state;
    }
}

export default reducer;
