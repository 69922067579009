import React, {useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import {Formik} from "formik";
import {FormatNumber} from "../../constants/FormatNumber";
import {useDispatch, useSelector} from "react-redux";
import {updateProduct} from "../../redux/product/actions";

export const EditProduct = ({
                               visible,
                               close,
                               focus,
}) => {

    const dispatch = useDispatch();

    const {token} = useSelector(state => state.auth);
    const {subcategories} = useSelector(state => state.subcategory);
    const {updateProductRequesting, updateProductError} = useSelector(state => state.product);
    const {providers} = useSelector(state => state.provider);
    const {categories} = useSelector(state => state.category);

    const [newSubcategories, setNewSubcategories] = useState(subcategories.filter(subcategory => subcategory.category.id === focus.category.id));
    const [focusCategory, setFocusCategory] = useState(focus.category.id);

    const changeCategory = (category) => {
        if(category !== 0){
            setFocusCategory(parseInt(category.target.value))
            let subcategoriesShort = subcategories.filter(subcategory => subcategory.category.id === parseInt(category.target.value));
            setNewSubcategories(subcategoriesShort.sort(function (a,b) {
                return a.name.localeCompare(b.name)
            }));
        }else {
            setFocusCategory(focus.category.id)
            setNewSubcategories([]);
        }
    }

    const handleUpdateProduct = (values) => {
        dispatch(updateProduct(token, values, focus));
    };

    return (
        <Modal show={visible} onHide={close} style={{ display: 'block' }}>
            <div className="modal-content">
                <Modal.Header className="modal-header" closeButton>
                    <h5 className="modal-title  fw-bold" id="addProduct">Editar producto</h5>
                </Modal.Header>
                <Formik
                    initialValues={{ name: '', sku: '', stock: '',
                        sale_price: '', purchase_price: '', description: '',
                        sub_category_id: '', provider_id: ''}}
                    onSubmit={(values ) => {
                        handleUpdateProduct(values)
                    }}
                >
                    {({
                          handleChange,
                          handleSubmit,
                          setFieldValue,
                      }) => (
                        <form onSubmit={handleSubmit}>
                            <Modal.Body className="modal-body">
                                <div className="deadline-form">
                                    <div className="row g-3 mb-3">
                                        <div className="col-sm-12">
                                            <label htmlFor="it em100" className="form-label">Nombre</label>
                                            <input type="text"
                                                   className="form-control"
                                                   name={'name'}
                                                   onChange={handleChange}
                                                   defaultValue={focus.name}
                                            />
                                            {updateProductError.name && <div className="text-danger fs-12">
                                                {updateProductError.name}
                                            </div>}
                                        </div>
                                        <div className="col-sm-12">
                                            <label htmlFor="it    em100" className="form-label">Sku</label>
                                            <input type="text"
                                                   className="form-control"
                                                   name={'sku'}
                                                   onChange={handleChange}
                                                   defaultValue={focus.sku}
                                            />
                                            {updateProductError.sku && <div className="text-danger fs-12">
                                                {updateProductError.sku}
                                            </div>}
                                        </div>
                                        {focus.references.length === 0 && (
                                            <div className="col-sm-12">
                                                <label htmlFor="it    em100" className="form-label">Stock</label>
                                                <input type="numeric"
                                                       className="form-control"
                                                       name={'stock'}
                                                       onChange={handleChange}
                                                       defaultValue={focus.stock}
                                                />
                                                {updateProductError.stock && <div className="text-danger fs-12">
                                                    {updateProductError.stock}
                                                </div>}
                                            </div>
                                        )}
                                        <div className="col-sm-12">
                                            <label htmlFor="it    em100" className="form-label">Precio de venta</label>
                                            <FormatNumber
                                                className="form-control"
                                                onValueChange={(value) => setFieldValue('sale_price', value.floatValue)}
                                                value={focus.sale_price}
                                            />
                                            {updateProductError.sale_price && <div className="text-danger fs-12">
                                                {updateProductError.sale_price}
                                            </div>}
                                        </div>
                                        <div className="col-sm-12">
                                            <label htmlFor="it    em100" className="form-label">Precio de compra</label>
                                            <FormatNumber
                                                className="form-control"
                                                onValueChange={(value) => setFieldValue('purchase_price', value.floatValue)}
                                                value={focus.purchase_price}
                                            />
                                            {updateProductError.purchase_price && <div className="text-danger fs-12">
                                                {updateProductError.purchase_price}
                                            </div>}
                                        </div>
                                        <div className="col-sm-12">
                                            <label className="form-label">Descripción</label>
                                            <textarea className="form-control"
                                                      rows="3"
                                                      name={'description'}
                                                      onChange={handleChange}
                                                      defaultValue={focus.description}
                                            />
                                        </div>
                                        <div className="col-sm-12">
                                            <label className="form-label">Proveedor</label>
                                            <select name={'provider_id'} className="form-control"
                                                    defaultValue={focus.provider.id}
                                                    onChange={handleChange}>
                                                {providers.map((provider, index) => (
                                                    <option
                                                        key={index}
                                                        value={provider.id}>
                                                        {provider.name}
                                                    </option>
                                                ))}
                                            </select>
                                            {updateProductError.provider_id && <div className="text-danger fs-12">
                                                {updateProductError.provider_id}
                                            </div>}
                                        </div>
                                        <div className="col-sm-12">
                                            <label className="form-label">Categorias</label>
                                            <select className="form-control" onChange={changeCategory}
                                                    defaultValue={focus.category.id}
                                            >
                                                {categories.map((category, index) => (
                                                    <option
                                                        key={index}
                                                        value={category.id}>
                                                        {`${category.name}-${category.section.name}`}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="col-sm-12">
                                            <label className="form-label">Subcategoría</label>
                                            <select name={'sub_category_id'} className="form-control"
                                                    defaultValue={focus.sub_category.id}
                                                    onChange={handleChange}>
                                                {focusCategory !== focus.category.id && (
                                                    <option
                                                        value={0}>
                                                        Elegir subcategoria
                                                    </option>
                                                )}
                                                {newSubcategories.map((subcategory, index) => (
                                                    <option
                                                        key={index}
                                                        value={subcategory.id}>
                                                        {subcategory.name}
                                                    </option>
                                                ))}
                                            </select>
                                            {updateProductError.sub_category_id && <div className="text-danger fs-12">
                                                {updateProductError.sub_category_id}
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                            <div className="modal-footer">
                                {!updateProductRequesting && (
                                    <button type="button"
                                            className="btn btn-secondary col-sm-3"
                                            onClick={close}
                                            data-bs-dismiss="modal">Cancelar</button>
                                )}
                                {updateProductRequesting && (
                                    <button className="btn btn-primary lift btn-spinner container-spinner col-sm-3">
                                        <i className="fa fa-spinner fa-spin"></i>
                                    </button>
                                )}
                                {!updateProductRequesting && (
                                    <button type="submit" className="btn btn-primary col-sm-3">Actualizar</button>
                                )}
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </Modal>
    )
};
