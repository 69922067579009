export const GET_ALL_PAYMENTS_ATTEMPT = 'GET_ALL_PAYMENTS_ATTEMPT';
export const GET_ALL_PAYMENTS_SUCCESS = 'GET_ALL_PAYMENTS_SUCCESS';
export const GET_ALL_PAYMENTS_FAILURE = 'GET_ALL_PAYMENTS_FAILURE';

export const CREATE_PAYMENT_ATTEMPT = 'CREATE_PAYMENT_ATTEMPT';
export const CREATE_PAYMENT_SUCCESS = 'CREATE_PAYMENT_SUCCESS';
export const CREATE_PAYMENT_FAILURE = 'CREATE_PAYMENT_FAILURE';

export const UPDATE_PAYMENT_ATTEMPT = 'UPDATE_PAYMENT_ATTEMPT';
export const UPDATE_PAYMENT_SUCCESS = 'UPDATE_PAYMENT_SUCCESS';
export const UPDATE_PAYMENT_FAILURE = 'UPDATE_PAYMENT_FAILURE';

export const CLEAN_PAYMENT = 'CLEAN_PAYMENT';
