import {
    CLEAN_CATEGORY,
    CREATE_CATEGORY_ATTEMPT,
    CREATE_CATEGORY_FAILURE,
    CREATE_CATEGORY_SUCCESS,
    GET_CATEGORIES_ATTEMPT,
    GET_CATEGORIES_FAILURE,
    GET_CATEGORIES_SUCCESS,
    UPDATE_ACTIVE_CATEGORY_ATTEMPT, UPDATE_ACTIVE_CATEGORY_FAILURE, UPDATE_ACTIVE_CATEGORY_SUCCESS,
    UPDATE_CATEGORY_ATTEMPT,
    UPDATE_CATEGORY_FAILURE,
    UPDATE_CATEGORY_SUCCESS
} from "./constants";

const initialState = {
    getCategoriesRequesting: false,
    getCategoriesSuccess: false,
    getCategoriesError: '',
    categories: [],
    createCategoryRequesting: false,
    createCategorySuccess: false,
    createCategoryError: '',
    updateCategoryRequesting: false,
    updateCategorySuccess: false,
    updateActiveRequesting: false,
    updateActiveSuccess: false,
    updateActiveError: '',
    updateCategoryError: '',
}

const reducer = (state = initialState, action) => {
    switch (action.type){
        case GET_CATEGORIES_ATTEMPT:
            return {
                ...state,
                getCategoriesRequesting: true,
            };
        case GET_CATEGORIES_SUCCESS:
            return {
                ...state,
                getCategoriesRequesting: false,
                getCategoriesSuccess: true,
                categories: action.categories.data,
            };
        case GET_CATEGORIES_FAILURE:
            return {
                ...state,
                getCategoriesRequesting: false,
                getCategoriesError: action.error,
            };
        case CREATE_CATEGORY_ATTEMPT:
            return {
                ...state,
                createCategoryRequesting: true,
                createCategorySuccess: false,
                updateCategorySuccess: false,
                updateActiveSuccess: false,
            };
        case CREATE_CATEGORY_SUCCESS:
            return {
                ...state,
                createCategoryRequesting: false,
                createCategorySuccess: true,
                categories: [...state.categories, action.category],
            };
        case CREATE_CATEGORY_FAILURE:
            return {
                ...state,
                createCategoryRequesting: false,
                createCategoryError: action.error,
            };
        case UPDATE_CATEGORY_ATTEMPT:
            return {
                ...state,
                updateCategoryRequesting: true,
                updateCategorySuccess: false,
                createCategorySuccess: false,
                updateActiveSuccess: false,
            };
        case UPDATE_CATEGORY_SUCCESS:
            return {
                ...state,
                updateCategoryRequesting: false,
                updateCategorySuccess: true,
                categories: state.categories.map(category => (
                    category.id === action.category.id ? action.category : category
                ))
            };
        case UPDATE_CATEGORY_FAILURE:
            return {
                ...state,
                updateCategoryRequesting: false,
                updateCategoryError: action.error,
            };
        case UPDATE_ACTIVE_CATEGORY_ATTEMPT:
            return {
                ...state,
                updateActiveRequesting: true,
                updateActiveSuccess: false,
                updateCategorySuccess: false,
                createCategorySuccess: false,
            };
        case UPDATE_ACTIVE_CATEGORY_SUCCESS:
            return {
                ...state,
                updateActiveRequesting: false,
                updateActiveSuccess: true,
                categories: state.categories.map(category => (
                    category.id === action.category.id ? action.category : category
                ))
            };
        case UPDATE_ACTIVE_CATEGORY_FAILURE:
            return {
                ...state,
                updateActiveRequesting: false,
                updateActiveError: action.error,
            };
        case CLEAN_CATEGORY:
            return {
                ...state,
                getCategoriesRequesting: false,
                getCategoriesSuccess: false,
                getCategoriesError: '',
                categories: [],
                createCategoryRequesting: false,
                createCategorySuccess: false,
                createCategoryError: '',
                updateCategoryRequesting: false,
                updateCategorySuccess: false,
                updateCategoryError: '',
                updateActiveRequesting: false,
                updateActiveSuccess: false,
                updateActiveError: '',
            };
        default:
            return state;
    }
}

export default reducer;
