import React, {useEffect, useState} from 'react';
import {Sidebar} from "../../components/Sidebar/Sidebar";
import {useDispatch, useSelector} from "react-redux";
import {Header} from "../../components/Header/Header";
import {getDamagedProduct} from "../../redux/damagedProduct/actions";
import {TableDamagedProduct} from "../../components/DamagedProduct/Table";
import {ModalHistoriesProduct} from "../../components/DamagedProduct/ModalHistories";
import {AddMoreDamagedProduct} from "../../components/DamagedProduct/AddMoreDamagedProduct";
import {getStatesDamagedProduct} from "../../redux/state/actions";

export const DamagedProduct = () => {

    const dispatch = useDispatch();

    const {user, token, successMe, sidebarMini} = useSelector(state => state.auth);
    const {getDamagedProductSuccess, damagedProducts, metaDamagedProducts,
        pageDamagedProducts, updateDamagedProductSuccess} = useSelector(state => state.damagedProduct);
    const {getStateDamagedProductSuccess} = useSelector(state => state.state);

    const [modal, setModal] = useState('');
    const [focus, setFocus] = useState({});

    useEffect(() => {
        if(!getDamagedProductSuccess && successMe)
            dispatch(getDamagedProduct(token, 1))
        if(!getStateDamagedProductSuccess && successMe)
            dispatch(getStatesDamagedProduct(token))
    }, [successMe]);

    useEffect(() => {
        if(updateDamagedProductSuccess){
            closeModal();
        }
    }, [updateDamagedProductSuccess]);

    const closeModal = () => {
        setModal('')
    };

    const handleChangePage = (page) => {
        if(page !== pageDamagedProducts && page !== 0)
            dispatch(getDamagedProduct(token, page));
    };

    return (
        <div className={'d-flex'}>
            <Sidebar
                isSidebarMini={sidebarMini}
                routeCurrency={'/damagedProduct'} />
            <div className='main px-lg-12 px-md-4' >
                <Header user={user}/>
                <div className={`${sidebarMini ? 'table-widthSideMini': 'table-widthSide'} body d-flex py-3`}>
                    <TableDamagedProduct
                        damagedProducts={damagedProducts}
                        pageCurrency={pageDamagedProducts}
                        metaDamagedProducts={metaDamagedProducts}
                        setModal={setModal}
                        setFocus={setFocus}
                        handleChangePage={handleChangePage}
                    />
                </div>
            </div>
            {modal === 'viewHistories' && (
                <ModalHistoriesProduct
                    visible={modal === 'viewHistories'}
                    close={closeModal}
                    histories={focus.histories}
                />
            )}
            {modal === 'addMoreDamaged' && (
                <AddMoreDamagedProduct
                    close={() => {
                        closeModal();
                    }}
                    visible={modal === 'addMoreDamaged'}
                    focus={focus}
                />
            )}
        </div>
    )
};
