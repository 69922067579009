export const menuItem = [
    {
        "name": "Dashboard",
        "routerLink": "dashboard",
        "identifier": "dashboard",
        "iconClass": "icofont-dashboard-web fs-5",
        "isCategory": false,
        "isApp": false,
        "children": []
    },
    {
        "name": "Órdenes",
        "routerLink": "orders",
        "identifier": "Orders",
        "iconClass": "icofont-notepad fs-5",
        "isCategory": false,
        "isApp": false,
        "children": []
    },
    {
        "name": "Cuentas",
        "routerLink": "accounts",
        "identifier": "Accounts",
        "iconClass": "icofont-coins fs-5",
        "isCategory": false,
        "isApp": false,
        "children": []
    },
    {
        "name": "Productos",
        "routerLink": "products",
        "identifier": "Products",
        "iconClass": "icofont-truck-loaded fs-5",
        "isCategory": false,
        "isApp": false,
        "children": []
    },
    {
        "name": "Empaques",
        "routerLink": "packing",
        "identifier": "Packing",
        "iconClass": "icofont-package fs-5",
        "isCategory": false,
        "isApp": false,
        "children": []
    },
    {
        "name": "Compradores",
        "routerLink": "buyers",
        "identifier": "Buyers",
        "iconClass": "icofont-user-alt-3 fs-5",
        "isCategory": false,
        "isApp": false,
        "children": []
    },
    {
        "name": "Averiados",
        "routerLink": "",
        "identifier": "damaged",
        "iconClass": "icofont-repair",
        "isCategory": false,
        "isApp": false,
        "children": [
            {
                "name": "Productos",
                "routerLink": "damagedProduct",
                "identifier": "damagedProduct",
                "isCategory": false,
                "isApp": false,
                "iconClass": "icofont-truck-loaded fs-5",
                "children": []
            },
            {
                "name": "Empaques",
                "routerLink": "damagedPacking",
                "identifier": "damagedPacking",
                "isCategory": false,
                "isApp": false,
                "iconClass": "icofont-package fs-5",
                "children": []
            },
        ]
    },
    {
        "name": "Configuración",
        "routerLink": "",
        "identifier": "Setting",
        "iconClass": "icofont-ui-settings",
        "isCategory": false,
        "isApp": false,
        "children": [
            {
                "name": "Sección",
                "routerLink": "sections",
                "identifier": "Section",
                "isCategory": false,
                "isApp": false,
                "iconClass": "icofont-paper fs-5",
                "children": []
            },
            {
                "name": "Categorías",
                "routerLink": "categories",
                "identifier": "Category",
                "isCategory": false,
                "isApp": false,
                "iconClass": "icofont-billboard fs-5",
                "children": []
            },
            {
                "name": "Subcategorías",
                "routerLink": "subcategories",
                "identifier": "Subcategory",
                "isCategory": false,
                "isApp": false,
                "iconClass": "icofont-company fs-5",
                "children": []
            },
            {
                "name": "Proveedores",
                "routerLink": "providers",
                "identifier": "Provider",
                "isCategory": false,
                "isApp": false,
                "iconClass": "icofont-handshake-deal fs-5",
                "children": []
            },
            {
                "name": "Métodos de pago",
                "routerLink": "payments",
                "identifier": "Payment",
                "isCategory": false,
                "isApp": false,
                "iconClass": "icofont-bill-alt fs-5",
                "children": []
            },
        ]
    },
    {
        "name": "Cerrar sesión",
        "routerLink": "close",
        "identifier": "Close",
        "iconClass": "icofont-ui-power fs-5",
        "isCategory": false,
        "isApp": false,
        "children": []
    },
]
