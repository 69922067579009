import {
    CLEAN_DAMAGED_PRODUCT,
    CREATE_DAMAGED_PRODUCT_ATTEMPT,
    CREATE_DAMAGED_PRODUCT_FAILURE,
    CREATE_DAMAGED_PRODUCT_SUCCESS,
    GET_DAMAGED_PRODUCT_ATTEMPT,
    GET_DAMAGED_PRODUCT_FAILURE,
    GET_DAMAGED_PRODUCT_SUCCESS,
    UPDATE_DAMAGED_PRODUCT_ATTEMPT,
    UPDATE_DAMAGED_PRODUCT_FAILURE,
    UPDATE_DAMAGED_PRODUCT_SUCCESS
} from "./constants";

export const createDamagedProduct = (token, values) => ({
    type: CREATE_DAMAGED_PRODUCT_ATTEMPT,
    token,
    values,
});

export const createDamagedProductSuccess = (damagedProduct) => ({
    type: CREATE_DAMAGED_PRODUCT_SUCCESS,
    damagedProduct,
});

export const createDamagedProductFailure = error => ({
    type: CREATE_DAMAGED_PRODUCT_FAILURE,
    error,
});

export const getDamagedProduct = (token, page) => ({
    type: GET_DAMAGED_PRODUCT_ATTEMPT,
    token,
    page,
});

export const getDamagedProductSuccess = damagedProducts => ({
    type: GET_DAMAGED_PRODUCT_SUCCESS,
    damagedProducts,
});

export const getDamagedProductFailure = error => ({
    type: GET_DAMAGED_PRODUCT_FAILURE,
    error,
});

export const updateDamagedProduct = (token, values) => ({
    type: UPDATE_DAMAGED_PRODUCT_ATTEMPT,
    token,
    values,
});

export const updateDamagedProductSuccess = damagedProduct => ({
    type: UPDATE_DAMAGED_PRODUCT_SUCCESS,
    damagedProduct,
});

export const updateDamagedProductFailure = error => ({
    type: UPDATE_DAMAGED_PRODUCT_FAILURE,
    error,
});

export const cleanDamagedProduct = () => ({
    type: CLEAN_DAMAGED_PRODUCT,
});
