import React, {useEffect, useState} from "react";
import {Sidebar} from "../../components/Sidebar/Sidebar";
import {Header} from "../../components/Header/Header";
import {useDispatch, useSelector} from "react-redux";
import {TableProvider} from "../../components/Provider/Table";
import {createProvider, getProviders, updateProvider} from "../../redux/provider/actions";
import {AddProvider} from "../../components/Provider/AddProvider";
import {EditProvider} from "../../components/Provider/EditProvider";
import {toast, ToastContainer} from "react-toastify";

export const Provider = () => {

    const dispatch = useDispatch();

    const {user, successMe, token, sidebarMini} = useSelector(state => state.auth);
    const {providers, getProvidersSuccess, createProviderSuccess,
    createProviderError, createProviderRequesting, updateProviderError,
        updateProviderRequesting, updateProviderSuccess} = useSelector(state => state.provider);

    const [modalProvider, setModalProvider] = useState(false);
    const [modalEditProvider, setModalEditProvider] = useState(false);
    const [focusProvider, setFocusProvider] = useState({});

    const handleCreateProvider = values => {
        dispatch(createProvider(token, values));
    };

    const handleUpdateProvider = values => {
        let body = {
            '_method': 'PUT',
            id: focusProvider.id,
            name: values.name !== '' ? values.name : focusProvider.name,
        }
        dispatch(updateProvider(token, body));
    };

    useEffect(() => {
        if(!getProvidersSuccess && successMe)
            dispatch(getProviders(token));
    }, []);

    useEffect(() => {
        if(createProviderSuccess){
            setModalProvider(false);
            toast("Proveedor creada", {type: 'success'});
        }
        if(updateProviderSuccess){
            setModalEditProvider(false);
            toast("Proveedor modificado", {type: 'success'});
        }
    }, [createProviderSuccess, updateProviderSuccess]);

    return (
        <div className={'d-flex'}>
            <Sidebar isSidebarMini={sidebarMini}
                     routeCurrency={'/providers'} />
            <div className='main px-lg-12 px-md-4' >
                <Header
                    search={false}
                    user={user}
                />
                <button
                    type="button"
                    className="btn btn-primary align-self-end"
                    onClick={() => setModalProvider(true)}
                >
                    Crear proveedor
                </button>
                <div className={`${sidebarMini ? 'table-widthSideMini': 'table-widthSide'} body d-flex py-3`}>
                    <div className={'table-widthMiniCategory'}>
                        <TableProvider
                            providers={providers}
                            setModalEditProvider={setModalEditProvider}
                            setFocusProvider={setFocusProvider}
                        />
                    </div>
                </div>
                <AddProvider
                    visible={modalProvider}
                    close={() => setModalProvider(false)}
                    error={createProviderError}
                    createProvider={handleCreateProvider}
                    requesting={createProviderRequesting}
                />
                <EditProvider
                    visible={modalEditProvider}
                    close={() => setModalEditProvider(false)}
                    error={updateProviderError}
                    update={handleUpdateProvider}
                    requesting={updateProviderRequesting}
                    focus={focusProvider}
                />
                <ToastContainer autoClose={3000} />
            </div>
        </div>
    )
}
