import {all, call, put, takeEvery} from 'redux-saga/effects'
import {
    createProviderFailure,
    createProviderSuccess,
    getProvidersFailure,
    getProvidersSuccess, updateProviderFailure,
    updateProviderSuccess
} from "./actions";
import {CREATE_PROVIDER_ATTEMPT, GET_PROVIDERS_ATTEMPT, UPDATE_PROVIDER_ATTEMPT} from "./constants";

const URL = `${process.env.REACT_APP_API_KEY}`;

const getProvidersAPI = token => {
    return fetch(`${URL}providers/providers`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    })
        .then(response => response.json())
        .then(json => {
            if (json.data.length === 0){
                throw 'No hay proveedores';
            }
            if (json.data.length > 0){
                return json;
            }
        }).catch((error) => {
            throw error;
        })
}

function* getProvidersFlow(action) {
    try {
        const {token} = action;
        const providers = yield call(getProvidersAPI, token);
        yield put(getProvidersSuccess(providers));
    } catch (error) {
        yield put(getProvidersFailure(error));
    }
}

const createProviderAPI = (token, values) => {
    return fetch(`${URL}providers/provider`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(values)
    })
        .then(response => response.json())
        .then(json => {
            if (json?.code && json.code === 200){
                return json.provider;
            }else {
                throw json.errors
            }
        }).catch((error) => {
            throw error;
        })
}

function* createProviderFlow(action) {
    try {
        const {token, values} = action;
        const provider = yield call(createProviderAPI, token, values);
        yield put(createProviderSuccess(provider));
    } catch (error) {
        yield put(createProviderFailure(error));
    }
}

const updateProviderAPI = (token, values) => {
    return fetch(`${URL}providers/provider/${values.id}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(values),
    })
        .then(response => response.json())
        .then(json => {
            if (json?.code && json.code === 200){
                return json.provider;
            }else if (json?.code && json.code === 400){
                throw json;
            }else {
                throw json.errors
            }
        }).catch((error) => {
            throw error;
        })
}

function* updateProviderFlow(action) {
    try {
        const {token, values} = action;
        const provider = yield call(updateProviderAPI, token, values);
        yield put(updateProviderSuccess(provider));
    } catch (error) {
        yield put(updateProviderFailure(error));
    }
}


function* providerWatcher() {
    yield all([
        takeEvery(GET_PROVIDERS_ATTEMPT, getProvidersFlow),
        takeEvery(CREATE_PROVIDER_ATTEMPT, createProviderFlow),
        takeEvery(UPDATE_PROVIDER_ATTEMPT, updateProviderFlow),
    ])
}

export default providerWatcher;
