export const GET_PACKING_ATTEMPT = 'GET_PACKING_ATTEMPT';
export const GET_PACKING_SUCCESS = 'GET_PACKING_SUCCESS';
export const GET_PACKING_FAILURE = 'GET_PACKING_FAILURE';

export const CREATE_PACKING_ATTEMPT = 'CREATE_PACKING_ATTEMPT';
export const CREATE_PACKING_SUCCESS = 'CREATE_PACKING_SUCCESS';
export const CREATE_PACKING_FAILURE = 'CREATE_PACKING_FAILURE';

export const UPDATE_PACKING_ATTEMPT = 'UPDATE_PACKING_ATTEMPT';
export const UPDATE_PACKING_SUCCESS = 'UPDATE_PACKING_SUCCESS';
export const UPDATE_PACKING_FAILURE = 'UPDATE_PACKING_FAILURE';

export const ADD_STOCK_PACKING_ATTEMPT = 'ADD_STOCK_PACKING_ATTEMPT';
export const ADD_STOCK_PACKING_SUCCESS = 'ADD_STOCK_PACKING_SUCCESS';
export const ADD_STOCK_PACKING_FAILURE = 'ADD_STOCK_PACKING_FAILURE';

export const UPDATE_IMAGE_PACKING_ATTEMPT = 'UPDATE_IMAGE_PACKING_ATTEMPT';
export const UPDATE_IMAGE_PACKING_SUCCESS = 'UPDATE_IMAGE_PACKING_SUCCESS';
export const UPDATE_IMAGE_PACKING_FAILURE = 'UPDATE_IMAGE_PACKING_FAILURE';

export const ALL_GET_PACKING_ATTEMPT = 'ALL_GET_PACKING_ATTEMPT';
export const ALL_GET_PACKING_SUCCESS = 'ALL_GET_PACKING_SUCCESS';
export const ALL_GET_PACKING_FAILURE = 'ALL_GET_PACKING_FAILURE';

export const GET_PACK = 'GET_PACK';

export const CLEAN_PACKING = 'CLEAN_PACKING';
