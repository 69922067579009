import React, {useState} from 'react';
import {Formik} from "formik";
import Images from "../../assets/images/forgot-password.svg";
import {Link} from "react-router-dom";

export const ResetPassword = ({
                                           handle,
                                           error,
                                           requesting,
                                           message,
                                       }) => {

    const [seePassword, setSeePassword] = useState(false);

    return (
        <div>
            <h4 className="text-center mb-4 text-login"></h4>
            <Formik
                initialValues={{ password: ''}}
                onSubmit={(values ) => {
                    handle(values)
                }}
            >
                {({
                      handleChange,
                      handleSubmit,

                  }) => (
                    <form className="row g-1 p-3 p-md-4" onSubmit={handleSubmit}>
                        <div className="col-12 text-center mb-1">
                            <img src={Images} className="w240 mb-4" alt="" />
                        </div>
                        <div className="text-center mb-4">
                            <span>Escribe la nueva contraseña</span>
                        </div>
                        {error?.message && (
                            <div className='text-danger fs-12'>
                                {error.message}
                            </div>
                        )}
                        {message !== '' && (
                            <div className='text-success fs-12 mb-3'>
                                {message}
                            </div>
                        )}
                        <div className="col-12">
                            <div className="mb-3">
                                <div className="form-label">
                                <span className="d-flex justify-content-between align-items-center">
                                    Contraseña
                                </span>
                                </div>
                                <input type={seePassword ? 'text' : 'password' }
                                       className="form-control form-control-lg lift"
                                       placeholder="***************"
                                       name={'password'}
                                       autoComplete={"off"}
                                       onChange={handleChange}
                                />
                                <i onClick={() => setSeePassword(!seePassword)}
                                   className={`fa ${seePassword ? 'fa-regular fa-eye' : 'fa-regular fa-eye-slash'} icon-password`}></i>
                            </div>
                            {error.password && <div className="text-danger fs-12">{error.password}</div>}
                        </div>
                        <div className="col-12 text-center mt-4">
                            {requesting && (
                                <button className="btn btn-primary lift btn-spinner">
                                    <i className="fa fa-spinner fa-spin"></i>
                                </button>
                            )}
                            {!requesting && (
                                <button type={"submit"} className="btn btn-primary btn-lg lift">
                                    Cambiar contraseña
                                </button>
                            )}
                        </div>
                        <div className="col-12 text-center mt-4">
                            <Link to={'/'} className="text-secondary">Volver</Link>
                        </div>
                    </form>
                )}
            </Formik>
        </div>
    )
};
