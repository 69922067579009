import {
    CLEAN_DAMAGED_PACKING,
    CREATE_DAMAGED_PACKING_ATTEMPT,
    CREATE_DAMAGED_PACKING_FAILURE,
    CREATE_DAMAGED_PACKING_SUCCESS,
    GET_DAMAGED_PACKING_ATTEMPT,
    GET_DAMAGED_PACKING_FAILURE,
    GET_DAMAGED_PACKING_SUCCESS,
    UPDATE_DAMAGED_PACKING_ATTEMPT,
    UPDATE_DAMAGED_PACKING_FAILURE,
    UPDATE_DAMAGED_PACKING_SUCCESS
} from "./constants";

export const createDamagedPacking = (token, values) => ({
    type: CREATE_DAMAGED_PACKING_ATTEMPT,
    token,
    values,
});

export const createDamagedPackingSuccess = damagedPacking => ({
    type: CREATE_DAMAGED_PACKING_SUCCESS,
    damagedPacking,
});

export const createDamagedPackingFailure = error => ({
    type: CREATE_DAMAGED_PACKING_FAILURE,
    error,
});

export const getDamagedPacking = (token, page) => ({
    type: GET_DAMAGED_PACKING_ATTEMPT,
    token,
    page,
});

export const getDamagedPackingSuccess = damagedPacking => ({
    type :GET_DAMAGED_PACKING_SUCCESS,
    damagedPacking,
});

export const getDamagedPackingFailure = error => ({
    type: GET_DAMAGED_PACKING_FAILURE,
    error
});

export const updateDamagedPacking = (token, values) => ({
    type: UPDATE_DAMAGED_PACKING_ATTEMPT,
    token, values,
});

export const updateDamagedPackingSuccess = damagedPacking => ({
    type: UPDATE_DAMAGED_PACKING_SUCCESS,
    damagedPacking,
});

export const updateDamagedPackingFailure = error => ({
    type: UPDATE_DAMAGED_PACKING_FAILURE,
    error,
});


export const cleanDamagedPacking = () => ({
    type: CLEAN_DAMAGED_PACKING,
});
