import {
    CLEAN_LOGIN,
    GET_ME_ATTEMPT,
    GET_ME_FAILURE,
    GET_ME_SUCCESS,
    LOGIN_ATTEMPT,
    LOGIN_FAILURE,
    LOGIN_SUCCESS, LOGOUT_ATTEMPT, LOGOUT_FAILURE, LOGOUT_SUCCESS, OPEN_MODAL, SIDEBAR_MINI
} from "./constants";

const initialState = {
    requesting: false,
    success: false,
    error: '',
    requestingMe: false,
    successMe: false,
    errorMe: '',
    user: {},
    token: '',
    logoutRequesting: false,
    logoutSuccess: false,
    logoutError: '',
    sidebarMini: false,
    openModal: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type){
        case LOGIN_ATTEMPT:
            return {
                ...state,
                requesting: true,
                error: '',
                logoutSuccess: false,
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
            };
        case LOGIN_FAILURE:
            return {
                ...state,
                requesting: false,
                error: action.error,
            };
        case CLEAN_LOGIN:
            return {
                ...state,
                requesting: false,
                success: false,
                error: '',
            };
        case GET_ME_ATTEMPT:
            return {
                ...state,
                requestingMe: true,
                token: action.token,
            };
        case GET_ME_SUCCESS:
            return {
                ...state,
                requestingMe: false,
                successMe: true,
                user: action.user,
            };
        case GET_ME_FAILURE:
            return {
                ...state,
                requestingMe: false,
                errorMe: action.error,
            };
        case LOGOUT_ATTEMPT:
            return {
                ...state,
                logoutRequesting: true,
                logoutSuccess: false,
            };
        case LOGOUT_SUCCESS:
            return {
                ...state,
                logoutRequesting: false,
                logoutSuccess: true,
                successMe: false,
                user: {},
                token: '',
                openModal: false,
            };
        case LOGOUT_FAILURE:
            return {
                ...state,
                logoutRequesting: false,
                logoutError: action.error,
            };
        case SIDEBAR_MINI:
            return {
                ...state,
                sidebarMini: action.change,
            };
        case OPEN_MODAL:
            return {
                ...state,
                openModal: action.change
            };
        default:
            return state;
    }
}

export default reducer;
