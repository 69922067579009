import React, {useState} from 'react';
import Modal from "react-bootstrap/Modal";
import {useDispatch, useSelector} from "react-redux";
import {updateImagePacking} from "../../redux/packing/actions";

export const EditImagePacking = ({
                              visible,
                              close,
                              focus,
                              photos,
                              setPhotos,
}) => {

    const dispatch = useDispatch();

    const {token} = useSelector(state => state.auth);
    const {updateImagePackingRequesting} = useSelector(state => state.packing);

    const [productImages, setProductImages] = useState(focus.images);
    const [imagePrincipal, setImagePrincipal] = useState(focus.images[0]);
    const [imagesDelete, setImagesDelete] = useState([]);
    const [countImages, setCountImages] = useState(focus.images.length);
    const [newPhotos, setNewPhotos] = useState([]);

    let notImagePrincipal = imagesDelete.find(image => image.image_id ===  imagePrincipal.id);

    const handleUpdateImage = () => {

        let changeImages = imagePrincipal.id !== focus.images[0].id ? {
            change_images: [
                {image_id: imagePrincipal.id, image_position: focus.images[0].position},
                {image_id: focus.images[0].id, image_position: imagePrincipal.position}
            ]
        } : '';

        let deleteImages = imagesDelete.length > 0 ? {
            delete_images: imagesDelete,
        } : '';

        let editImages = photos.length > 0 ? {
            edit_images: photos
        } : '';

        let newImages = newPhotos.length > 0 ? {
            new_images: newPhotos
        } : '';

        let values = {
            '_method': 'PUT',
            packing_id: focus.id,
            change_position: imagePrincipal.id !== focus.images[0].id,
            ...changeImages,
            delete_image: imagesDelete.length > 0,
            ...deleteImages,
            edit_image: photos.length > 0,
            ...editImages,
            new_image: newPhotos.length > 0,
            ...newImages,
        }
        dispatch(updateImagePacking(token, values));
    };

    const editImage = (photo, productInfo) => {
        let reader = new FileReader();
        reader.readAsDataURL(photo.target.files[0]);
        reader.onload = () => {
            let values =  {
                image_id: productInfo.id,
                path: reader.result,
                url: URL.createObjectURL(photo.target.files[0]),
                name: photo.target.files[0].name,
            }
            setPhotos([...photos, values])
        };
        let updateImage = productImages.map((product) => (
            product.id === productInfo.id ? {
                id: productInfo.id,
                url:URL.createObjectURL(photo.target.files[0]),
                position: productImages.position,
            } : product
        ))
        setProductImages(updateImage);
        if(productInfo.position === 1){
            setImagePrincipal({
                id: productInfo.id,
                url:URL.createObjectURL(photo.target.files[0]),
                position: productImages.position,
            });
        }
    };

    const addImage = (photo) => {
        setCountImages(countImages + 1);
        let reader = new FileReader();
        reader.readAsDataURL(photo.target.files[0]);
        reader.onload = () => {
            let values =  {
                path: reader.result,
                url: URL.createObjectURL(photo.target.files[0]),
                name: photo.target.files[0].name,
                position: countImages + 1
            }
            setNewPhotos([...newPhotos, values])
        };
        let image = {
            id: photo.target.files[0].name,
            url:URL.createObjectURL(photo.target.files[0]),
        }
        setProductImages([...productImages, image]);
    }

    const addMoreImageForDelete = (image) => {
        let values = {
            'image_id' : image
        }
        setImagesDelete([...imagesDelete, values]);
    };

    return (
        <Modal
            show={visible} onHide={close} style={{ display: 'block' }}>
            <Modal.Header className="modal-header" closeButton>
                <h5 className="modal-title  fw-bold" id="addProduct">Imagenes</h5>
            </Modal.Header>
            <Modal.Body className="modal-body">
                <div className="deadline-form">
                    <div className='text-center'>
                        <h4>Imagen Principal</h4>
                        {notImagePrincipal !== undefined ?
                            <p>Seleccionaremos una por defecto una vez guardes cambios</p>
                            : <img className="avatar rounded image-principal border"
                                   src={imagePrincipal.url}
                                   alt={`${imagePrincipal.name}`} />
                        }
                    </div>
                    <div className='text-center mt-3 mb-2 container-input-add-file-icon'>
                        <span className='text-add-image'>Agregar nueva imagen</span>
                        <input
                            type="file"
                            className='button-add-file-icon'
                            onChange={addImage}
                        />
                    </div>
                    <div className='column-2'>
                        {productImages.map((image, index) => {
                            let hideImages = imagesDelete.find(imageDelete => imageDelete.image_id === image.id)
                            if(hideImages === undefined)
                                return <div key={index} className='d-flex flex-row align-items-center' >
                                    <div>
                                        <img className="avatar rounded xxl border m-2"
                                             src={image.url}
                                             alt={`${image.name}`} />
                                    </div>
                                    <div className='d-flex flex-column '>
                                        {image?.position && <div className='container-input-file-icon'>
                                            <i className="fa-solid fa-pen-to-square fa-lg icon-table" />
                                            <input
                                                type="file"
                                                className='button-file-icon'
                                                onChange={(value) => {
                                                    editImage(value, image);
                                                }}

                                            />
                                        </div>}
                                        {countImages > 1 && (
                                            <button type="button"
                                                    onClick={() => {
                                                        if(image?.position){
                                                            addMoreImageForDelete(image.id);
                                                            setCountImages(countImages - 1);
                                                        }else{
                                                            setProductImages(productImages.filter(allImages => allImages.id !== image.id));
                                                        }
                                                    }}
                                                    className="btn button-table-reverse margin-buttons mt-2"
                                            >
                                                <i className="fa-solid fa-trash fa-lg icon-table" />
                                            </button>
                                        )}
                                        {image?.position && countImages > 1 && (
                                            <button type="button"
                                                    onClick={() => {
                                                        setImagePrincipal(image)
                                                    }}
                                                    className="btn button-table-account margin-buttons mt-2"
                                            >
                                                <i className="fa-solid fa-arrow-up-1-9 fa-lg icon-table"></i>
                                            </button>
                                        )}
                                    </div>
                                </div>
                        })}
                    </div>
                </div>
            </Modal.Body>
            <div className="modal-footer">
                {!updateImagePackingRequesting && (
                    <button className="btn btn-secondary col-sm-4"
                            onClick={close}
                            data-bs-dismiss="modal">Cancelar</button>
                )}
                {updateImagePackingRequesting && (
                    <button className="btn btn-primary lift btn-spinner container-spinner col-sm-4">
                        <i className="fa fa-spinner fa-spin"></i>
                    </button>
                )}
                {!updateImagePackingRequesting && (
                    <button
                            onClick={handleUpdateImage}
                            className="btn btn-primary col-sm-4">
                        Guardar cambios
                    </button>
                )}
            </div>
        </Modal>
    )
};
