import {all, call, put, takeEvery} from 'redux-saga/effects'
import {
    changeStateOrderFailure,
    changeStateOrderSuccess,
    getCartProductsFailure,
    getCartProductsSuccess,
    getOrdersFailure,
    getOrdersSuccess,
    reverseOrderFailure,
    reverseOrderSuccess
} from "./actions";
import {
    CHANGE_STATE_ORDER_ATTEMPT,
    GET_CART_PRODUCTS_ATTEMPT,
    GET_ORDERS_ATTEMPT,
    REVERSE_ORDER_ATTEMPT
} from "./constants";

const URL = `${process.env.REACT_APP_API_KEY}`;

const getOrdersAPI = (token, page) => {
    return fetch(`${URL}orders/orders?page=${page}&top=10`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    })
        .then(response => response.json())
        .then(json => {
            if (json.data.length === 0){
                throw 'No hay ordenes';
            }
            if (json.data.length > 0){
                return json;
            }
        }).catch((error) => {
            throw error;
        })
}

function* getOrdersFlow(action) {
    try {
        const {token, page} = action;
        const orders = yield call(getOrdersAPI, token, page);
        yield put (getOrdersSuccess(orders));
    }catch (error){
        yield put(getOrdersFailure(error));
    }
}

const getReverseOrderAPI = (orderId, buyerId, values, token) => {

    return fetch(`${URL}orders/order/${orderId}/${buyerId}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(values),
    })
        .then(response => response.json())
        .then(json => {
            if (json.code === 200){
                return json.order;
            }
        }).catch((error) => {
            throw error;
        })
}

function* getReverseOrderFlow(action) {
    try {
        const {orderId, buyerId, values, token} = action;
        const order = yield call(getReverseOrderAPI, orderId, buyerId, values, token);
        yield put (reverseOrderSuccess(order));
    }catch (error){
        yield put(reverseOrderFailure(error));
    }
}

const getCartProductsAPI = (token, id) => {

    return fetch(`${URL}products/cart_products/${id}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    })
        .then(response => response.json())
        .then(json => {
            if (json.code === 200){
                return json.cart;
            }
        }).catch((error) => {
            throw error;
        })
}

function* getCartProductsFlow(action) {
    try {
        const {token, id} = action;
        const cart = yield call(getCartProductsAPI, token, id);
        yield put (getCartProductsSuccess(cart));
    }catch (error){
        yield put(getCartProductsFailure(error));
    }
}

const changeStateOrderAPI = (token, orderId) => {
    return fetch(`${URL}states/order_states/${orderId}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    })
        .then(response => response.json())
        .then(json => {
            if (json.code === 200){
                return json.order;
            }
        }).catch((error) => {
            throw error;
        })
};

function* changeStateOrderFlow(action) {
    try {
        const {token, orderId} = action;
        const order = yield call(changeStateOrderAPI, token, orderId);
        yield put(changeStateOrderSuccess(order));
    } catch (error) {
        yield put(changeStateOrderFailure(error));
    }
}

function* orderWatcher() {
    yield all([
        takeEvery(GET_ORDERS_ATTEMPT, getOrdersFlow),
        takeEvery(REVERSE_ORDER_ATTEMPT, getReverseOrderFlow),
        takeEvery(GET_CART_PRODUCTS_ATTEMPT, getCartProductsFlow),
        takeEvery(CHANGE_STATE_ORDER_ATTEMPT, changeStateOrderFlow)
    ])
}

export default orderWatcher;
