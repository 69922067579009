import {
    GET_STATES_DAMAGED_PRODUCT_ATTEMPT,
    GET_STATES_DAMAGED_PRODUCT_FAILURE,
    GET_STATES_DAMAGED_PRODUCT_SUCCESS
} from "./constants";

const initialState = {
    getStateDamagedProductRequesting: false,
    getStateDamagedProductSuccess: false,
    getStateDamagedProductError: '',
    stateDamagedProduct: [],
}

const reducer = (state = initialState, action) => {
    switch (action.type){
        case GET_STATES_DAMAGED_PRODUCT_ATTEMPT:
            return {
                ...state,
                getStateDamagedProductRequesting: true,
            };
        case GET_STATES_DAMAGED_PRODUCT_SUCCESS:
            return {
                ...state,
                getStateDamagedProductRequesting: false,
                getStateDamagedProductSuccess: true,
                stateDamagedProduct: action.statesDamagedProduct
            };
        case GET_STATES_DAMAGED_PRODUCT_FAILURE:
            return {
                ...state,
                getStateDamagedProductRequesting: false,
                getStateDamagedProductError: action.error,
            };
        default:
            return state;
    }
}

export default reducer;

