import React from "react";
import Modal from "react-bootstrap/Modal";
import {Formik} from "formik";
import {useDispatch, useSelector} from "react-redux";
import {updateDamagedPacking} from "../../redux/damagedPacking/actions";


export const AddMoreDamagedPacking = ({
                             visible,
                             close,
                             focus,
}) => {

    const dispatch = useDispatch();

    const {token} = useSelector(state => state.auth);
    const {updateDamagedPackingRequesting,
        updateDamagedPackingError} = useSelector(state => state.damagedPacking);
    const {stateDamagedProduct} = useSelector(state => state.state);

    const handleMoreDamagedPacking = (values) => {
        let body = {
            '_method': 'PUT',
            id: focus.id,
            quantity: parseInt(values.quantity),
            reason: values.reason,
            state_id: parseInt(values.state_id),
        }
        dispatch(updateDamagedPacking(token, body))
    };

    return (
        <Modal show={visible} onHide={close} style={{ display: 'block' }}>
            <div className="modal-content">
                <Modal.Header className="modal-header" closeButton>
                    <h5 className="modal-title  fw-bold" id="addProduct">{`Agregar o devolver otra cantidad del empaque
                     averiado`}</h5>
                </Modal.Header>
                <Formik
                    initialValues={{ quantity: '', reason: '', state_id: '' }}
                    onSubmit={(values ) => {
                        handleMoreDamagedPacking(values)
                    }}
                >
                    {({
                          handleChange,
                          handleSubmit,
                      }) => (
                        <form onSubmit={handleSubmit}>
                            <Modal.Body className="modal-body">
                                <div className="mb-3 mt-1">
                                    <label className="form-label">Estado</label>
                                    <select name={'state_id'} className="form-control" onChange={handleChange}>
                                        <option
                                            value={0}>
                                            Elegir Estado
                                        </option>
                                        {stateDamagedProduct.map((states, index) => (
                                            <option
                                                key={index}
                                                value={states.id}>
                                                {states.name}
                                            </option>
                                        ))}
                                    </select>
                                    {updateDamagedPackingError.state_id && <div className="text-danger fs-12">
                                        {updateDamagedPackingError.state_id}
                                    </div>}
                                    <label className="mb-1 mt-2 form-label">{`Cantidad a agregar o a devolver`}</label>
                                    <input type="text" className="form-control"
                                           placeholder={focus.quantity}
                                           name={'quantity'}
                                           onChange={handleChange}
                                    />
                                    {updateDamagedPackingError.quantity && <div className="text-danger fs-12">
                                        {updateDamagedPackingError.quantity}
                                    </div>}
                                    <textarea name="reason"
                                              rows="4"
                                              placeholder={'Escribe la razón'}
                                              className="form-control mt-2"
                                              onChange={handleChange}
                                    />
                                    {updateDamagedPackingError.reason && <div className="text-danger fs-12">
                                        {updateDamagedPackingError.reason}
                                    </div>}
                                </div>
                            </Modal.Body>
                            <div className="modal-footer">
                                {!updateDamagedPackingRequesting && (
                                    <button className="btn btn-secondary col-sm-4"
                                            onClick={close}
                                            data-bs-dismiss="modal">Cancelar</button>
                                )}
                                {updateDamagedPackingRequesting && (
                                    <button className="btn btn-primary lift btn-spinner container-spinner col-sm-4">
                                        <i className="fa fa-spinner fa-spin"></i>
                                    </button>
                                )}
                                {!updateDamagedPackingRequesting && (
                                    <button type={"submit"}
                                            className="btn btn-primary col-sm-4">
                                        Agregar avería
                                    </button>
                                )}
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </Modal>
    )
};
