import React from 'react'
import {Modal} from "react-bootstrap";
import {Formik} from "formik";
import {FormatNumber} from "../../constants/FormatNumber";

export const ModalPayment = ({
                                 visible,
                                 close,
                                 payments,
                                 handleShould,
                                 requesting,
                                 error
}) => {
    return (
        <Modal show={visible} onHide={close} style={{ display: 'block'}}>
            <div className="modal-content">
                <Modal.Header className="modal-header" closeButton>
                    <h5 className="modal-title  fw-bold" id="expeditLabel1111">Agregar abono</h5>
                </Modal.Header>
                <Modal.Body className="modal-body">
                    <div className="deadline-form">
                        <Formik
                                initialValues={{ should: '0', paymentId: '0'}}
                            onSubmit={(values ) => {

                                handleShould(values)
                            }}
                        >
                            {({
                                  handleChange,
                                  handleSubmit,
                                  setFieldValue
                              }) => (
                                <form onSubmit={handleSubmit}>
                                    {error && (
                                        <div className='text-danger fs-12 text-message-modal'>
                                            {error}
                                        </div>
                                    )}
                                    <div className="col-sm-12">
                                        <label htmlFor="item100" className="form-label">Abono</label>
                                        <FormatNumber  className="form-control"
                                                       onValueChange={(value) => setFieldValue('should', value.floatValue)} />
                                    </div>
                                    <div className="col-sm-12 mt-3">
                                        <label className="form-label">Método de pago</label>
                                        <select className="form-select" name="paymentId" onChange={handleChange}>
                                            <option value={0} >Elegir método</option>
                                            {payments.map((payment, index) => (
                                                <option value={payment.id} key={index} >{payment.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="text-center mt-4">
                                        {requesting && (
                                            <button className="btn btn-primary col-sm-6">
                                                <i className="fa fa-spinner fa-spin"></i>
                                            </button>
                                        )}
                                        {!requesting && (
                                            <button type='submit'
                                                    disabled={requesting}
                                                    className="btn btn-primary text-uppercas col-sm-6">
                                                Crear abono de cuenta
                                            </button>
                                        )}
                                    </div>

                                </form>
                            )}
                        </Formik>
                    </div>
                </Modal.Body>
            </div>
        </Modal>
    )
}
