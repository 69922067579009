import {
    ADD_PACKING_PRODUCTS_ATTEMPT, ADD_PACKING_PRODUCTS_FAILURE, ADD_PACKING_PRODUCTS_SUCCESS,
    ADD_STOCK_PRODUCT_ATTEMPT,
    ADD_STOCK_PRODUCT_FAILURE,
    ADD_STOCK_PRODUCT_SUCCESS,
    CHANGE_STATE_PRODUCT_ATTEMPT,
    CHANGE_STATE_PRODUCT_FAILURE,
    CHANGE_STATE_PRODUCT_SUCCESS,
    CLEAN_ERROR_PRODUCT_REFERENCE,
    CLEAN_PRODUCT, CLEAN_SEARCH,
    CREATE_PRODUCT_ATTEMPT,
    CREATE_PRODUCT_FAILURE,
    CREATE_PRODUCT_REFERENCES_ATTEMPT,
    CREATE_PRODUCT_REFERENCES_FAILURE,
    CREATE_PRODUCT_REFERENCES_SUCCESS,
    CREATE_PRODUCT_SUCCESS, GET_PRODUCT,
    GET_PRODUCTS_ATTEMPT,
    GET_PRODUCTS_FAILURE,
    GET_PRODUCTS_SUCCESS, SEARCH_PRODUCTS_ATTEMPT, SEARCH_PRODUCTS_FAILURE, SEARCH_PRODUCTS_SUCCESS,
    UPDATE_IMAGE_ATTEMPT,
    UPDATE_IMAGE_FAILURE,
    UPDATE_IMAGE_SUCCESS,
    UPDATE_PRODUCT_ATTEMPT,
    UPDATE_PRODUCT_FAILURE,
    UPDATE_PRODUCT_REFERENCES_ATTEMPT,
    UPDATE_PRODUCT_REFERENCES_FAILURE,
    UPDATE_PRODUCT_REFERENCES_SUCCESS,
    UPDATE_PRODUCT_SUCCESS
} from "./constants";

export const createProduct = (token, values, photo, references) => ({
    type: CREATE_PRODUCT_ATTEMPT,
    token,
    values,
    photo,
    references,
});

export const createProductSuccess = product => ({
    type: CREATE_PRODUCT_SUCCESS,
    product,
});

export const createProductFailure = error => ({
    type: CREATE_PRODUCT_FAILURE,
    error,
});

export const getProducts = (token, page) => ({
    type: GET_PRODUCTS_ATTEMPT,
    token,
    page,
});

export const getProductsSuccess = products => ({
    type: GET_PRODUCTS_SUCCESS,
    products,
});

export const getProductsFailure = error => ({
    type: GET_PRODUCTS_FAILURE,
    error,
});

export const changeStateProduct = (token, productId) => ({
    type: CHANGE_STATE_PRODUCT_ATTEMPT,
    token,
    productId,
});

export const changeStateProductSuccess = product => ({
    type: CHANGE_STATE_PRODUCT_SUCCESS,
    product,
});

export const changeStateProductFailure = error => ({
    type: CHANGE_STATE_PRODUCT_FAILURE,
    error,
});

export const createProductReferences = (token, values, productId) => ({
    type: CREATE_PRODUCT_REFERENCES_ATTEMPT,
    token,
    values,
    productId,
});

export const createProductReferencesSuccess = product => ({
    type: CREATE_PRODUCT_REFERENCES_SUCCESS,
    product,
});

export const createProductReferencesFailure = error => ({
    type: CREATE_PRODUCT_REFERENCES_FAILURE,
    error,
});

export const updateProductReferences = (token, values) => ({
    type: UPDATE_PRODUCT_REFERENCES_ATTEMPT,
    token,
    values,
});

export const updateProductReferencesSuccess = product => ({
    type: UPDATE_PRODUCT_REFERENCES_SUCCESS,
    product,
});

export const updateProductReferencesFailure = error => ({
    type: UPDATE_PRODUCT_REFERENCES_FAILURE,
    error,
});

export const addStockProduct = (token, values) => ({
    type: ADD_STOCK_PRODUCT_ATTEMPT,
    token,
    values,
});

export const addStockProductSuccess = product => ({
    type: ADD_STOCK_PRODUCT_SUCCESS,
    product,
});

export const addStockProductFailure = error => ({
    type: ADD_STOCK_PRODUCT_FAILURE,
    error,
});

export const updateProduct = (token, values, focusProduct) => ({
    type: UPDATE_PRODUCT_ATTEMPT,
    token,
    values,
    focusProduct,
});

export const updateProductSuccess = product => ({
    type: UPDATE_PRODUCT_SUCCESS,
    product,
});

export const updateProductFailure = error => ({
    type: UPDATE_PRODUCT_FAILURE,
    error,
});

export const updateImage = (token, values) => ({
    type: UPDATE_IMAGE_ATTEMPT,
    token,
    values,
});

export const updateImageSuccess = product => ({
    type: UPDATE_IMAGE_SUCCESS,
    product,
});

export const updateImageFailure = error => ({
    type: UPDATE_IMAGE_FAILURE,
    error,
});

export const cleanErrorProductReference = () => ({
    type: CLEAN_ERROR_PRODUCT_REFERENCE,
});

export const addPackingProducts = (token, values, productId) => ({
    type: ADD_PACKING_PRODUCTS_ATTEMPT,
    token,
    values,
    productId,
});

export const addPackingProductsSuccess = product => ({
    type: ADD_PACKING_PRODUCTS_SUCCESS,
    product
});

export const addPackingProductsFailure = error => ({
    type: ADD_PACKING_PRODUCTS_FAILURE,
    error,
});

export const searchProducts = (token, search, page) => ({
    type: SEARCH_PRODUCTS_ATTEMPT,
    token,
    search,
    page,
});

export const searchProductsSuccess = products => ({
    type: SEARCH_PRODUCTS_SUCCESS,
    products,
});

export const searchProductsFailure = error => ({
    type: SEARCH_PRODUCTS_FAILURE,
    error,
});

export const cleanSearch = () => ({
    type: CLEAN_SEARCH
});

export const getProduct = (product) => ({
    type: GET_PRODUCT,
    product,
});

export const cleanProduct = () => ({
    type: CLEAN_PRODUCT,
});
