import {all, call, put, takeEvery} from 'redux-saga/effects'
import {
    createDamagedProductFailure,
    createDamagedProductSuccess,
    getDamagedProductFailure,
    getDamagedProductSuccess, updateDamagedProductFailure, updateDamagedProductSuccess
} from "./actions";
import {CREATE_DAMAGED_PRODUCT_ATTEMPT, GET_DAMAGED_PRODUCT_ATTEMPT, UPDATE_DAMAGED_PRODUCT_ATTEMPT} from "./constants";
import {getProduct} from "../product/actions";

const URL = `${process.env.REACT_APP_API_KEY}`;

const createDamagedProductAPI = (token, values) => {
    return fetch(`${URL}damaged_product/damaged_product`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(values),
    })
        .then(response => response.json())
        .then(json => {
            if (json.code === 200){
                return json.data;
            }
        }).catch((error) => {
            throw error;
        })
}

 function* createDamagedProductFlow(action) {
    try {
        const {token, values} = action;
        const damagedProduct = yield call(createDamagedProductAPI, token, values);
        yield put(createDamagedProductSuccess(damagedProduct));
        yield put(getProduct(damagedProduct.product));
    } catch (error) {
        yield put(createDamagedProductFailure(error));
    }
}

const getDamagedProductsAPI = (token, page) => {
    return fetch(`${URL}damaged_product/damaged_products?page=${page}&top=10`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    })
        .then(response => response.json())
        .then(json => {
            if (json.data.length === 0){
                throw 'No hay productos averiados';
            }
            if (json.data.length > 0){
                return json;
            }
        }).catch((error) => {
            throw error;
        })
}

function* getDamagedProductsFlow(action) {
    try {
        const {token, page} = action;
        const damagedProducts = yield call(getDamagedProductsAPI, token, page);
        yield put(getDamagedProductSuccess(damagedProducts));
    } catch (error) {
        yield put(getDamagedProductFailure(error));
    }
}

const updateDamagedProductAPI = (token, values) => {
    return fetch(`${URL}damaged_product/damaged_product/${values.id}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(values),
    })
        .then(response => response.json())
        .then(json => {
            if (json.code === 200){
                return json.damagedProduct;
            }
        }).catch((error) => {
            throw error;
        })
}

function* updateDamagedProductFlow(action) {
    try {
        const {token, values} = action;
        const damagedProduct = yield call(updateDamagedProductAPI, token, values);
        yield put(updateDamagedProductSuccess(damagedProduct));
    } catch (error) {
        yield put(updateDamagedProductFailure(error));
    }
}

function* damagedProductWatcher(){
    yield all([
        takeEvery(CREATE_DAMAGED_PRODUCT_ATTEMPT, createDamagedProductFlow),
        takeEvery(GET_DAMAGED_PRODUCT_ATTEMPT, getDamagedProductsFlow),
        takeEvery(UPDATE_DAMAGED_PRODUCT_ATTEMPT, updateDamagedProductFlow)
    ])
}

export default damagedProductWatcher;
